// 组件类型
export const ComponentType = {
    LAMP: 'street-lamp',                            // 路灯
    FENCEUPRIGHT: 'fence-upright',                  // 围墙立柱
    FENCESOLIDWALL: 'fence-solid-wall',             // 围墙实墙
    FENCEBANISTER: 'fence-banister',                // 围墙栅栏
    PARKINGSPACE: "parking-space",                  // 机动车位
    NOMOTORSPARKINGSPACE: "no-motor-parking-space", // 非机动车位
    SMOKE_DETECTOR: 'smoke-detector',// 烟感器
    FIRE_HYDRANT: 'fire-hydrant',// 消火栓
    SPRAY_HEAD: 'spray-head',// 喷淋头
    FIRE_EXTINGUISHER: 'fire-extinguisher',// 灭火器
    /**
     * 巡更点
     */
    PATROLPOINT: 'patrol-point',


    TREE: 'tree-shrubs',// 乔灌木
}