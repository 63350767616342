import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function createComponent(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/components`, data, {
        params: {
            ...params,
        }
    })
}

export function getComponents(params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/components`, {
        params: {
            ...params,
        }
    })
}

export function deleteComponent(componentUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/components/${componentUuid}`, {
        params: {
            ...params,
        }
    })
}
export function updateComponent(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/components`, data, {
        params: {
            ...params,
        }
    })
}