import { TnEngineExtraContext } from "pythagoras";
import {
    createPrimitive,
    createPrimitiveMulti,
    deletePrimitivesInGraphic,
    // findAxisNet,
    findPrimitivesInGraphic,
} from "@/api/geometry/primitive";
import { findFontGlyphs, findFontTtfs, findFontsInGraphic } from "@/api/geometry/font";
import { deleteUcs, saveUcs } from "@/api/geometry/ucs";
// import { deleteWorkPlane, saveWorkPlane } from "@/api/spec-common/work-surface";
import { deleteLayer, saveLayer, updateLayer } from "@/api/geometry/layer";
import { addRoadNetworkToUndo, deleteRoadNetworkByUuids, getRoadNetworkByUuids, updateRoadNetwork } from "@/api/building/road-network";

export function cmdRegister() {
    let extraContext = TnEngineExtraContext.getInstance();

    extraContext.apiContext.createPrimitive = createPrimitive;
    extraContext.apiContext.createPrimitiveMulti = createPrimitiveMulti;
    extraContext.apiContext.deletePrimitivesInGraphic = deletePrimitivesInGraphic;
    extraContext.apiContext.findPrimitivesInGraphic = findPrimitivesInGraphic;
    extraContext.apiContext.findOneTypePrimitivesInGraphic = findPrimitivesInGraphic;

    extraContext.apiContext.saveUcs = saveUcs;
    extraContext.apiContext.deleteUcs = deleteUcs;

    extraContext.apiContext.updateLayer = updateLayer;
    extraContext.apiContext.createLayer = saveLayer;
    extraContext.apiContext.deleteLayer = deleteLayer;

    extraContext.apiContext.findFontGlyphs = findFontGlyphs;
    extraContext.apiContext.findFontsInGraphic = findFontsInGraphic;
    extraContext.apiContext.findFontTtfs = findFontTtfs;

    extraContext.apiContext.registerApi("updateRoadNetwork", updateRoadNetwork)
    extraContext.apiContext.registerApi("getRoadNetworkByUuids", getRoadNetworkByUuids)
    extraContext.apiContext.registerApi("deleteRoadNetworkByUuids", deleteRoadNetworkByUuids)
    extraContext.apiContext.registerApi("addRoadNetworkToUndo", addRoadNetworkToUndo)
    // extraContext.apiContext.createBlock = createBlock;
    // extraContext.apiContext.deleteBlocksInGraphic = deleteBlocksInGraphic;
    // extraContext.apiContext.createBlockMulti = createBlockMulti;
    // extraContext.apiContext.findBlocksInGraphic = findBlocksInGraphic;
    // extraContext.apiContext.findOneBlockInGraphic = findOneBlockInGraphic;

    // extraContext.apiContext.addGroup = addGroup;
    // extraContext.apiContext.deleteGroup = deleteGroup;

    // extraContext.apiContext.saveWorkPlane = saveWorkPlane;
    // extraContext.apiContext.deleteWorkPlane = deleteWorkPlane;

    // extraContext.apiContext.findAxisNet = findAxisNet;
}