import { updateRoadNetwork } from "@/api/building/road-network";
import { getRoadTypeDetailByUuid } from "@/api/building/road-type";
import { createPrimitiveMulti } from "@/api/geometry/primitive";
import { ROAD_LANE_TYPE, getRoadTypeShortName } from "@/commons/enums/road-type";
import { RoadNetwork } from "@/commons/interface/building/road-network";
import { RoadType } from "@/commons/interface/building/road-type";
import { graphicStore } from "@/commons/store/graphic-store";
import { Select } from "antd";
import classNames from "classnames";
import { BlockT, LineT, ModelBase } from "pythagoras";
import { useEffect, useState } from "react";
import { Color, OrthographicCamera, Vector3 } from "three";
import { LaneT } from "tnbimbase";


const { Option } = Select;

interface Iprop {
    optionList: RoadType[]
    roadNetwork: RoadNetwork,
    selectList: string[],
    setSelectList: (e) => void
}


export default function RoadListItem(props: Iprop) {

    const [roadType, setRoadType] = useState<string>("")

    const [roadNetwork, setRoadNetwork] = useState<RoadNetwork>(null)

    const [optionList, setOptionList] = useState<RoadType[]>([])

    useEffect(() => {
        setOptionList(props.optionList || [])
        setRoadType(props.roadNetwork?.roadTypeUuid || "")
        setRoadNetwork(props.roadNetwork)
    }, [props])

    const changeRoadType = (typeUuid: string) => {
        setRoadType(typeUuid)
        // 判断该道路是否绑定了图元
        if ((roadNetwork?.roadUuid?.length > 0)) {
            changeLaneList(typeUuid)
        } else {
            generateLaneList(typeUuid)
        }
    }

    const onClick = (e) => {
        if (e.ctrlKey) {
            if (props.selectList.find(item => item == roadNetwork.uuid)) {
                let selectList = props.selectList.filter(item => item != roadNetwork.uuid)
                props.setSelectList([...selectList])
            } else {
                props.setSelectList([...props.selectList, roadNetwork.uuid])
            }
        } else {
            if (roadNetwork?.roadUuid?.length > 0) {
                if (props.selectList.find(item => item == roadNetwork?.uuid)) {
                    let midList = props.selectList?.filter(item => item != roadNetwork?.uuid) || []
                    props.setSelectList([...midList])
                } else {
                    let model = graphicStore.extraContext.getCurrentViewEditor().entityDict[roadNetwork?.roadUuid]
                    if (model) {
                        showEntity(model)
                    }
                    props.setSelectList([roadNetwork.uuid])
                }

            }

        }
    }

    const showEntity = (model: ModelBase) => {
        let box = model.getBoundingBox()
        box.min.project(graphicStore.extraContext.getCurrentViewEditor().camera);
        box.max.project(graphicStore.extraContext.getCurrentViewEditor().camera);
        let x1 = box.min.x
        let y1 = box.min.y
        let x2 = box.max.x
        let y2 = box.max.y
        if (x1 == null || y1 == null || x2 == null || y2 == null) {
            return
        }

        let scale1 = Math.abs(y1 - y2) / 2;

        let scale2 = Math.abs(x1 - x2) / 2;
        // max
        let scale = Math.max(scale1, scale2) * 1.3;
        let x = (x1 + x2) / 2;
        let y = (y1 + y2) / 2;
        let camera = graphicStore.extraContext.getCurrentViewEditor().camera;
        let target = graphicStore.context.ucsContext.unprojectCursorPoint(x, y, camera);

        let lastTarget = graphicStore.extraContext.getCurrentViewEditor().viewControl.target.clone();
        let offset = camera.position.clone().sub(lastTarget);
        const position = camera.position;
        graphicStore.extraContext.getCurrentViewEditor().viewControl.target.copy(target);
        position.copy(target).add(offset);
        graphicStore.extraContext.getCurrentViewEditor().camera.lookAt(target);
        // scale 框和当前边界的比例
        graphicStore.context.renderContext.cameraEdge *= scale;
        if (camera.type === "OrthographicCamera") {
            let orthoCamera = camera as OrthographicCamera;
            orthoCamera.left = orthoCamera.left * scale;
            orthoCamera.right = orthoCamera.right * scale;
            orthoCamera.top = orthoCamera.top * scale;
            orthoCamera.bottom = orthoCamera.bottom * scale;
            orthoCamera.updateProjectionMatrix();
        }


        graphicStore.extraContext.listeners.signals.onZoomChanged.dispatch(1 / scale);
    }

    const changeLaneList = (typeUuid: string) => {
        // 获取选择的路型的详细信息
        getRoadTypeDetailByUuid(typeUuid).then(res => {
            updateRoadNetwork(roadNetwork?.uuid, { roadTypeUuid: typeUuid })
            // 获取该道路绑定的图元
            let model = graphicStore.extraContext.getCurrentViewEditor().entityDict[roadNetwork?.roadUuid]
            if (!model) return
            let blockModel = model as BlockT
            let entities = blockModel.entities
            // 获取路型线
            let baseLine: LineT = (entities.find(item => item.type == "LineT") as LineT)
            // 获取线的方向
            let lineDir: Vector3 = baseLine._geo.end.clone().sub(baseLine._geo.start)
            // 获取当前的normal
            let worldNormal: Vector3 = graphicStore.context.ucsContext.currentUcs.axisZ.clone()
            // 获取线的y方向
            let lineAxiosY = worldNormal.cross(lineDir).normalize()
            // 获取线的normal方向
            let lineNormal = lineDir.cross(lineAxiosY).normalize()

            let typeDetail: RoadType = res.data
            // 获取左右车道的列表
            let leftLaneList = typeDetail?.laneList?.filter(item => item.type == ROAD_LANE_TYPE.LEFT) || []
            let rightLaneList = typeDetail?.laneList?.filter(item => item.type == ROAD_LANE_TYPE.RIGHT) || []
            // 获取左右人行道宽度
            let leftWalk = typeDetail?.leftSidewalk || 0
            let rightWalk = typeDetail?.rightSidewalk || 0
            // 获取左右车道modelList
            let leftLaneModelList: LaneT[] = []
            let rightLaneModelList: LaneT[] = []
            // 累计左右偏移量
            let leftDeviation: number = 0
            let rightDeviation: number = 0
            // 记录widthMap存入balockT中
            let widthMap: { [key: string]: number } = {}
            for (let i = 0; i < leftLaneList.length; i++) {
                let midLane: LaneT = new LaneT()
                leftDeviation += (leftLaneList[i].width) / 2
                let line = baseLine._geo.clone()
                midLane.setByLine(line, lineNormal, lineAxiosY, (leftLaneList[i].width) * 1000, -(leftDeviation) * 1000)
                leftLaneModelList.push(midLane)
                leftDeviation += (leftLaneList[i].width) / 2
                widthMap[midLane.uuid] = leftLaneList[i].width
            }
            // 加入左人行道
            if (leftWalk > 0) {
                let leftWalkModel: LaneT = new LaneT()
                leftWalkModel.isSideWalk = true
                leftWalkModel.setByLine(baseLine._geo.clone(), lineNormal, lineAxiosY, leftWalk * 1000, -(leftDeviation + leftWalk / 2) * 1000)
                leftLaneModelList.push(leftWalkModel)
                widthMap[leftWalkModel.uuid] = leftWalk
            }

            for (let i = 0; i < rightLaneList.length; i++) {
                let midLane: LaneT = new LaneT()
                rightDeviation += (rightLaneList[i].width) / 2
                let line = baseLine._geo.clone()
                midLane.setByLine(line, lineNormal, lineAxiosY, (rightLaneList[i].width) * 1000, rightDeviation * 1000)
                // midLane.initMap()
                rightLaneModelList.push(midLane)
                rightDeviation += (rightLaneList[i].width) / 2
                widthMap[midLane.uuid] = rightLaneList[i].width
            }
            // 加入右人行道
            if (rightWalk > 0) {
                let rightWalkModel: LaneT = new LaneT()
                rightWalkModel.isSideWalk = true
                rightWalkModel.setByLine(baseLine._geo.clone(), lineNormal, lineAxiosY, rightWalk * 1000, (rightDeviation + rightWalk / 2) * 1000)
                rightLaneModelList.push(rightWalkModel)
                widthMap[rightWalkModel.uuid] = rightWalk
            }

            let laneModelList = [...leftLaneModelList, ...rightLaneModelList]
            entities = entities.filter(item => item.type != "LaneT")

            entities.push(...laneModelList)
            blockModel.entities = [...entities]

            let extraData = {
                ...blockModel.extraData,
                widthMap: widthMap,
                normal_x: lineNormal.x,
                normal_y: lineNormal.y,
                normal_z: lineNormal.z,
            }

            blockModel.extraData = extraData
            let color = new Color(graphicStore.context.layerContext.currentColor)
            blockModel.changeColor(color, true)


            createPrimitiveMulti([blockModel.toJson()])
            graphicStore.extraContext.getCurrentViewEditor().entityDict[roadNetwork?.roadUuid] = blockModel
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
        })
    }

    const generateLaneList = (typeUuid: string) => {
        // 获取选择的路型的详细信息
        getRoadTypeDetailByUuid(typeUuid).then(res => {
            updateRoadNetwork(roadNetwork?.uuid, { roadTypeUuid: typeUuid })
            // 获取该道路绑定的图元
            let model = graphicStore.extraContext.getCurrentViewEditor().entityDict[roadNetwork?.roadUuid]
            if (!model) return
            let blockModel = model as BlockT
            let entities = blockModel.entities
            // 获取路型线
            let baseLine: LineT = (entities.find(item => item.type == "LineT") as LineT)
            // 获取线的方向
            let lineDir: Vector3 = baseLine._geo.end.clone().sub(baseLine._geo.start)
            // 获取当前的normal
            let worldNormal: Vector3 = graphicStore.context.ucsContext.currentUcs.axisZ.clone()
            // 获取线的y方向
            let lineAxiosY = worldNormal.cross(lineDir).normalize()
            // 获取线的normal方向
            let lineNormal = lineDir.cross(lineAxiosY).normalize()

            let typeDetail: RoadType = res.data
            // 获取左右车道的列表
            let leftLaneList = typeDetail?.laneList?.filter(item => item.type == ROAD_LANE_TYPE.LEFT) || []
            let rightLaneList = typeDetail?.laneList?.filter(item => item.type == ROAD_LANE_TYPE.RIGHT) || []
            // 获取左右人行道宽度
            let leftWalk = typeDetail.leftSidewalk || 0
            let rightWalk = typeDetail.rightSidewalk || 0
            // 获取左右车道modelList
            let leftLaneModelList: LaneT[] = []
            let rightLaneModelList: LaneT[] = []
            // 累计左右偏移量
            let leftDeviation: number = 0
            let rightDeviation: number = 0
            // 记录widthMap存入balockT中
            let widthMap: { [key: string]: number } = {}
            for (let i = 0; i < leftLaneList.length; i++) {
                let midLane: LaneT = new LaneT()
                leftDeviation += (leftLaneList[i].width) / 2
                let line = baseLine._geo.clone()
                midLane.setByLine(line, lineNormal, lineAxiosY, (leftLaneList[i].width) * 1000, -(leftDeviation) * 1000)
                leftLaneModelList.push(midLane)
                leftDeviation += (leftLaneList[i].width) / 2
                widthMap[midLane.uuid] = leftLaneList[i].width
            }
            // 加入左人行道
            if (leftWalk > 0) {
                let leftWalkModel: LaneT = new LaneT()
                leftWalkModel.isSideWalk = true
                leftWalkModel.setByLine(baseLine._geo.clone(), lineNormal, lineAxiosY, leftWalk * 1000, -(leftDeviation + leftWalk / 2) * 1000)
                leftLaneModelList.push(leftWalkModel)
                widthMap[leftWalkModel.uuid] = leftWalk
            }


            for (let i = 0; i < rightLaneList.length; i++) {
                let midLane: LaneT = new LaneT()
                rightDeviation += (rightLaneList[i].width) / 2
                let line = baseLine._geo.clone()
                midLane.setByLine(line, lineNormal, lineAxiosY, (rightLaneList[i].width) * 1000, rightDeviation * 1000)
                leftLaneModelList.push(midLane)
                rightDeviation += (rightLaneList[i].width) / 2
                widthMap[midLane.uuid] = rightLaneList[i].width
            }
            // 加入右人行道
            if (rightWalk > 0) {
                let rightWalkModel: LaneT = new LaneT()
                rightWalkModel.isSideWalk = true
                rightWalkModel.setByLine(baseLine._geo.clone(), lineNormal, lineAxiosY, rightWalk * 1000, (leftDeviation + rightWalk / 2) * 1000)
                rightLaneModelList.push(rightWalkModel)
                widthMap[rightWalkModel.uuid] = rightWalk
            }

            let laneModelList = [...leftLaneModelList, ...rightLaneModelList]
            entities = entities.filter(item => item.type != "LaneT")

            entities.push(...laneModelList)
            blockModel.entities = [...entities]

            let extraData = {
                ...blockModel.extraData,
                widthMap: widthMap,
                normal_x: lineNormal.x,
                normal_y: lineNormal.y,
                normal_z: lineNormal.z,
            }

            blockModel.extraData = extraData

            let color = new Color(graphicStore.context.layerContext.currentColor)
            blockModel.changeColor(color, true)

            createPrimitiveMulti([blockModel.toJson()])
            graphicStore.extraContext.getCurrentViewEditor().entityDict[roadNetwork?.roadUuid] = blockModel
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');

        })
    }

    return (
        <div className={classNames({
            "road-item": true,
            "road-item-active": props.selectList.find(item => item == roadNetwork?.uuid)
        })}
            onClick={(e) => { onClick(e) }}>
            <div className="name">
                {roadNetwork?.name}
            </div>
            <Select className="select" value={roadType} onClick={(e) => { e.stopPropagation() }} onChange={(e) => { changeRoadType(e) }}>
                {optionList?.map((item, index) => (
                    <Option value={item.uuid} key={index}>
                        {getRoadTypeShortName(item.type) + "-" + item.totalWidth + "m-" + item.totalLaneSize + "车道"}
                    </Option>
                ))}
            </Select>
        </div>
    )
}