import { SideBarBtnType } from '@/commons/enums/side-bar-btn-type';
import './index.less';
import SideBarTool from "@/commons/components/side-bar-tool";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addRoom, deleteRoom, getRooms, updateRoom } from '@/api/building/room';
import { RoomType } from '@/commons/enums/room-type';
import { Room, StoreyRoom } from '@/commons/interface/building/room';
import { Input, Modal, message } from 'antd';
import { ReactComponent as ConfimSvg } from '@/commons/icons/marshalling/confim.svg';
import { ReactComponent as EditSvg } from '@/commons/icons/marshalling/edit.svg';
import classNames from 'classnames';
import { graphicStore } from '@/commons/store/graphic-store';
import { AddPrimitivesCommand, ModelBase } from 'pythagoras';
import { BimModelBase, GroupType } from 'tnbimbase';
import TnModal from '@/commons/widget/base-modal';



export default function PumpRoomPanel() {

    const { projectId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [displayRooms, setDisplayRooms] = useState<Room[]>([]);
    const [activeRoom, setActiveRoom] = useState<Room>();
    const [editRoomUuid, setEditRoomUuid] = useState<string>('');
    const [editRoomName, setEditRoomName] = useState<string>('');
    const [showAddRoom, setShowAddRoom] = useState<boolean>(false);
    const [showBtnGroup, setShowBtnGroup] = useState<boolean>(false);

    useEffect(() => {
        getRooms(projectId, RoomType.PUMPS).then(res => {
            if (res.status === 200) {
                let displayRooms: StoreyRoom[] = res.data || [];
                if (displayRooms?.length > 0) {
                    let rooms = displayRooms[0].rooms || [];
                    setDisplayRooms(rooms);
                } else {
                    setDisplayRooms([]);
                }
            }
        })
    }, [projectId])


    const onDeleteClick = () => {
        if (!activeRoom) {
            message.warning("没有选中房间");
            return;
        }
        TnModal().deleteModal({
            closable: false,
            onOk() {
                deleteRoom(projectId, activeRoom.uuid).then(res => {
                    message.success("删除成功");
                    // 1 去除属性
                    let idx = displayRooms.findIndex(item => item.uuid === activeRoom.uuid);
                    let room = displayRooms[idx];
                    let priUuids = room?.primitiveUuids || [];
                    if (priUuids?.length > 0) {
                        let newEntities = [];
                        for (let uuid of priUuids) {
                            let entity = graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid];
                            if (!(entity as BimModelBase).group[GroupType.PUMP_ROOM]) {
                                continue;
                            }
                            let groupUuids = (entity as BimModelBase).group[GroupType.PUMP_ROOM];
                            groupUuids = groupUuids?.filter(item => item !== activeRoom.uuid) || [];
                            (entity as BimModelBase).group[GroupType.PUMP_ROOM] = groupUuids;
                            newEntities.push(entity);
                        }
                        new AddPrimitivesCommand(newEntities).execute();
                    }
                    // 2 修改列表
                    displayRooms.splice(idx, 1);
                    setDisplayRooms([...displayRooms]);

                }).finally(() => {
                    Modal.destroyAll();
                })
            },
            onCancel() {
                Modal.destroyAll();
            },
        })
    }

    const onRoomClick = (sub) => {
        setActiveRoom(sub);
        let entities: ModelBase[] = sub?.primitiveUuids?.map(uuid => graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid]);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
        if (entities?.length > 0) {
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.forceEntitiesSelected.dispatch(entities);
        }
    }

    const onAddBtnClick = () => {
        setEditRoomName("");
        setEditRoomUuid("");
        setShowAddRoom(true);
    }

    const onAddSave = () => {
        if (loading) return;
        if (!editRoomName) {
            setEditRoomName("");
            setShowAddRoom(false);
            return;
        }
        setLoading(true);
        let data: Room = {
            projectUuid: projectId,
            name: editRoomName,
            type: RoomType.PUMPS,
        }
        addRoom(projectId, data).then(res => {
            if (res.status === 200) {
                let uuid = res.data;
                message.success("添加成功");
                data.uuid = uuid;
                displayRooms.push(data);
                setDisplayRooms([...displayRooms]);
            }
        }).catch(() => {
            message.error("添加失败");
        }).finally(() => {
            setLoading(false);
            setShowAddRoom(false);
            setEditRoomName("");
            setEditRoomUuid("");
        })
    }

    const onRoomEditClick = (room: Room) => {
        setEditRoomUuid(room.uuid);
        setEditRoomName(room.name);
        setActiveRoom(room);
        setShowBtnGroup(true);
        let entities: ModelBase[] = room?.primitiveUuids?.map(uuid => graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid]);
        if (entities?.length > 0) {
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.forceEntitiesSelected.dispatch(entities);
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onCursorChange.dispatch(3);
        }
    }

    const onSave = () => {
        // 1.获取当前选中的图元
        let selectedList = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList();
        let newPriUuids: string[] = selectedList?.map(item => item.uuid) || [];
        let activeRoomUuids = new Set(activeRoom?.primitiveUuids || []);
        let newPriUuidsSet = new Set(newPriUuids);
        let addedUuids = newPriUuids.filter(uuid => !activeRoomUuids.has(uuid));
        let removedUuids = [...activeRoomUuids].filter(uuid => !newPriUuidsSet.has(uuid));

        // 2.传递参数回去
        let data: Room = {
            ...activeRoom,
            name: editRoomName,
        }
        // 3.修改api 同步修改图元属性
        updateRoom(activeRoom.uuid, data).then(res => {
            if (res.status === 200) {
                // 1 修改图元属性（旧的需要删除属性，新的需要新增属性）
                let newEntities = [];
                for (let uuid of addedUuids) {
                    let entity = graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid];
                    if (!(entity as BimModelBase).group[GroupType.PUMP_ROOM]) {
                        (entity as BimModelBase).group[GroupType.PUMP_ROOM] = [];
                    }
                    let groupUuids = (entity as BimModelBase).group[GroupType.PUMP_ROOM] || [];
                    groupUuids.push(activeRoom.uuid);
                    (entity as BimModelBase).group[GroupType.PUMP_ROOM] = groupUuids;
                    newEntities.push(entity);
                }

                for (let uuid of removedUuids) {
                    let entity = graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid];
                    if (!(entity as BimModelBase).group[GroupType.PUMP_ROOM]) {
                        continue;
                    }
                    let groupUuids = (entity as BimModelBase).group[GroupType.PUMP_ROOM];
                    groupUuids = groupUuids?.filter(item => item !== activeRoom.uuid) || [];
                    (entity as BimModelBase).group[GroupType.PUMP_ROOM] = groupUuids;
                    newEntities.push(entity);
                }
                new AddPrimitivesCommand(newEntities).execute();
                // 2 修改列表
                let idx = displayRooms?.findIndex(item => item.uuid === activeRoom.uuid);
                displayRooms[idx].primitiveUuids = newPriUuids;
                displayRooms[idx].name = editRoomName;
                setDisplayRooms([...displayRooms]);
                message.success("编辑成功");

            }
        }).catch(err => {
            console.log('err', err)
            message.error("编辑失败");
        }).finally(() => {
            onCancel();
        })
    }

    const onCancel = () => {
        setEditRoomName("");
        setEditRoomUuid("");
        setShowBtnGroup(false);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onCursorChange.dispatch(1);
    }

    return (
        <div className='pump-room-container'>
            <SideBarTool
                activeBtnList={[SideBarBtnType.DELETE, SideBarBtnType.ADD]}
                onDeleteBtnClick={onDeleteClick}
                onAddBtnClick={onAddBtnClick}
            />
            <div className="room-side-title">泵房</div>
            <div className='room-content-area'>
                <div className='room-content'>
                    {showAddRoom && (
                        <div className={classNames({
                            'room-row': true,
                            'room-row-active': true,
                        })}>
                            <div className='text-area'>
                                <Input
                                    value={editRoomName}
                                    onChange={(e) => setEditRoomName(e.target.value)}
                                    onPressEnter={onAddSave}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Escape') {
                                            setShowAddRoom(false);
                                            setEditRoomName("");
                                            setEditRoomUuid("");
                                        }
                                    }}
                                    placeholder='单击输入房间名称'
                                    autoFocus
                                />
                            </div>
                            <div className='icon-area' onClick={onAddSave}><ConfimSvg /></div>
                        </div>
                    )}
                    {displayRooms?.map((item, key) => (
                        <div
                            key={key}
                            className={classNames({
                                'room-row': true,
                                'room-row-active': activeRoom?.uuid === item.uuid,
                            })}
                            onClick={() => onRoomClick(item)}
                        >
                            <div className='text-area'>
                                {editRoomUuid !== item.uuid && `${item?.name}`}
                                {editRoomUuid === item.uuid && <Input
                                    value={editRoomName}
                                    onChange={(e) => setEditRoomName(e.target.value)}
                                    onPressEnter={onSave}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Escape') {
                                            setShowAddRoom(false);
                                            setEditRoomName("");
                                            setEditRoomUuid("");
                                            setShowBtnGroup(false);
                                            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
                                        }
                                    }}
                                    placeholder='单击输入房间名称'
                                    autoFocus
                                />}
                            </div>
                            <div
                                className='icon-area'

                            >
                                {editRoomUuid !== item?.uuid && <EditSvg onClick={(e) => {
                                    e.stopPropagation();
                                    onRoomEditClick(item);
                                }} />}
                                {editRoomUuid === item?.uuid && <ConfimSvg onClick={onSave} />}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showBtnGroup && <div className='room-btn-group'>
                <div className="btn save" onClick={onSave}>确定</div>
                <div className="btn cancel" onClick={onCancel}>取消</div>
            </div>}
        </div>
    )
}