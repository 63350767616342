/**
 * 二级可展开下拉框，双击条目可编辑
 */
import "./index.less"
import { MainDropdownBoxItem } from "./main-dropdown-box-item"

interface Iprops {
    datas: {
        uuid?: string
        name?: string
        nameSuffix?: string
        childList?: { uuid: string, name: string, isSelected: boolean }[]
    }[]

    selectedUuid: string
    onChangeSelectedUuid: (value: string) => void
    setSelectedEntity: (uuid: string, selected: boolean) => void
    updateName: (uuid: string, name: string) => void
}



export function SecondaryDropdownBox(props: Iprops) {
    return (
        <div className="secondary-dropdown-box-container">
            {(props.datas || []).map(item => (
                <MainDropdownBoxItem
                    key={item.uuid}
                    selected={props.selectedUuid === item.uuid}
                    onClick={() => { props.onChangeSelectedUuid(item.uuid) }}
                    name={`${item.name}${item.nameSuffix ? item.nameSuffix : ''}`}
                    dataList={item.childList}
                    setSelectedEntity={props.setSelectedEntity}
                    updateName={props.updateName}
                />
            ))}
        </div>
    );
}
