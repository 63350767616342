import { useCallback, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { RenderMode } from "pythagoras";
import { CAMERA_TYPE } from "@/panel/draw/viewport";

export interface SystemStatus {

    rws: ReconnectingWebSocket
    resetRws: (value: ReconnectingWebSocket) => void

    updateDrawPanel: boolean
    setUpdateDrawPanel: (value: boolean) => void

    currentRenderMode: number
    setCurrentRenderMode: (value: number) => void

    currentCameraType: number
    setCurrentCameraType: (value: number) => void
}

export function useSystemService() {

    const [rws, setRws] = useState<ReconnectingWebSocket>(null); // WebSocket
    const [updateDrawPanel, setUpdateDrawPanel] = useState<boolean>(); // 重新初始化绘图区
    const [currentRenderMode, setCurrentRenderMode] = useState<number>(RenderMode.CONCEPT); // 当前渲染类型（0-线框 1-概念）

    const [currentCameraType, setCurrentCameraType] = useState<number>(CAMERA_TYPE.Orthographic);  //当前相机类型

    const resetRws = useCallback((value: ReconnectingWebSocket) => {
        if (rws != null) {
            rws.close();
        }
        setRws(value);
    }, [])


    return {
        rws, resetRws,
        updateDrawPanel, setUpdateDrawPanel,
        currentRenderMode, setCurrentRenderMode,
        currentCameraType, setCurrentCameraType,
    } as SystemStatus
}

