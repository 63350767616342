import { createComponent } from "@/api/spec-common/component";
import { message } from "antd";
import { ModelBase, Point } from "pythagoras";
import { ComponentT } from "tnbimbase";
import { Vector3 } from "three";
import { graphicStore } from "@/commons/store/graphic-store";

export const ImportComponent = (event, projectUuid: string, type, name?: string, successCallback?: any) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    let filename: string = file.name;
    let originName = filename.slice(0, filename?.lastIndexOf('.'));
    let componentName = name != null ? name : originName
    reader.onloadend = (e) => onSuccess(e, projectUuid, type, componentName, successCallback);
    reader.onabort = abortUpload;
    reader.onerror = errorHandler;
    reader.readAsText(file, "UTF-8");
}

const onSuccess = (event, projectUuid, type, name, successCallback) => {
    let fileReader = event.target;
    if (fileReader.error) return console.log("error onloadend!");
    let json = JSON.parse(fileReader.result);
    let entities = json.entities.map((item: any) => {
        let entity: ModelBase =  new (graphicStore.context.modelContext.getModelConstructor(item.type))();
        entity.fromJson(item);
        return entity;
    });
    let component: ComponentT = new ComponentT(type, new Point(0, 0, 0), entities, name, new Vector3(0, 0, 1), new Vector3(1, 0, 0));

    let componentJson = component.toJson();
    createComponent({ ...componentJson, projectUuid: projectUuid }).then(res => {
        message.success("导入成功！");
        if (typeof successCallback === 'function') {
            successCallback(res);
        }
    }).catch(err => {
        console.error("Error creating component:", err);
    });
}

const abortUpload = () => {
    console.log('Aborted read');
}

const errorHandler = (event) => {
    switch (event.target.error.code) {
        case event.target.error.NOT_FOUND_ERR:
            alert('File Not Found!');
            break;
        case event.target.error.NOT_READABLE_ERR:
            alert('File is not readable');
            break;
        case event.target.error.ABORT_ERR:
            break; // noop
        default:
            alert('An error occurred reading this file.');
    }
}