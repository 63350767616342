import SideBarTool from "@/commons/components/side-bar-tool";
import { HoleCircleImg, HoleRectImg } from "@/commons/constants/schematic-diagram";
import { Input, Select, Image, message } from "antd";
import { useState } from "react";
import './index.less'
import { Circle, Line, LineType, Point, Polygon } from "pythagoras";
import { Vector3 } from "three";
import { graphicStore } from "@/commons/store/graphic-store";
import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { HoleT } from "tnbimbase";
import { LayerType } from "@/commons/enums/layer-type";
import genUUID from "@/commons/utils/gen-uuid";
import { Layer } from "@/commons/interface/layer";
import { useParams } from "react-router";
import { saveLayer } from "@/api/geometry/layer";

export default function HolePanel() {
    const { projectId } = useParams();
    const [selectedShape, setSelectedShape] = useState<string>('rect');
    const [length, setLength] = useState<number>(); // 长
    const [width, setWidth] = useState<number>(); // 宽
    const [angle, setAngle] = useState<number>(0); // 偏心角
    const [radius, setRadius] = useState<number>(); // 半径

    const onAssembleHole = () => {
        if (selectedShape == 'rect') {
            if (!length || !width) {
                message.warning("请输入长度或宽度")
                return;
            }
        } else if (selectedShape == 'circle') {
            if (!radius) {
                message.warning("请输入半径")
                return;
            }
        }
        let polygon = new Polygon([], new Vector3(1, 0, 0), new Point(0, 0, 0));
        if (selectedShape == 'rect') {
            let points: Point[] = [
                new Point(-length / 2, -width / 2, 0), new Point(length / 2, -width / 2, 0), new Point(length / 2, width / 2, 0), new Point(-length / 2, width / 2, 0)
            ]
            let curves = [];
            for (let i = 0; i < points.length; i++) {
                if (i != points.length - 1) {
                    curves.push(new Line(points[i], points[i + 1]));
                } else {
                    curves.push(new Line(points[i], points[0]));
                }
            }
            polygon.set(curves);
            if (!!angle) {
                // 沿着中心旋转
                // 因贯穿洞口是向体内拉伸的，从体外观察洞口，相当于从背面观察，这里顺时针旋转
                polygon.rotate(new Point(0, 0, 0), new Vector3(0, 0, 1), Math.PI - (angle * Math.PI) / 180);
                polygon.axisX.copy(new Vector3(1, 0, 0));
            }
        } else if (selectedShape == 'circle') {
            polygon.set([new Circle(new Point(0, 0, 0), radius)]);
        }
        if (!graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl || graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl.id !== EXTRA_CTRL_TYPE.ASSEMBLE_HOLE) {
            // 激活插入组件的二级控制器
            graphicStore.extraContext.getCurrentViewEditor().cmdControl.activateSubCommandControl("ASSEMBLEHOLE");
        }
        let hole = null;
        if (polygon.edges.length > 0) {
            hole = new HoleT(polygon.edges);
            hole._geoPolygon.basePoint.set(0, 0, 0);
        }
        let layerUuid = graphicStore.context.layerContext.layers?.find(item => item?.type === LayerType.HOLE)?.uuid || null;
        if (!layerUuid) {
            // 不存在洞口图层，新建洞口图层
            let uuid = genUUID();
            let newLayer: Layer = {
                uuid: uuid,
                name: "洞口",
                color: "#000000",
                lineWidth: 0.25,
                lineType: LineType.BASE_LINE,
                lock: false,
                hidden: false,
                projectUuid: projectId,
                originLayer: false,
                type: LayerType.HOLE,
            };
            saveLayer(newLayer).then(res => {
                if (res.status === 200) {
                    graphicStore.context.layerContext.addLayer(newLayer);
                    graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleHole).dispatch(newLayer.uuid, hole);
                }
            }).catch(err => {
                message.error("无法置为洞口图层");
                console.log(err);
                let currentLayerUuid = graphicStore.context.layerContext.currentLayerUuid;
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleHole).dispatch(currentLayerUuid, hole);

            })
        } else {
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleHole).dispatch(layerUuid, hole);
        }
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    洞口
                </div>
                <div className="detail hole-panel">
                    <div className="row">
                        <div className="label">洞口样式:</div>
                        <Select
                            size='small'
                            className="select-box"
                            value={selectedShape}
                            // allowClear
                            onChange={(value) => setSelectedShape(value)}
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            <Select.Option value="rect">方形</Select.Option>
                            <Select.Option value="circle">圆形</Select.Option>
                            <Select.Option value="draw">屏幕指定</Select.Option>
                        </Select>
                    </div>
                    {selectedShape != "draw" && (
                        <div className="title-row">
                            <div className="title-row-text">{selectedShape == 'rect' ? "方形洞口尺寸" : "圆形洞口尺寸"}</div>
                        </div>
                    )}
                    {selectedShape == 'rect' &&
                        <>
                            <div className="row">
                                <div className="label">长:</div>
                                <Input
                                    className="input-box"
                                    value={length}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (!isNaN(parseInt(value))) {
                                            setLength(parseInt(value))
                                        } else {
                                            if (value == '') {
                                                setLength(null);
                                            } else {
                                                setLength(0)
                                            }
                                        }
                                    }}
                                    suffix="mm"
                                />
                            </div>
                            <div className="row">
                                <div className="label">宽:</div>
                                <Input
                                    className="input-box"
                                    value={width}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (!isNaN(parseInt(value))) {
                                            setWidth(parseInt(value))
                                        } else {
                                            if (value == '') {
                                                setWidth(null);
                                            } else {
                                                setWidth(0)
                                            }
                                        }
                                    }}
                                    suffix="mm"
                                />
                            </div>
                            <div className="row">
                                <div className="label">偏心角:</div>
                                <Input
                                    className="input-box"
                                    value={angle}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (!isNaN(parseInt(value))) {
                                            setAngle(parseInt(value))
                                        } else {
                                            if (value == '') {
                                                setAngle(null);
                                            } else {
                                                setAngle(0)
                                            }
                                        }
                                    }}
                                    suffix="度"
                                />
                            </div>
                            <div className="schematic-diagram">
                                <Image width={144} height={144} preview src={HoleRectImg} />
                            </div>
                        </>
                    }
                    {selectedShape == 'circle' &&
                        <>
                            <div className="row">
                                <div className="label">半径:</div>
                                <Input
                                    className="input-box"
                                    value={radius}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (!isNaN(parseInt(value))) {
                                            setRadius(parseInt(value))
                                        } else {
                                            if (value == '') {
                                                setRadius(null);
                                            } else {
                                                setRadius(0)
                                            }
                                        }
                                    }}
                                    suffix="mm"
                                />
                            </div>
                            <div className="schematic-diagram">
                                <Image width={128} height={128} preview src={HoleCircleImg} />
                            </div>
                        </>
                    }
                    <div className="ope-area">
                        <div className="primary-btn" onClick={onAssembleHole}>确定</div>
                        <div className="secondary-btn" onClick={() => { }}><div className="text">取消</div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}