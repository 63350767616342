export const LayerType = {
    AXIS_NET: 1, // 轴网
    ELEVATOR_CAR: 21, // 轿厢
    WALL:22, // 墙
    HOLE:23, // 洞口
    DOOR:24, // 门
    WINDOW:25, // 窗

    /**
     * 楼板
     */
    FLOOR: 2,

    /**
     * 地面
     */
    GROUND: 3,

    /**
     * 吊顶
     */
    CEILING: 4,

    
    PARKING_SPACE: 520,  // 机动车位&非机动车位

    COLUMN: 210, // 柱

    /**
     * 巡更点 
     */
    PATROL_POINT: 77,



    TREES: 9, // 乔灌木
}