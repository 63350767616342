import SideBarTool from "@/commons/components/side-bar-tool";
import { ReactComponent as ShowSvg } from "@/commons/icons/panel-icon/show.svg";
import { ContextMenuFC } from "tncet-common";
import "../../commons/styles/side-bar.less"
import "./index.less"
import { useContext, useEffect, useRef, useState } from "react";
import { Input, InputRef, Select } from "antd";
import LocalScene from "@/commons/components/local-scene";
import { ColumnT } from "tnbimbase";
import { AddLayerCommand, Circle, Line, LineT, LineType, Point, Polygon } from "pythagoras";
import { Vector3 } from "three";
import { graphicStore } from "@/commons/store/graphic-store";
import { StoreyStatus } from "@/commons/context/storey-store";
import { StoreyContext } from "@/App";
import { LayerType } from "@/commons/enums/layer-type";
import genUUID from "@/commons/utils/gen-uuid";
import { Layer } from "@/commons/interface/layer";
import { useParams } from "react-router";

const { Option } = Select;

const defauleData1 = [
    350, 400, 450, 500, 600, 650, 700, 750, 800, 850, 900, 950, 1000
]

const defauleData2 = [
    2700, 2800, 2900, 3000, 3600, 3900, 4200, 5100, 5400
]

export default function ColumnPanel(second) {

    const { projectId } = useParams()

    const [sectionType, setSectionType] = useState<string>("1")

    const [tableItem1Value1, setTableItem1Value1] = useState<string>("")
    const [tableItem1Value2, setTableItem1Value2] = useState<string>("")

    const [tableItem2Value1, setTableItem2Value1] = useState<string>("")
    const [tableItem2Value2, setTableItem2Value2] = useState<string>("")

    const [tableItem3Value1, setTableItem3Value1] = useState<string>("")
    const [tableItem3Value2, setTableItem3Value2] = useState<string>("")

    const [selectInputIndex, setSelectInputIndex] = useState<number>()

    const [entities, setEntities] = useState<any[]>([]);

    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const [visible, setVisible] = useState<boolean>(false);

    const ref_1 = useRef<InputRef>();
    const ref_2 = useRef<InputRef>();
    const ref_3 = useRef<InputRef>();

    const storeyContext = useContext<StoreyStatus>(StoreyContext);

    useEffect(() => {
        let height = storeyContext.currentStorey?.height || 3000
        setTableItem1Value1("350")
        setTableItem1Value2("")
        setTableItem2Value1("350")
        setTableItem2Value2("")
        setTableItem3Value1(height.toString())
        setTableItem3Value2("")
        setSectionType("1")
    }, [])

    useEffect(() => {
        // 判断柱子类型
        if (sectionType == "1") {   // 矩形
            // 判断三个初始值是否都有值
            if (tableItem1Value1?.length > 0 && tableItem2Value1?.length > 0 && tableItem3Value1?.length > 0) {
                let width: number = +tableItem1Value1
                let length: number = +tableItem2Value1
                let height: number = +tableItem3Value1

                let maxLength = Math.max(width, length)
                // 构建x，y两个直线
                let xLine = new LineT(new Point(-maxLength * 2, 0, 0), new Point(maxLength * 2, 0, 0))
                let yLine = new LineT(new Point(0, -maxLength * 2, 0), new Point(0, maxLength * 2, 0))
                xLine.color.set(1, 0, 0)
                yLine.color.set(1, 0, 0)
                // 构建柱子
                let point1 = new Point(-width / 2, length / 2, 0)
                let point2 = new Point(width / 2, length / 2, 0)
                let point3 = new Point(-width / 2, -length / 2, 0)
                let point4 = new Point(width / 2, -length / 2, 0)
                // 判断是否有转角
                if (tableItem3Value2?.length > 0) {
                    let angle = +tableItem3Value2 || 0
                    let piAngle = Math.PI * angle / 180
                    point1.rotate(new Point(0, 0, 0), new Vector3(0, 0, 1), piAngle)
                    point2.rotate(new Point(0, 0, 0), new Vector3(0, 0, 1), piAngle)
                    point3.rotate(new Point(0, 0, 0), new Vector3(0, 0, 1), piAngle)
                    point4.rotate(new Point(0, 0, 0), new Vector3(0, 0, 1), piAngle)
                }

                // 判断是否有偏移值
                if (tableItem1Value2?.length > 0 || tableItem2Value2?.length > 0) {
                    let xMove: number = +tableItem1Value2 || 0
                    let yMove: number = +tableItem2Value2 || 0
                    point1.move(new Vector3(1, 0, 0), xMove)
                    point2.move(new Vector3(1, 0, 0), xMove)
                    point3.move(new Vector3(1, 0, 0), xMove)
                    point4.move(new Vector3(1, 0, 0), xMove)

                    point1.move(new Vector3(0, 1, 0), yMove)
                    point2.move(new Vector3(0, 1, 0), yMove)
                    point3.move(new Vector3(0, 1, 0), yMove)
                    point4.move(new Vector3(0, 1, 0), yMove)
                }
                let bottomFace = new Polygon([new Line(point1, point3), new Line(point3, point4), new Line(point4, point2), new Line(point2, point1)])
                let colunmT = new ColumnT(bottomFace, new Vector3(0, 0, 1), height)
                colunmT.color.set(1, 1, 1)
                setEntities([xLine, yLine, colunmT])
            } else {
                setEntities([new LineT(new Vector3(0, 0, 0), new Vector3(0, 0, 0))])
            }
        } else if (sectionType == "2") {    // 圆形
            // 判断是否有值
            if (tableItem1Value1?.length > 0 && tableItem3Value1?.length > 0) {
                let radius: number = (+tableItem1Value1) / 2
                let height: number = +tableItem3Value1

                // 构建x，y两个直线
                let xLine = new LineT(new Point(-radius * 3, 0, 0), new Point(radius * 3, 0, 0))
                let yLine = new LineT(new Point(0, -radius * 3, 0), new Point(0, radius * 3, 0))
                xLine.color.set(1, 0, 0)
                yLine.color.set(1, 0, 0)
                let center: Point = new Point(0, 0, 0)
                // 判断是否有偏移值
                if (tableItem1Value2?.length > 0 || tableItem2Value2?.length > 0) {
                    center.x += (+tableItem1Value2 || 0)
                    center.y += (+tableItem2Value2 || 0)
                }

                let bottomFace = new Polygon([new Circle(center, radius)])
                let colunmT = new ColumnT(bottomFace, new Vector3(0, 0, 1), height)
                colunmT.color.set(1, 1, 1)
                setEntities([xLine, yLine, colunmT])
            } else {
                setEntities([new LineT(new Vector3(0, 0, 0), new Vector3(0, 0, 0))])
            }
        }

    }, [tableItem1Value1, tableItem2Value1, tableItem3Value1, sectionType, tableItem1Value2, tableItem2Value2, tableItem3Value2])

    const showDefaultOptions = (index: number) => {
        let ele: InputRef
        let width = 32
        if (index == 1) {
            ele = ref_1.current
        } else if (index == 2) {
            ele = ref_2.current
        } else {
            ele = ref_3.current
        }
        setSelectInputIndex(index)
        if (ele == null) return;
        let bound = ele.input.getBoundingClientRect()
        let clientX = bound.left + bound.width / 2 - width / 2;
        let bodyW = document.body.clientWidth;
        if (clientX + width > bodyW) {
            clientX = bound.right - width;
        }
        setLeft(clientX);
        let clientY = bound.bottom;
        let bodyH = document.body.clientHeight;
        let height = 18;
        if (clientY + height > bodyH) {
            clientY = bound.top - height - 6;
        } else {
            clientY += 6
        }
        setTop(clientY);
        setVisible(true)
    }

    const onInputChange = (e, index) => {
        let value = e.target.value
        // const check = /^\d*\.?\d*$/
        const check = /^-?\d*\.?\d*$/
        if (check.test(value)) {
            if (index == 1) {
                setTableItem1Value1(value)
            } else if (index == 2) {
                setTableItem1Value2(value)
            } else if (index == 3) {
                setTableItem2Value1(value)
            } else if (index == 4) {
                setTableItem2Value2(value)
            } else if (index == 5) {
                setTableItem3Value1(value)
            } else if (index == 6) {
                setTableItem3Value2(value)
            }
        }
    }

    const onSelectOption = (value: number) => {
        if (selectInputIndex == 1) {
            setTableItem1Value1(value.toString())
        } else if (selectInputIndex == 2) {
            setTableItem2Value1(value.toString())
        } else {
            setTableItem3Value1(value.toString())
        }
        setVisible(false)
    }

    const onClickSaveBtn = () => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;

        // 获取colunm
        let column: ColumnT = entities.find(item => item.type == "ColumnT")
        if (column) {
            let xMove: number = +tableItem1Value2 || 0
            let yMove: number = +tableItem2Value2 || 0

            // let myCloumn = column.clone()
            let myCloumn = column.clone()
            myCloumn.move(new Vector3(1, 0, 0), -xMove)
            myCloumn.move(new Vector3(0, 1, 0), -yMove)

            let layerUuid: string = graphicStore.context.layerContext.layers.find(item => item.type === LayerType.COLUMN)?.uuid
            if (layerUuid?.length <= 0) {
                layerUuid = genUUID();
                let newLayer: Layer = {
                    id: layerUuid,
                    uuid: layerUuid,
                    name: '柱',
                    color: '#2b2b2b',
                    lineWidth: 0.25,
                    lineType: LineType.BASE_LINE,
                    originLayer: false,
                    projectUuid: projectId,
                    type: LayerType.COLUMN,
                }
                new AddLayerCommand(newLayer).execute();
            }

            //如果当前控制器已被激活，则结束当前二级控制器，再重新生成二级控制器
            if (graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl) {
                graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
            }
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("ADDCOLUMN");
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal("initColunmControl").dispatch(myCloumn, xMove, yMove, layerUuid);

        }
    }

    const onClickCancel = () => {
        setTableItem1Value1("350")
        setTableItem1Value2("")
        setTableItem2Value1("350")
        setTableItem2Value2("")
        setTableItem3Value1("2700")
        setTableItem3Value2("")
        setSectionType("1")
        setVisible(false)
        setSelectInputIndex(null)
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    柱
                </div>
                <div className="column-body-detail">
                    <div className="select-type">
                        <div className="type-name">
                            截面：
                        </div>
                        <Select
                            className="type-select"
                            value={sectionType == "1" ? "矩形" : "圆形"}
                            onChange={(e) => { setSectionType(e) }}
                            size='small' optionLabelProp='label'
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            <Option value={1} key={"1"}>
                                矩形
                            </Option>
                            <Option value={2} key={"2"}>
                                圆形
                            </Option>
                        </Select>
                    </div>
                    <div className="panel-title">
                        {(sectionType == "1" ? "矩形" : "圆形") + "柱设置面板"}
                    </div>
                    <div className="panel-main">
                        <div className="show-scene">
                            <LocalScene
                                entities={entities}
                                color="#000"
                                react_id={"column-panel-scene"}
                                width={88}
                                height={88}
                                top={-0.01}
                                left={0.1}
                            />
                        </div>
                        <div className="edit-table">
                            <div className="table-title">
                                <div className="size">
                                    柱子尺寸
                                </div>
                                <div className="angle">
                                    偏心转角
                                </div>
                            </div>
                            <div className="table-item">
                                <div className="name-1">
                                    {sectionType == "1" ? "横向：" : "直径："}
                                </div>
                                <Input
                                    value={tableItem1Value1}
                                    ref={ref_1}
                                    className="input-1"
                                    suffix={(<ShowSvg onClick={() => { showDefaultOptions(1) }} />)}
                                    onClick={() => { showDefaultOptions(1) }}
                                    onChange={(e) => { onInputChange(e, 1) }}
                                />
                                <div className="name-2">
                                    横轴：
                                </div>
                                <Input
                                    value={tableItem1Value2}
                                    className="input-2"
                                    onChange={(e) => { onInputChange(e, 2) }}
                                />
                            </div>
                            <div className="table-item">
                                {sectionType == "1" && (
                                    <>
                                        <div className="name-1">
                                            纵向：
                                        </div>
                                        <Input
                                            value={tableItem2Value1}
                                            ref={ref_2}
                                            className="input-1"
                                            suffix={(<ShowSvg onClick={() => { showDefaultOptions(2) }} />)}
                                            onChange={(e) => { onInputChange(e, 3) }}
                                            onClick={() => { showDefaultOptions(2) }}
                                        />
                                    </>
                                )}
                                <div className="name-2">
                                    纵轴：
                                </div>
                                <Input
                                    value={tableItem2Value2}
                                    className="input-2"
                                    onChange={(e) => { onInputChange(e, 4) }}
                                />
                            </div>
                            <div className="table-item">
                                <div className="name-1">
                                    柱高：
                                </div>
                                <Input
                                    value={tableItem3Value1}
                                    ref={ref_3}
                                    className="input-1"
                                    suffix={(<ShowSvg onClick={() => { showDefaultOptions(3) }} />)}
                                    onChange={(e) => { onInputChange(e, 5) }}
                                    onClick={() => { showDefaultOptions(3) }}
                                />
                                <div className="name-2">
                                    转角：
                                </div>
                                <Input
                                    value={tableItem3Value2}
                                    className="input-2"
                                    onChange={(e) => { onInputChange(e, 6) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="btn-list">
                        <div className="save" onClick={() => { onClickSaveBtn() }}>
                            确定
                        </div>
                        <div className="cancel" onClick={() => { onClickCancel() }}>
                            取消
                        </div>
                    </div>
                </div>
            </div>
            <ContextMenuFC
                visible={visible}
                left={left}
                top={top}
                width={51}
                wrapperClassname="column-input-default-option"
                onCancel={() => { setVisible(false) }}
            >
                {selectInputIndex != 3 ? (
                    <>
                        {defauleData1.map((item, index) => (
                            <div key={index} className="option-item" onClick={() => { onSelectOption(item) }}>
                                {item}
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        {defauleData2.map((item, index) => (
                            <div key={index} className="option-item" onClick={() => { onSelectOption(item) }}>
                                {item}
                            </div>
                        ))}
                    </>
                )}

            </ContextMenuFC>
        </div>
    )
}