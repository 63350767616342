import { useEffect, useState } from 'react';
// import "../../commons/styles/side-bar.less"
import '../building/ground/index.less';
import SideBarTool from '@/commons/components/side-bar-tool';
import { ReactComponent as AssembleBlackSvg } from "@/commons/icons/panel-icon/assemble-black.svg";
import { graphicStore } from '@/commons/store/graphic-store';
import { Upload, message } from 'antd';
import { BIM_BASE_EXTRA_SINGAL_COMMON, BIM_BASE_EXTRA_SUB_CONTROL_COMMON, Component2DT } from 'tnbimbase';
import { AddLayerCommand, LineType } from 'pythagoras';
import { useParams } from 'react-router-dom';
import genUUID from '@/commons/utils/gen-uuid';
import { Layer } from '@/commons/interface/layer';
import { LayerType } from '@/commons/enums/layer-type';
import { TextureUploadUrl, getTextureUrl, getTextures } from '@/api/spec-common/texture';
import { CustomSelect } from '@/commons/components/select-with-picture';
import { TEXTURE_CATEGORY, getTypeName } from '@/commons/enums/texture';
import { ReactComponent as UploadSvg } from '@/commons/icons/side/groud-panel/upload.svg'
import { Texture } from '@/commons/interface/texture';
import { onActiveSubControl } from '@/commons/utils/graphic-method';

export default function TreePanel() {
    const { projectId } = useParams();
    const [selectedMatUuid, setSelectedMatUuid] = useState('');
    const [data, setData] = useState<{ [key: string]: { name: string, value: any, url: string }[] }>();
    const [uploading, setUploading] = useState<boolean>(false);


    useEffect(() => {
        // 获取乔灌木组件
        generateData(true);
    }, [])

    const onInsertComponent = () => {
        if (!selectedMatUuid) {
            // 组件未选，无法布置
            message.warning("未选择乔灌木");
            return;
        }

        if (!graphicStore.context.layerContext.layers.some(item => item.type === LayerType.TREES)) {
            // 没有乔灌木图层，新增乔灌木图层
            let uuid = genUUID();
            let newLayer: Layer = {
                id: uuid,
                uuid: uuid,
                name: '乔灌木',
                color: '#2b2b2b',
                lineWidth: 0.25,
                lineType: LineType.BASE_LINE,
                originLayer: false,
                projectUuid: projectId,
                type: LayerType.TREES,

            }
            new AddLayerCommand(newLayer).execute();
        }
        onActiveSubControl(BIM_BASE_EXTRA_SUB_CONTROL_COMMON.INSERTCOMPONENT2D.name);
        let treeLayerUuid = graphicStore.context.layerContext.layers?.find(item => item.type === LayerType.TREES)?.uuid;
        if (!treeLayerUuid) {
            treeLayerUuid = graphicStore.context.layerContext.currentLayerUuid;
        }
        let tree = new Component2DT();
        tree.setMaterial(selectedMatUuid).then(bool => {
            tree.layerUuid = treeLayerUuid;
            tree.isColorByLayer = true;
            graphicStore.extraContext.listeners.getSignal(BIM_BASE_EXTRA_SINGAL_COMMON.onContinuouslyInsertComponent2D).dispatch(tree);
        })
    }

    const generateData = (needUpdateMap = false) => {
        getTextures(projectId, { category: TEXTURE_CATEGORY.tree.key }).then(res => {
            if (res.status === 200) {
                let resData = res.data as Texture[];
                if (needUpdateMap) {
                    resData.forEach(texture => {
                        if (texture && texture.uuid && texture.url) {
                            graphicStore.extraContext.materialContext.materialUrlMap[texture.uuid] = getTextureUrl(texture.url);
                        }
                    })
                }
                let tmpData: { [key: string]: { name: string, value: any, url: string }[] } = {};
                resData.forEach(texture => {
                    const typeName = getTypeName(texture.category, texture.type);
                    if (tmpData[typeName]) {
                        tmpData[typeName].push({
                            name: texture.textureName,
                            value: texture.uuid,
                            url: getTextureUrl(texture.url)
                        })
                    } else {
                        tmpData[typeName] = [
                            {
                                name: texture.textureName,
                                value: texture.uuid,
                                url: getTextureUrl(texture.url)
                            }];
                    }
                });
                setData(tmpData);
            }
        });
    }

    const beforeUpload = (file) => {
        if (uploading) {
            return false;
        }
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        let isImage = file && acceptedImageTypes.includes(file.type);
        if (!isImage) {
            message.warning("只能上传图片！")
            return false;
        }
        setUploading(true);
        return true;
    }

    const handleChange = (info) => {
        if (info.file.status !== 'uploading') {
            setUploading(false);
        }
        if (info.file.status === 'done') {
            message.success(`上传成功!`);
            generateData(true);
        }
        if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    乔灌木
                </div>
                <div className="detail">
                    <div className="row">
                        <div className="label">乔灌木：</div>
                        <CustomSelect style={{ 'marginTop': "-4px" }} data={data} selectedValue={selectedMatUuid} setSelectedValue={setSelectedMatUuid} />
                        <Upload
                            className='upload-button'
                            name='file'
                            data={{ 'projectUuid': projectId, 'category': TEXTURE_CATEGORY.tree.key, 'type': TEXTURE_CATEGORY.tree.type.normal.key }}
                            showUploadList={false}
                            action={TextureUploadUrl}
                            onChange={handleChange}
                            beforeUpload={beforeUpload}>
                            <UploadSvg />
                        </Upload>
                    </div>
                    <div className="ope-btn">
                        <div className="insert-button" onClick={onInsertComponent}>
                            <div className="icon"><AssembleBlackSvg className="icon-svg" /></div>
                            <div className="text">布置</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}