import { NumFixed } from "@/commons/utils/num-fixed"
import { Tooltip } from "antd"
import { ReactComponent as OrthogonalSvg } from '@/commons/icons/system-switch/orthogonal.svg';
import { ReactComponent as OrthogonalWhiteSvg } from '@/commons/icons/system-switch/orthogonal-white.svg';
import { ReactComponent as PolarSvg } from '@/commons/icons/system-switch/polar.svg';
import { ReactComponent as PolarWhiteSvg } from '@/commons/icons/system-switch/polar-white.svg';
import { ReactComponent as DymodeSvg } from '@/commons/icons/system-switch/dymode.svg';
import { ReactComponent as DymodeWhiteSvg } from '@/commons/icons/system-switch/dymode-white.svg';
import { ReactComponent as CaptureSvg } from '@/commons/icons/system-switch/capture.svg';
import { ReactComponent as CaptureWhiteSvg } from '@/commons/icons/system-switch/capture-white.svg';
import { ReactComponent as ScreenSvg } from '@/commons/icons/system-switch/screen.svg';
import { ReactComponent as ScreenWhiteSvg } from '@/commons/icons/system-switch/screen-white.svg';

import { ReactComponent as UpArrowSvg } from '@/commons/icons/system-switch/up-arrow.svg';
import { ReactComponent as DownArrowSvg } from '@/commons/icons/system-switch/down-arrow.svg';
import { ReactComponent as UpArrowWhiteSvg } from '@/commons/icons/system-switch/up-arrow-white.svg';
import { ReactComponent as DownArrowWhiteSvg } from '@/commons/icons/system-switch/down-arrow-white.svg';
import Icon, { CheckOutlined, SettingOutlined } from '@ant-design/icons';
import './index.less'
import { graphicStore } from "@/commons/store/graphic-store";
import { SWITCH_TYPE, UpdatePolarAngleCommand, UpdateCaptureModeCommand, UpdateSwitchCommand, CommandGroup, captureMode, RenderMode, ChangeModelCommand } from "pythagoras";
import { useContext, useEffect, useState } from "react";
import { ContextMenuModal, DragModal } from "tncet-common";
import CaptureSetting from "./CaptureSetting";
import { SystemStatus } from "@/commons/context/system-store";
import { SystemContext } from "@/App";

export const commonlyUsedAngle = [
    90, 45, 30, 22.5, 18, 15, 10, 5
]

export default function SystemSwitch() {

    const [captureModalVisible, setCaptureModalVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [polarMenuVisible, setPolarMenuVisible] = useState(false);
    const [renderMenuVisible, setRenderMenuVisible] = useState(false);
    const [menuVisibleLeft, setMenuVisibleLeft] = useState(0);
    const [polarMenuVisibleLeft, setPolarMenuVisibleLeft] = useState(0);
    const [renderMenuVisibleLeft, setRenderMenuVisibleLeft] = useState(0);
    const [tabPane, setTabPane] = useState('capture');
    const [orthogonal, setOrthogonal] = useState(false);
    const [autoSnap, setAutoSnap] = useState(false);
    const [dymode, setDymode] = useState(true);
    const [screenActive, setScreenActive] = useState(false);
    const [capture, setCapture] = useState(true);
    const [captureModes, setCaptureModes] = useState<captureMode[]>([]);
    const systemContext = useContext<SystemStatus>(SystemContext);

    // 设置全屏状态的鼠标悬浮状态
    const [isScreenHovered, setIsScreenHovered] = useState(false);
    // 处理鼠标进入事件
    const handleMouseEnterScreen = () => {
        setIsScreenHovered(true);
    };
    // 处理鼠标离开事件
    const handleMouseLeaveScreen = () => {
        setIsScreenHovered(false);
    };

    // 设置对象捕捉状态的鼠标悬浮状态
    const [isCaptureHovered, setIsCaptureHovered] = useState(false);
    // 处理鼠标进入事件
    const handleMouseEnterCapture = () => {
        setIsCaptureHovered(true);
    };
    // 处理鼠标离开事件
    const handleMouseLeaveCapture = () => {
        setIsCaptureHovered(false);
    };

    // 设置动态输入状态的鼠标悬浮状态
    const [isDymodeHovered, setIsDymodeHovered] = useState(false);
    // 处理鼠标进入事件
    const handleMouseEnterDymode = () => {
        setIsDymodeHovered(true);
    };
    // 处理鼠标离开事件
    const handleMouseLeaveDymode = () => {
        setIsDymodeHovered(false);
    };

    // 设置极轴追踪状态的鼠标悬浮状态
    const [isPolarHovered, setIsPolarHovered] = useState(false);
    // 处理鼠标进入事件
    const handleMouseEnterPolar = () => {
        setIsPolarHovered(true);
    };
    // 处理鼠标离开事件
    const handleMouseLeavePolar = () => {
        setIsPolarHovered(false);
    };

    // 设置正交状态的鼠标悬浮状态
    const [isOrthogonalHovered, setIsOrthogonalHovered] = useState(false);
    // 处理鼠标进入事件
    const handleMouseEnterOrthogonal = () => {
        setIsOrthogonalHovered(true);
    };
    // 处理鼠标离开事件
    const handleMouseLeaveOrthogonal = () => {
        setIsOrthogonalHovered(false);
    };


    const [ucsX, setUcsX] = useState<number>(0);
    const [ucsY, setUcsY] = useState<number>(0);
    const [ucsZ, setUcsZ] = useState<number>(0);
    document.addEventListener('keydown', handleKeyDown);
    function handleKeyDown(event) {
        if (event.key === 'F8' || event.key === 'F10' || event.key === 'F3') {
            setTimeout(() => {
                getActiveStatus();
            }, 100)
        }
    }
    useEffect(() => {
        getActiveStatus();
        setCaptureModes(graphicStore.extraContext.captureContext.captureModes);
    }, [])



    useEffect(() => {
        const root = document.getElementById('viewport');
        root.addEventListener('pointermove', onPointerMove);
        return (() => {
            root.removeEventListener('pointermove', onPointerMove);
        })
    }, [])

    const onPointerMove = (event) => {
        setUcsX(graphicStore.extraContext.mousePosContext.ucs_x)
        setUcsY(graphicStore.extraContext.mousePosContext.ucs_y)
        setUcsZ(graphicStore.extraContext.mousePosContext.ucs_z)
    }

    const onCaptureMenuItemClick = (number, active) => {
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        editor.history.execute(new UpdateCaptureModeCommand([{ number: number, active: active }]));
        setTimeout(() => {
            setCaptureModes(graphicStore.extraContext.captureContext.captureModes);
        }, 100)
    }


    const onPolarMenuItemClick = (angle) => {
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        editor.history.execute(new UpdatePolarAngleCommand(angle));
        setPolarMenuVisible(false);
    }

    const toggleSwitch = (type) => {
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        if (type === SWITCH_TYPE.autoSnap) {
            if (graphicStore.extraContext.editStatusContext.orthogonal && !graphicStore.extraContext.editStatusContext.autoSnap) {
                let switchPolarCmd = new UpdateSwitchCommand(type);
                let switchOrthogonalCmd = new UpdateSwitchCommand(SWITCH_TYPE.orthogonal);
                editor.history.execute(new CommandGroup([switchPolarCmd, switchOrthogonalCmd]));
                return;
            }
        } else if (type === SWITCH_TYPE.orthogonal) {
            if (graphicStore.extraContext.editStatusContext.autoSnap && !graphicStore.extraContext.editStatusContext.orthogonal) {
                let switchPolarCmd = new UpdateSwitchCommand(SWITCH_TYPE.autoSnap);
                let switchOrthogonalCmd = new UpdateSwitchCommand(type);
                editor.history.execute(new CommandGroup([switchPolarCmd, switchOrthogonalCmd]));
                return;
            }
        }
        editor.history.execute(new UpdateSwitchCommand(type));
        setTimeout(() => {
            getActiveStatus();
        }, 100)
    }

    const getActiveStatus = () => {
        setOrthogonal(graphicStore.extraContext.editStatusContext.orthogonal);
        setAutoSnap(graphicStore.extraContext.editStatusContext.autoSnap);
        setDymode(graphicStore.extraContext.editStatusContext.dymode);
        setScreenActive(graphicStore.extraContext.editStatusContext.screenActive);
        setCapture(graphicStore.extraContext.editStatusContext.capture);
    }

    const renderModeClick = (mode: number) => {
        setRenderMenuVisible(false);
        const editor = graphicStore.extraContext.getCurrentViewEditor();
        if (!editor) {
            return;
        }
        let prev = graphicStore.context.renderContext.renderType;
        if (prev !== mode) {
            editor.history.execute(new ChangeModelCommand(editor, mode));
        }
    }

    useEffect(() => {
        const editor = graphicStore.extraContext.getCurrentViewEditor();
        if (!editor) {
            return () => { };
        }
        editor.listeners.signals.onRenderTypeChange.add(renderTypeChange);

        return () => {
            if (editor == null) return;
            editor.listeners.signals.onRenderTypeChange.remove(renderTypeChange);
        }
    }, [graphicStore.extraContext?.getCurrentViewEditor()])

    const renderTypeChange = (renderType: number) => {
        systemContext.setCurrentRenderMode(renderType);
    }

    const CaptureMenu = (
        <div className="capture-menu-container">
            {captureModes.map((item, idx) => (
                <div className="capture-menu-item" key={item.number} onClick={() => onCaptureMenuItemClick(item.number, !item.active)}>
                    <div className="check-icon">
                        {item.active && (<CheckOutlined style={{ color: 'white' }} />)}
                    </div>
                    <div>
                        <Icon style={{ margin: '5px', fontSize: '16px' }} />
                    </div>
                    <div>
                        {item.name}
                    </div>
                </div>
            ))}
            <div className="menu-divider"></div>
            <div className="capture-menu-item setting" onClick={() => {
                setMenuVisible(false);
                setTabPane('capture');
                setTimeout(() => { graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('OSNAP') }, 200)
            }}>
                <div className="check-icon">
                    {/* <Icon component={SettingSvg} style={{ marginRight: '4px' }} /> */}
                </div>
                <span onClick={() => setCaptureModalVisible(true)}>对象捕捉设置...</span>
            </div>
        </div>
    )
    const PolarMenu = (
        <div className="polar-menu-container">
            {commonlyUsedAngle.map((item) => (
                <div className="polar-menu-item" key={item} onClick={() => onPolarMenuItemClick(item)}>
                    <div className="check-icon">
                        {item === graphicStore.extraContext.editStatusContext.polarAng && (<CheckOutlined style={{ color: 'white' }} />)}
                    </div>
                    <div>
                        {item.toFixed(0)}
                    </div>
                    <div className="multiply">
                        {', ' + (2 * item).toFixed(0) + ', ' + (3 * item).toFixed(0) + ', ' + (4 * item).toFixed(0) + '...'}
                    </div>
                </div>
            ))}
            <div className="menu-divider"></div>
            <div className="polar-menu-item setting" onClick={() => {
                setPolarMenuVisible(false);
                setTabPane('polar');
                setTimeout(() => { graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('OSNAP') }, 200)
            }}>
                <div className="check-icon"></div>
                <span onClick={() => setCaptureModalVisible(true)}>极轴追踪设置...</span>
            </div>
        </div>
    )
    const RenderMenu = (
        <div className="render-menu-container">
            {[RenderMode.WIREFRAME, RenderMode.CONCEPT].map((item) => (
                <div className="render-menu-item" key={item} onClick={() => renderModeClick(item)}>
                    <div className="check-icon">
                        {item === graphicStore.context.renderContext.renderType && (<CheckOutlined style={{ color: 'white' }} />)}
                    </div>
                    <div>
                        {item === RenderMode.WIREFRAME && `线框模型`}
                        {item === RenderMode.CONCEPT && `概念模型`}
                    </div>
                </div>
            ))}
        </div>
    )
    return (
        <div className="system-switch">
            <div className="coord">
                <span
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: 'auto',
                        cursor: 'pointer',
                        height: 26,
                    }}
                    onClick={(e) => {
                        setRenderMenuVisible(true);
                        setRenderMenuVisibleLeft(e.clientX + 40);
                    }}
                >
                    {systemContext.currentRenderMode === RenderMode.WIREFRAME && `线框模型`}
                    {systemContext.currentRenderMode === RenderMode.CONCEPT && `概念模型`}
                    {!renderMenuVisible && <DownArrowSvg style={{ marginLeft: 3 }} />}
                    {renderMenuVisible && <UpArrowSvg style={{ marginLeft: 3 }} />}
                </span>

                <Tooltip title="图形坐标" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <span>X={NumFixed(ucsX, 4).toFixed(4)}, </span>
                    <span>Y={NumFixed(ucsY, 4).toFixed(4)}, </span>
                    <span>Z={NumFixed(ucsZ, 4).toFixed(4)}</span>
                </Tooltip>
            </div>
            <div className="switch-group">
                <Tooltip title="正交(F8)" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    {orthogonal &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isOrthogonalHovered ? "#ABC3E8" : "#0061F3" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.orthogonal) }}
                                onMouseEnter={handleMouseEnterOrthogonal}
                                onMouseLeave={handleMouseLeaveOrthogonal}
                            >
                                {isOrthogonalHovered ? <OrthogonalSvg /> : <OrthogonalWhiteSvg />}
                            </div>
                        </div>
                    }
                    {!orthogonal &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isOrthogonalHovered ? "#ABC3E8" : "#FFFFFF" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.orthogonal) }}
                                onMouseEnter={handleMouseEnterOrthogonal}
                                onMouseLeave={handleMouseLeaveOrthogonal}
                            >
                                <OrthogonalSvg />
                            </div>
                        </div>
                    }
                </Tooltip>


                {/*  极轴追踪 */}
                <Tooltip title="极轴追踪(F10)" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    {autoSnap &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isPolarHovered ? "#ABC3E8" : "#0061F3" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.autoSnap) }}
                                onMouseEnter={handleMouseEnterPolar}
                                onMouseLeave={handleMouseLeavePolar}
                            >
                                {isPolarHovered ? <PolarSvg /> : <PolarWhiteSvg />}
                            </div>
                            <div className="system-triangle" onClick={(e) => {
                                setPolarMenuVisibleLeft(e.clientX); setPolarMenuVisible(true);
                            }}
                                onMouseEnter={handleMouseEnterPolar}
                                onMouseLeave={handleMouseLeavePolar}
                            >
                                {isPolarHovered && polarMenuVisible && <UpArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {!isPolarHovered && polarMenuVisible && <UpArrowWhiteSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {isPolarHovered && !polarMenuVisible && <DownArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {!isPolarHovered && !polarMenuVisible && <DownArrowWhiteSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                            </div>
                        </div>
                    }
                    {!autoSnap &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isPolarHovered ? "#ABC3E8" : "#FFFFFF" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.autoSnap) }}
                                onMouseEnter={handleMouseEnterPolar}
                                onMouseLeave={handleMouseLeavePolar}
                            >
                                <PolarSvg />
                            </div>
                            <div className="system-triangle" onClick={(e) => {
                                setPolarMenuVisibleLeft(e.clientX); setPolarMenuVisible(true);
                            }}
                                onMouseEnter={handleMouseEnterPolar}
                                onMouseLeave={handleMouseLeavePolar}
                            >
                                {polarMenuVisible && <UpArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {!polarMenuVisible && <DownArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                            </div>
                        </div>
                    }
                </Tooltip>


                {/*  动态输入 */}
                <Tooltip title="动态输入" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    {dymode &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isDymodeHovered ? "#ABC3E8" : "#0061F3" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.dymode) }}
                                onMouseEnter={handleMouseEnterDymode}
                                onMouseLeave={handleMouseLeaveDymode}
                            >
                                {isDymodeHovered ? <DymodeSvg /> : <DymodeWhiteSvg />}

                            </div>
                        </div>
                    }
                    {!dymode &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isDymodeHovered ? "#ABC3E8" : "#FFFFFF" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.dymode) }}
                                onMouseEnter={handleMouseEnterDymode}
                                onMouseLeave={handleMouseLeaveDymode}
                            >
                                <DymodeSvg />
                            </div>
                        </div>
                    }
                </Tooltip>

                {/*  对象捕捉 */}
                <Tooltip title="对象捕捉(F3)" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    {capture &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isCaptureHovered ? "#ABC3E8" : "#0061F3" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.capture) }}
                                onMouseEnter={handleMouseEnterCapture}
                                onMouseLeave={handleMouseLeaveCapture}
                            >
                                {isCaptureHovered ? <CaptureSvg /> : <CaptureWhiteSvg />}
                            </div>
                            <div className="system-triangle" onClick={(e) => {
                                setMenuVisibleLeft(e.clientX); setMenuVisible(true)
                            }}
                                onMouseEnter={handleMouseEnterCapture}
                                onMouseLeave={handleMouseLeaveCapture}
                            >
                                {isCaptureHovered && menuVisible && <UpArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {!isCaptureHovered && menuVisible && <UpArrowWhiteSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {isCaptureHovered && !menuVisible && <DownArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {!isCaptureHovered && !menuVisible && <DownArrowWhiteSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                            </div>
                        </div>
                    }
                    {!capture &&
                        <div style={{ height: "26px", display: 'flex', alignItems: 'center', backgroundColor: isCaptureHovered ? "#ABC3E8" : "#FFFFFF" }}>
                            <div
                                className="system-ope"
                                onClick={() => { toggleSwitch(SWITCH_TYPE.capture) }}
                                onMouseEnter={handleMouseEnterCapture}
                                onMouseLeave={handleMouseLeaveCapture}
                            >
                                <CaptureSvg />
                            </div>
                            <div className="system-triangle" onClick={(e) => {
                                setMenuVisibleLeft(e.clientX); setMenuVisible(true)
                            }}
                                onMouseEnter={handleMouseEnterCapture}
                                onMouseLeave={handleMouseLeaveCapture}
                            >
                                {menuVisible && <UpArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                                {!menuVisible && <DownArrowSvg style={{ paddingTop: '0px', display: 'block', cursor: 'pointer' }} />}
                            </div>
                        </div>
                    }
                </Tooltip>


            </div>

            {/*  全屏 */}
            {!screenActive &&
                <div className="switch-group" style={{ backgroundColor: isScreenHovered ? "#ABC3E8" : "#FFFFFF" }} >
                    <Tooltip title="全屏" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                        <div
                            className="system-ope"
                            style={{ width: "18px" }}
                            onClick={() => { toggleSwitch(SWITCH_TYPE.screenActive) }}
                            onMouseEnter={handleMouseEnterScreen}
                            onMouseLeave={handleMouseLeaveScreen}
                        >
                            <ScreenSvg />
                        </div>
                    </Tooltip>
                </div>
            }
            {screenActive &&
                <div className="switch-group" style={{ backgroundColor: isScreenHovered ? "#ABC3E8" : "#0061F3" }} >
                    <Tooltip title="全屏" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                        <div
                            className="system-ope"
                            style={{ width: "18px" }}
                            onClick={() => { toggleSwitch(SWITCH_TYPE.screenActive) }}
                            onMouseEnter={handleMouseEnterScreen}
                            onMouseLeave={handleMouseLeaveScreen}
                        >
                            {isScreenHovered ? <ScreenSvg /> : <ScreenWhiteSvg />}
                        </div>
                    </Tooltip>
                </div>
            }

            <div className="switch-group" style={{ backgroundColor: '#FFFFFF' }} >
                <Tooltip title="全局设置" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <div
                        className="system-ope"
                        style={{ width: "18px" }}
                    // onMouseEnter={handleMouseEnterScreen}
                    // onMouseLeave={handleMouseLeaveScreen}
                    >
                        <SettingOutlined />
                    </div>
                </Tooltip>
            </div>


            <ContextMenuModal
                visible={menuVisible}
                bottom={28}
                left={menuVisibleLeft - 120}
                mask
                onCancel={() => setMenuVisible(false)}
                onRightClick={() => setMenuVisible(false)}
                onEscape={() => {
                    setMenuVisible(false);
                    graphicStore.extraContext.listeners.signals.onProcessEscape.dispatch();
                }}
            >
                {CaptureMenu}
            </ContextMenuModal>
            <ContextMenuModal
                visible={polarMenuVisible}
                bottom={28}
                left={polarMenuVisibleLeft - 120}
                mask
                onCancel={() => setPolarMenuVisible(false)}
                onRightClick={() => setPolarMenuVisible(false)}
                onEscape={() => {
                    setPolarMenuVisible(false);
                    graphicStore.extraContext.listeners.signals.onProcessEscape.dispatch();
                }}
            >
                {PolarMenu}
            </ContextMenuModal>
            <ContextMenuModal
                visible={renderMenuVisible}
                bottom={28}
                left={renderMenuVisibleLeft - 100}
                mask
                onCancel={() => setRenderMenuVisible(false)}
                onRightClick={() => setRenderMenuVisible(false)}
                onEscape={() => {
                    setRenderMenuVisible(false);
                    graphicStore.extraContext.listeners.signals.onProcessEscape.dispatch();
                }}
            >
                {RenderMenu}
            </ContextMenuModal>
            <DragModal
                visible={captureModalVisible}
                destroyOnClose
                centered={false}
                onClose={() => setCaptureModalVisible(false)}
                title='草图设置'
                width={450}>
                <CaptureSetting
                    selectedTabPane={tabPane}
                    onCancel={() => { setCaptureModalVisible(false); getActiveStatus() }}
                />
            </DragModal>

            {/* <DragModal
                visible={globalSettingVisible}
                destroyOnClose
                centered={false}
                onClose={() => setGlobalSettingVisible(false)}
                title='全局设置'
                width={450}>
                <GlobalSetting
                    selectedTabPane={tabPane}
                    onCancel={() => { setGlobalSettingVisible(false) }}
                />
            </DragModal> */}
        </div >
    )
}