import './system-management-area.less'
import { ReactComponent as BIMSvg } from '@/commons/icons/top/bim.svg';
import { ReactComponent as BlackDownSvg } from '@/commons/icons/top/black-arrow.svg';
import { ReactComponent as WhiteDownSvg } from '@/commons/icons/top/white-arrow.svg';

export default function SystemManagementArea() {
    return (
        <div className="sys-man-top-nav-container">
            <div className='top-logo'>
                <BIMSvg onClick={() => { }} />
                <BlackDownSvg className='arrow-icon' />
            </div>
            <div className='top-ope'>
                <div className='ope-text'>项目</div>
                <WhiteDownSvg className='ope-icon' />
            </div>
        </div >
    )
}