import React from 'react';
import './App.css';
import { ConfigProvider } from 'antd';
import { UploadFileState, DownloadState, useUploadService, useDownloadService, UploadPanel, DownloadPanel } from 'tncet-common';
import { SideStatus, useSideService } from './commons/context/side-store';
import { SystemStatus, useSystemService } from './commons/context/system-store';
import { Routes, Route, Navigate } from 'react-router-dom';
import { checkFileExists, getFileUploadUrl } from './api/geometry/file';
import zhCN from 'antd/lib/locale/zh_CN'
import ProjectLayout from './layout/project';
import { StoreyStatus, useStoreyService } from './commons/context/storey-store';
import ProjectMain from './project-main';

export const SystemContext = React.createContext<SystemStatus>(null);
export const UploadFileContext = React.createContext<UploadFileState>(null);
export const DownloadContext = React.createContext<DownloadState>(null);
export const StoreyContext = React.createContext<StoreyStatus>(null);
export const SideContext = React.createContext<SideStatus>(null);

function App() {

    const systemContext = useSystemService();

    return (
        <ConfigProvider
            locale={zhCN}
            theme={{
                components: {
                    Radio: {
                        dotSize: 10,
                        radioSize: 14,
                        buttonPaddingInline: 8,
                        wrapperMarginInlineEnd: 4,
                    },
                },
            }}
        >
            <SystemContext.Provider value={systemContext}>
                <UploadFileContext.Provider value={useUploadService()}>
                    <DownloadContext.Provider value={useDownloadService()}>
                        <StoreyContext.Provider value={useStoreyService()}>
                            <SideContext.Provider value={useSideService()}>
                                {/* <ProjectMain /> */}
                                <div className="tn-base">
                                    <Routes>
                                        <Route path='/project' element={<ProjectMain />} />
                                        <Route path='/project/:projectId' element={<ProjectLayout />} />
                                        <Route path="*" element={<Navigate to="/project" />} />
                                    </Routes>
                                    <div className='upload-download-panel'>
                                        <UploadPanel checkFileExists={checkFileExists} getUploadUrl={getFileUploadUrl} uploadContext={UploadFileContext} />
                                        <DownloadPanel downloadContext={DownloadContext} />
                                    </div>
                                </div>
                            </SideContext.Provider>
                        </StoreyContext.Provider>
                    </DownloadContext.Provider>
                </UploadFileContext.Provider>
            </SystemContext.Provider>
        </ConfigProvider>
    );
}

export default App;