import { ModelBase } from "pythagoras";
import { graphicStore } from "../store/graphic-store";
import { Vector3 } from "three";

function computeEntityBoundingBox(entities: ModelBase[]){
    let minX = + Infinity;
    let minY = + Infinity;
    let minZ = + Infinity;

    let maxX = - Infinity;
    let maxY = - Infinity;
    let maxZ = - Infinity;
    let counter = 0;
    entities.forEach(entity => {
        if (entity == null) return;
        let points = entity.getPoints();
        points.forEach(point => {
            if (point.x < minX) minX = point.x;
            if (point.y < minY) minY = point.y;
            if (point.z < minZ) minZ = point.z;

            if (point.x > maxX) maxX = point.x;
            if (point.y > maxY) maxY = point.y;
            if (point.z > maxZ) maxZ = point.z;
            counter++;
        })
    })



    if (entities.length === 0 || counter === 0) {
        return {
            min: new Vector3(-100, -100, 0),
            max: new Vector3(100, 100, 0)
        }
    }
    // TODO: points为空
    if (Math.abs(maxX - minX) < 200) {
        minX -= 100;
        maxX += 100;
    }
    if (Math.abs(maxY - minY) < 200) {
        minY -= 100;
        maxY += 100;
    }
    if (Math.abs(maxZ - minZ) < 200) {
        minZ -= 100;
        maxZ += 100;
    }
    let res = {
        min: new Vector3(minX, minY, minZ),
        max: new Vector3(maxX, maxY, maxZ),
    }
    return res;
}

export function showEntities(entities: ModelBase[]) {
    try {
        let box = computeEntityBoundingBox(entities);
        const editor = graphicStore.extraContext.getCurrentViewEditor();
        let camera = editor.camera;
        box.min.project(camera);
        box.max.project(camera);
        editor.showEntitiesInRect(box.min.x, box.min.y, box.max.x, box.max.y, 1.3, true);
        editor.listeners.signals.needRender.dispatch();
    } catch (error) {
        console.warn(error);
    }
}