import { Select } from 'antd';
import { Component } from 'react';
import './LwSelect.less';
import React from 'react';
import { graphicStore } from '@/commons/store/graphic-store';
import { ModelBase } from 'pythagoras';

export const lineWidthList = [
    0.00,
    0.05,
    0.09,
    0.13,
    0.15,
    0.18,
    0.20,
    0.25,
    0.30,
    0.35,
    0.40,
    0.50,
    0.53,
    0.60,
    0.70,
    0.80,
    0.90,
    1.00,
    1.06,
    1.20,
    1.40,
    1.58,
    2.00,
    2.11,
]

const { Option } = Select;


interface IProps {
    layerStore?: any
}

export default class LwSelect extends Component<IProps> {
    ref = React.createRef<any>();
    state = {
        /// 当有东西选中时，用此变量显示选择器
        currentLineWidth: 0.25,
        isLineWidthByLayer: true,
        ///

        hasSelected: false,
        showProperty: true,

    }

    componentDidMount() {

        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onSelectChanged.add(this.onSelectChanged);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onLineWidthChanged.add(this.onSelectChanged);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onProcessEscape.add(this.onProcessEscape);
    }

    componentWillUnmount() {
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onSelectChanged.remove(this.onSelectChanged);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onLineWidthChanged.remove(this.onSelectChanged);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onProcessEscape.remove(this.onProcessEscape);
    }

    onSelectChanged = (entities: ModelBase[]) => {
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        let selects = editor.selectControl.getSelectedEntityList();
        if (selects.length === 0) {
            // 没有东西选中
            this.setState({
                currentLineWidth: this.props.layerStore.state.currentLayerLineWidth,
                hasSelected: false,
                showProperty: true,
                isLineWidthByLayer: this.props.layerStore.state.isLineWidthByLayer,
            })
            return;
        }
        let isLineWidthByLayer;
        for (let selected of selects) {
            if (isLineWidthByLayer == null) {
                isLineWidthByLayer = selected.isLineWidthByLayer;
            } else if (isLineWidthByLayer !== selected.isLineWidthByLayer) {
                isLineWidthByLayer = null;
                this.setState({
                    showProperty: false,
                    hasSelected: true,
                })
                return;
            }
        }
        let lineWidth;
        if (!!isLineWidthByLayer) {
            // 如果选中的对象颜色都是随层
            lineWidth = graphicStore.extraContext.getCurrentViewEditor().context.layerContext.isLineWidthByLayer;
        } else {
            for (let selected of selects) {
                if (lineWidth == null) {
                    lineWidth = selected.lineWidth;
                } else if (lineWidth !== selected.lineWidth) {
                    lineWidth = null;
                    this.setState({
                        showProperty: false,
                        hasSelected: true
                    })
                    return;
                }
            }
        }

        this.setState({
            currentLineWidth: lineWidth,
            isLineWidthByLayer: isLineWidthByLayer,
            hasSelected: true,
            showProperty: true,
        })
    }

    onProcessEscape = () => {
        this.setState({
            hasSelected: false,
            showProperty: true,
        })
    }

    onChange = (lineWidth) => {
        if (lineWidth === 'ByLayer') {
            let layerLineWidth = this.props.layerStore.state.currentLayerLineWidth;
            this.setState({
                showProperty: true,
                currentLineWidth: layerLineWidth,
                isLineWidthByLayer: true,
            })
            return;
        }
        this.setState({
            currentLineWidth: lineWidth,
            isLineWidthByLayer: false,
            showProperty: true,
        })
        this.ref.current?.blur();
    }

    render() {
        const { hasSelected, showProperty } = this.state;
        return (
            <div className="lw-select">
                <Select
                    style={{ width: '160px' }}
                    dropdownStyle={{ backgroundColor: '#757575' }}
                    size="small"
                    value={(!hasSelected) ? (this.props.layerStore.state.isLineWidthByLayer ? 'ByLayer' : this.props.layerStore.state.currentLineWidth) : (showProperty ? (this.state.isLineWidthByLayer ? 'ByLayer' : this.state.currentLineWidth) : null)}
                    onChange={this.onChange}
                    ref={this.ref}>
                    <Option value="ByLayer" key="ByLayer">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: this.props.layerStore.state.currentLayerLineWidth * 10 || 1 }}></div>
                            <div className="lw-select-name">ByLayer</div>
                        </div>
                    </Option>
                    {/* <Option value="ByBlock" key="ByBlock">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: this.props.layerStore.state.currentLayerLineWidth * 10 || 1 }}></div>
                            <div className="lw-select-name">ByBlock</div>
                        </div>
                    </Option> */}
                    {/* <Option value="Default" key="Default">
                        <div className="lw-select-option">
                            <div className="lw-select-box" style={{ height: 0.25 * 10 || 1 }}></div>
                            <div className="lw-select-name">默认</div>
                        </div>
                    </Option> */}
                    {lineWidthList.map((item, key) => (
                        <Option value={+item} key={key}>
                            <div className="lw-select-option">
                                <div className="lw-select-box" style={{ height: item * graphicStore.context.renderContext.lineWidthDisplayScale || 1 }}></div>
                                <div className="lw-select-name">{item.toFixed(2) + '毫米'}</div>
                            </div>
                        </Option>
                    ))}
                </Select>
            </div>
        )
    }
}