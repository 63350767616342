import { useEffect, useState } from "react";
import "./sub-dropdown-box-item.less";
import { ReactComponent as CheckSvg } from './icons/check.svg'
import { Input } from "antd";
import classNames from "classnames";
import { graphicStore } from "@/commons/store/graphic-store";

interface Iprops {
    name: string
    uuid: string
    selected: boolean
    setSelected: (uuid: string, selected: boolean) => void
    updateName: (uuid: string, name: string) => void
}


export function SubDropdownBoxItem(props: Iprops) {
    const [editing, setEditing] = useState<boolean>(false);
    const [name, setName] = useState<string>(props.name);

    const onDoubleClickExpand = () => {
        setEditing(!editing);
    }

    const onInputFinish = () => {
        setEditing(false);
        props.updateName(props.uuid, name);
    }

    useEffect(() => {
        setName(props.name);
    }, [props.name])


    /**
     * 切换列表选中状态后，需要反向修改图形区的选中状态
     */
    useEffect(() => {
        if (props.selected) {
            const editor = graphicStore.extraContext.getCurrentViewEditor();
            if (editor) {
                editor.entityDict[props.uuid].isSelected = true;
                editor.selectControl.getSelectedEntityList()[props.uuid] = editor.entityDict[props.uuid]
                editor.listeners.signals.needRender.dispatch('redraw');
            }
        } else {
            const editor = graphicStore.extraContext.getCurrentViewEditor();
            if (editor) {
                editor.entityDict[props.uuid].isSelected = false;
                delete editor.selectControl.getSelectedEntityList()[props.uuid];
                editor.listeners.signals.needRender.dispatch('redraw');
            }
        }
    }, [props.selected])

    const onClick = () => {
        props.setSelected(props.uuid, !props.selected);
    }



    return (
        <div className={classNames({
            "sub-dropdown-box-item": true,
            "selected": props.selected
        })}
            onClick={onClick}
        >
            {!editing &&
                <>
                    <div className="sub-dropdown-box-item-text" onDoubleClick={onDoubleClickExpand}>
                        <span>{name}</span>
                    </div>
                </>
            }
            {editing &&
                <>
                    <div className="sub-dropdown-box-item-input" onDoubleClick={onDoubleClickExpand}>
                        <span style={{ width: '90%', marginRight: '1px' }}>
                            <Input
                                height={20}
                                className="input"
                                placeholder="输入名称"
                                style={{
                                    paddingTop: '2px',
                                    height: '20px'
                                }}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </span>
                        <div className="right-svg" onClick={onInputFinish}>
                            <CheckSvg className="svg" />
                        </div>
                    </div>
                </>
            }
        </div>
    )

}