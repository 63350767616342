import { Input, Modal, message } from "antd";
import { ReactComponent as RoadTypeSvg } from "@/commons/icons/panel-icon/close-model.svg";
import { ReactComponent as AddSvg } from "@/commons/icons/panel-icon/model-add.svg";
import { ReactComponent as DeleteSvg } from "@/commons/icons/panel-icon/delete-black.svg";
import { ReactComponent as TableDeleteSvg } from "@/commons/icons/panel-icon/table-delete.svg";
import { ReactComponent as CopySvg } from "@/commons/icons/panel-icon/copy.svg";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { RoadLane, RoadType } from "@/commons/interface/building/road-type";
import { ROAD_LANE_TYPE, ROAD_TYPE, getRoadTypeName } from "@/commons/enums/road-type";
import { addRoadType, deleteByUuid, getRoadTypeDetailByUuid, updateRoadType } from "@/api/building/road-type";
import { ModelUtils } from "pythagoras";

interface Iprops {
    generateList: () => void
    setVisiable: (e) => void

    visiable: boolean
    className: string
    projectUuid: string

    roadTypeList: RoadType[]
}

export default function RoadTypeModel(prop: Iprops) {

    const [roadTypeList, setRoadTypeList] = useState<RoadType[]>([])

    const [selectTypeUuid, setSelectTypeUuid] = useState<string>("")

    const [leftRoadWidth, setLeftRoadWidth] = useState<string>("")
    const [rightRoadWidth, setRightRoadWidth] = useState<string>("")

    const [selectTypeDetail, setSelectTypeDetail] = useState<RoadType>(null)

    const [leftRoadLaneList, setLeftRoadLaneList] = useState<RoadLane[]>([])
    const [rightRoadLaneList, setRightRoadLaneList] = useState<RoadLane[]>([])

    useEffect(() => {
        setSelectTypeUuid(null)
    }, [prop.visiable])

    useEffect(() => {
        if (prop?.roadTypeList?.length > 0) {
            setRoadTypeList([...prop.roadTypeList])
        } else {
            setRoadTypeList([])
        }
    }, [prop.roadTypeList])

    useEffect(() => {
        if (selectTypeUuid?.length > 0) {
            getRoadTypeDetailByUuid(selectTypeUuid).then(res => {
                setSelectTypeDetail(res.data)
            })
        } else {
            setSelectTypeDetail(null)
        }

    }, [selectTypeUuid])

    useEffect(() => {
        if (selectTypeDetail == null) {
            setLeftRoadLaneList([])
            setRightRoadLaneList([])
            setLeftRoadWidth(null)
            setRightRoadWidth(null)
            return
        } else {
            setLeftRoadLaneList(selectTypeDetail.laneList.filter(item => item.type === ROAD_LANE_TYPE.LEFT) || [])
            setRightRoadLaneList(selectTypeDetail.laneList.filter(item => item.type === ROAD_LANE_TYPE.RIGHT) || [])
            setLeftRoadWidth(selectTypeDetail.leftSidewalk.toString())
            setRightRoadWidth(selectTypeDetail.rightSidewalk.toString())
        }
    }, [selectTypeDetail])

    const addRoadTypeClick = () => {
        // 初始单左右车道
        let leftRoadLane: RoadLane = {
            type: ROAD_LANE_TYPE.LEFT,
            width: 5
        }
        let rightRoadLane: RoadLane = {
            type: ROAD_LANE_TYPE.RIGHT,
            width: 5
        }
        // 初始化路型
        let myRoadType: RoadType = {
            type: ROAD_TYPE.URBAN_ROAD,
            leftSidewalk: 2,
            rightSidewalk: 2,
            laneList: [leftRoadLane, rightRoadLane]
        }

        addRoadType(prop.projectUuid, myRoadType).then(res => {
            prop.generateList()
            message.success("添加成功！")
        }).catch(e => {
            message.error("添加失败！")
            console.log("err:", e);
        })
    }

    const copyRoadType = (data: RoadType) => {
        getRoadTypeDetailByUuid(data.uuid).then(res => {
            let copyData = { ...data }
            copyData.uuid = null
            copyData.gmtCreate = null
            let laneList: RoadLane[] = res?.data?.laneList || []
            laneList.forEach(item => {
                item.uuid = null
                item.id = null
                item.gmtCreate = null
            })
            copyData.laneList = res?.data?.laneList || []
            addRoadType(prop.projectUuid, copyData).then(res => {
                prop.generateList()
                message.success("复制成功！")
            }).catch(e => {
                message.error("复制失败！")
                console.log("err:", e);
            })
        })


    }

    const setRoadTypeValue = (key: string, value: any) => {
        if (selectTypeDetail == null) return
        if (key == "type") {
            selectTypeDetail.type = value
            setSelectTypeDetail({ ...selectTypeDetail })
        }
    }

    const confirmUpdateRoadType = () => {
        if (selectTypeUuid?.length > 0) {
            let updateData = { ...selectTypeDetail }
            updateData.laneList = [...leftRoadLaneList, ...rightRoadLaneList]
            updateRoadType(selectTypeUuid, updateData).then(res => {
                prop.generateList()
                prop.setVisiable(false)
            }).catch(e => {
                message.error("更新失败！")
                console.log("errr:", e);

            })
        }
    }

    const addLane = (isLeft: boolean) => {
        if (selectTypeUuid?.length > 0) {
            if (isLeft) {
                let newLane: RoadLane = {
                    uuid: ModelUtils.generateUUID(),
                    roadUuid: selectTypeUuid,
                    width: 1,
                    type: ROAD_LANE_TYPE.LEFT
                }
                leftRoadLaneList.push(newLane)
                setLeftRoadLaneList([...leftRoadLaneList])
                return
            } else {
                let newLane: RoadLane = {
                    uuid: ModelUtils.generateUUID(),
                    roadUuid: selectTypeUuid,
                    width: 1,
                    type: ROAD_LANE_TYPE.RIGHT
                }
                rightRoadLaneList.push(newLane)
                setRightRoadLaneList([...rightRoadLaneList])
                return
            }

        }
    }

    function TableItem(data: { item: RoadLane, index: number, isLeft: boolean }) {

        const [roadLane, setRoadLane] = useState<RoadLane>(null)
        const [isLeft, setIsLeft] = useState<boolean>(false)

        useEffect(() => {
            if (data) {
                setRoadLane(data.item)
                setIsLeft(data.isLeft)
            }
        }, [data])

        return (
            <div className="list-item" key={data?.item?.uuid}>
                <div className="index">
                    {(data?.index + 1).toString().padStart(2, '0')}
                </div>
                <Input
                    value={roadLane?.width}
                    placeholder="输入路宽"
                    className="item-input" suffix={"m"}
                    onChange={(e) => {
                        let value = e.target.value
                        if (!(value?.length > 0)) {
                            value = "0"
                        }
                        const regex = /^\d*\.?\d*$/;
                        if (regex.test(value)) {
                            roadLane.width = parseFloat(value)
                            setRoadLane({ ...roadLane })
                        }
                    }}
                    onBlur={() => {
                        if (isLeft) {
                            let mid = leftRoadLaneList
                            let startIndex = mid.findIndex(item => item.uuid == roadLane?.uuid)
                            mid.splice(startIndex, 1, roadLane)
                            setLeftRoadLaneList([...mid])
                        } else {
                            let mid = rightRoadLaneList
                            let startIndex = mid.findIndex(item => item.uuid == roadLane?.uuid)
                            mid.splice(startIndex, 1, roadLane)
                            setRightRoadLaneList([...mid])
                        }
                    }}
                />
                <DeleteSvg className="delete-icon" onClick={() => {
                    if (isLeft) {
                        let mid = leftRoadLaneList
                        let startIndex = mid.findIndex(item => item.uuid == roadLane?.uuid)
                        mid.splice(startIndex, 1)
                        setLeftRoadLaneList([...mid])
                    } else {
                        let mid = rightRoadLaneList
                        let startIndex = mid.findIndex(item => item.uuid == roadLane?.uuid)
                        mid.splice(startIndex, 1)
                        setRightRoadLaneList([...mid])
                    }
                }} />
            </div>
        )
    }

    const onClickDeleteType = (uuid: string) => {
        deleteByUuid(uuid).then(res => {
            if (res.data) {
                message.success("删除成功")
            } else {
                message.warning("该路型已被使用")
            }

            if (selectTypeUuid == uuid) {
                setSelectTypeUuid(null)
            }
        }).finally(() => {
            prop.generateList()
        }).catch(e => {
            message.error("删除失败！")
        })
    }

    return (
        <>
            <Modal
                wrapClassName={prop.className}
                footer={null}
                mask
                destroyOnClose={true}
                closeIcon={false}
                onCancel={() => { prop.setVisiable(false) }}
                open={prop.visiable}
            >
                <div className="model-main">
                    <div className="title">
                        <div className="name">
                            路型库
                        </div>
                        <RoadTypeSvg onClick={() => { prop.setVisiable(false) }} className="icon" />
                    </div>
                    <div className="type-list">
                        <div className="list-title">
                            路型库
                            <AddSvg className="icon" onClick={() => { addRoadTypeClick() }} />
                        </div>
                        <div className="list-table">
                            <div className="table-title">
                                <div className="title-item">
                                    道路等级
                                </div>
                                <div className="title-item">
                                    总宽
                                </div>
                                <div className="title-item">
                                    车道数
                                </div>
                                <div className="title-item">
                                    编辑
                                </div>
                            </div>
                            <div className="table-body">
                                {roadTypeList?.map((item, index) => (
                                    <div
                                        className={classNames({
                                            "table-item": true,
                                            "table-item-active": selectTypeUuid == item.uuid
                                        })}
                                        key={index}
                                        onClick={() => { setSelectTypeUuid(item.uuid) }}
                                    >
                                        <div className="item">
                                            {getRoadTypeName(item.type)}
                                        </div>
                                        <div className="item">
                                            {(item?.totalWidth || 0) + "m"}
                                        </div>
                                        <div className="item">
                                            {item?.totalLaneSize || 0 + "车道"}
                                        </div>
                                        <div className="item">
                                            <CopySvg onClick={() => { copyRoadType(item) }} />
                                            <TableDeleteSvg onClick={(e) => { e.stopPropagation(); onClickDeleteType(item.uuid) }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="type-detail">
                        <div className="title">
                            路型编辑器
                        </div>
                        <div className="top">
                            <div className="name">
                                道路类型：
                            </div>
                            <div className="menu">
                                <div className={classNames({
                                    "item": true,
                                    "item-active": selectTypeDetail?.type == ROAD_TYPE.URBAN_ROAD
                                })}
                                    onClick={() => { setRoadTypeValue("type", ROAD_TYPE.URBAN_ROAD) }}
                                >
                                    市政
                                </div>
                                <div className={classNames({
                                    "item": true,
                                    "item-active": selectTypeDetail?.type == ROAD_TYPE.BASE_ROAD
                                })}
                                    onClick={() => { setRoadTypeValue("type", ROAD_TYPE.BASE_ROAD) }}
                                >
                                    基地
                                </div>
                            </div>
                            <div className="label">
                                左人行道：
                            </div>
                            <Input disabled={selectTypeDetail == null} className="input" suffix={"m"} value={leftRoadWidth}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (!(value?.length > 0)) {
                                        value = "0"
                                    }
                                    const regex = /^\d*\.?\d*$/;
                                    if (regex.test(value)) {
                                        setLeftRoadWidth(value)
                                    }
                                }} />
                            <div className="label">
                                右人行道：
                            </div>
                            <Input disabled={selectTypeDetail == null} className="input" suffix={"m"} value={rightRoadWidth}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (!(value?.length > 0)) {
                                        value = "0"
                                    }
                                    const regex = /^\d*\.?\d*$/;
                                    if (regex.test(value)) {
                                        setRightRoadWidth(value)
                                    }
                                }} />
                        </div>
                        <div className="detail">
                            <div className="detail-body">
                                <div className={classNames({
                                    "detail-main": true,
                                    "detail-main-right": false
                                })}>
                                    <div className="title">
                                        左车道
                                        <AddSvg className="icon" onClick={() => { addLane(true) }} />
                                    </div>
                                    {leftRoadLaneList?.map((item, index) => (
                                        <TableItem item={item} index={index} isLeft={true} />
                                    ))}

                                </div>
                                <div className={classNames({
                                    "detail-main": true,
                                    "detail-main-right": true
                                })}>
                                    <div className="title">
                                        右车道
                                        <AddSvg className="icon" onClick={() => { addLane(false) }} />
                                    </div>
                                    {rightRoadLaneList?.map((item, index) => (
                                        <TableItem item={item} index={index} isLeft={false} />
                                    ))}
                                </div>

                            </div>

                            <div className="detail-bottom">
                                <div className="save-btn" onClick={() => { confirmUpdateRoadType() }}>
                                    确定
                                </div>
                                <div className="cancel-btn" onClick={() => { prop.setVisiable(false) }}>
                                    取消
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </Modal>
        </>
    )
}