/**
 * 本文件存储了额外在外边注册的signal信号的名称
 */

export const EXTRA_SINGAL = {
    onAssembleFenceByLine: "onAssembleFenceByLine", // 通过选基准线布置围墙
    onAssembleFenceByPoint: "onAssembleFenceByPoint", // 通过指定角点布置围墙
    onAssembleHole: "onAssembleHole", // 布置洞口
    onAssembleWall: "onAssembleWall", // 布置墙
    onAssembleDoor: "onAssembleDoor", // 布置门
    onAssembleWindow: "onAssembleWindow", // 布置窗
}