import { SideContext } from '@/App';
import { SideStatus } from '@/commons/context/side-store';
import { SIDE_TYPE } from '@/commons/enums/side-type';
import { graphicStore } from '@/commons/store/graphic-store';
// import { SettingOutlined } from '@ant-design/icons';
import { useContext } from 'react';

import { ReactComponent as YanganqiSvg } from '@/commons/icons/new/yanganqi.svg';
import { ReactComponent as YanganqixianluSvg } from '@/commons/icons/new/yanganqixianlu.svg';
import { ReactComponent as PenlintouSvg } from '@/commons/icons/new/penlintou.svg';
import { ReactComponent as PenlintouxianluSvg } from '@/commons/icons/new/penlintouxianlu.svg';
import { ReactComponent as XiaohuoshuanSvg } from '@/commons/icons/new/xiaohuoshuan.svg';
import { ReactComponent as XiaohuoshuanshuiguanSvg } from '@/commons/icons/new/xiaohuoshuanshuiguan.svg';
import { ReactComponent as MiehuoqiSvg } from '@/commons/icons/new/miehuoqi.svg';



export default function FireMenu() {
    const sideContext = useContext<SideStatus>(SideContext);

    const onClick = (route: string) => {
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
        sideContext.setSelectedPanelValue(route);
    }

    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-row-area'>
                    <div className="ope-btn">
                        <div className="ope-name">场地</div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.smoke_detector) }}>
                                <div className='icon'><YanganqiSvg /></div>
                                <div className='name'>烟感器</div>
                            </div>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><YanganqixianluSvg /></div>
                                <div className='name'>烟感器线路</div>
                            </div>
                        </div>
                        <div style={{ width: 14 }}></div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.spray_head) }}>
                                <div className='icon'><PenlintouSvg /></div>
                                <div className='name'>喷淋头</div>
                            </div>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><PenlintouxianluSvg /></div>
                                <div className='name'>喷淋头线路</div>
                            </div>
                        </div>
                        <div style={{ width: 14 }}></div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.fire_hydrant) }}>
                                <div className='icon'><XiaohuoshuanSvg /></div>
                                <div className='name'>消火栓</div>
                            </div>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><XiaohuoshuanshuiguanSvg /></div>
                                <div className='name'>消火栓线路</div>
                            </div>
                        </div>
                        <div style={{ width: 14 }}></div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.fire_extinguisher) }}>
                                <div className='icon'><MiehuoqiSvg /></div>
                                <div className='name'>灭火器</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}