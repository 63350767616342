import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { AddPrimitivesCommand, Arc, ArcT, BasicControl, CURSOR_TYPE, CircleT, Curve3, DYMODE_MODE, Line, LineT, Listeners, MOUSE, ModelBase, PlineT, Point, PrimitiveColor, TnEngineContext, TnEngineExtraContext, ViewEditor, dymode } from "pythagoras";
import { Vector3 } from "three";
import { ComponentT } from "tnbimbase";

const EXECUTE_TYPE = {
    LINE: 1, // 基准线
    POINT: 2, // 角点
}

// 布置围墙二级控制器
export class AssembleFenceControl extends BasicControl {
    id = EXTRA_CTRL_TYPE.ASSEMBLE_FENCE;

    needInit: boolean;

    selectedEntities: ModelBase[] = []; // 选择的图元

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    upright: ComponentT; // 立柱组件
    uprightSpacing: number; // 立柱间距
    solidWall: ComponentT; // 实墙组件
    banister: ComponentT; // 栏杆组件

    executeType: number = EXECUTE_TYPE.LINE;

    curves: Line[] = []; // 指定角点绘制的线
    lines: LineT[] = [];
    line: LineT = new LineT();

    step: number = 1;

    capturePoint: Vector3;
    orthogonalPoint: Vector3;
    polarPoint: Vector3;

    point_down: Point = new Point();
    point_move: Point = new Point();

    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.needInit = true;

        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();

        this.editor = editor;
        this.listeners = listeners;

        this.listeners.getSignal(EXTRA_SINGAL.onAssembleFenceByLine).add(this.onAssembleFenceByLine);
        this.listeners.getSignal(EXTRA_SINGAL.onAssembleFenceByPoint).add(this.onAssembleFenceByPoint);

        console.log('AssembleFenceControl added')
    }

    dispose(): void {
        this.listeners.getSignal(EXTRA_SINGAL.onAssembleFenceByLine).remove(this.onAssembleFenceByLine);
        this.listeners.getSignal(EXTRA_SINGAL.onAssembleFenceByPoint).remove(this.onAssembleFenceByPoint);
        // 派发置空选择集的信号
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.editor.renderer.domElement.removeEventListener('pointermove', this.onPointerMove);
        this.listeners.signals.onPointCapture.remove(this.onPointCapture);
        this.listeners.signals.onKeyMouseConfirm.remove(this.onKeyMouseConfirm);
        this.listeners.signals.onKeyMouseConfirm.remove(this.onKeyMouseConfirmPoint);
        this.listeners.signals.onPointOrthogonal.remove(this.onPointOrthogonal);
        this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
        this.listeners.signals.onPointPolarTracking.remove(this.onPointPolarTracking);
        this.listeners.signals.noNeedCapture.dispatch();
        this.editor.selectControl.setEnabled(true);
        this.extraContext.dymodeContext.enabled = false;
        this.editor.orthogonalControl.enabled = false;
        this.editor.selectControl.setEnabled(true);
        this.editor.clampControl.enabled = true;
        this.editor.clampControl.hidden = false;
        let obj = this.line?.viewObj;
        if (obj && !!obj.parent) {
            obj.parent.remove(obj);
        }
        for (let i = 0; i < this.lines.length; i++) {
            let obj = this.lines[i]?.viewObj;
            if (obj && !!obj.parent) {
                obj.parent.remove(obj);
            }
        }
        if (this.executeType == EXECUTE_TYPE.POINT) {
            if (this.curves && this.curves.length > 0) {
                let newEntities = this.assembleByPline(this._worldZ, this.curves);
                this.editor.history.execute(new AddPrimitivesCommand(newEntities));
            }
        }
        console.log('AssembleFenceControl removed')
    }

    initControl(data: any): void {
        if (!!data.executeType) {
            if (data.executeType === EXECUTE_TYPE.LINE) {
                this.onAssembleFenceByLine(data.upright, data.uprightSpacing, data.solidWall, data.banister);
            } else if (data.executeType === EXECUTE_TYPE.POINT) {
                this.onAssembleFenceByPoint(data.upright, data.uprightSpacing, data.solidWall, data.banister);
            }
        }
    }

    /**
     * 通过选基准线布置围墙
     * @param {ComponentT} upright - 立柱组件
     * @param {number} uprightSpacing - 立柱间距
     * @param {ComponentT} solidWall - 实墙组件
     * @param {ComponentT} banister - 栏杆组件
     */
    onAssembleFenceByLine = (upright: ComponentT, uprightSpacing: number, solidWall: ComponentT, banister: ComponentT) => {
        let initData = {
            upright: upright,
            uprightSpacing: uprightSpacing,
            solidWall: solidWall,
            banister: banister,
            executeType: EXECUTE_TYPE.LINE
        }
        this.listeners.signals.setInitControlData.dispatch(initData);
        this.executeType = EXECUTE_TYPE.LINE;
        this.upright = upright;
        this.uprightSpacing = uprightSpacing;
        this.solidWall = solidWall;
        this.banister = banister;
        // 派发置空选择集的信号
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.extraContext.dymodeContext.setDymodeTip('选择基准线:');
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.TIPONLY);
        this.extraContext.stmodeContext.setStmode('选择基准线');
        // 激活选择
        this.editor.selectControl.setEnabled(true);
        // 钝化夹点
        this.editor.clampControl.enabled = false;
        this.editor.clampControl.hidden = true;
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.PICK);
        // 订阅确认信号
        this.listeners.signals.onKeyMouseConfirm.add(this.onKeyMouseConfirm);
    }

    private _worldZ = new Vector3(0, 0, 1);

    onKeyMouseConfirm = () => {
        if (this.executeType != EXECUTE_TYPE.LINE) return;
        this.selectedEntities = this.editor.selectControl.getSelectedEntityList().filter(entity => !entity.lock && (entity.baseType == 'LineT' || entity.baseType == 'ArcT' || entity.baseType == 'CircleT' || entity.baseType == 'PlineT'));
        if (this.selectedEntities.length === 0) {
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
        }
        let newEntities = [];
        for (let i = 0; i < this.selectedEntities.length; i++) {
            let entity = this.selectedEntities[i];
            switch (entity.baseType) {
                case 'LineT':
                    let lineT = entity as LineT;
                    newEntities = newEntities.concat(this.assembleByPline(this._worldZ, [lineT._geo]));
                    break;
                case 'ArcT':
                    let arcT = entity as ArcT;
                    newEntities = newEntities.concat(this.assembleByPline(this._worldZ, [arcT._geo]));
                    break;
                case 'CircleT':
                    let circleT = entity as CircleT;
                    newEntities = newEntities.concat(this.assembleByPline(this._worldZ, [circleT._geoCircle]));
                    break;
                case 'PlineT':
                    let plineT = entity as PlineT;
                    newEntities = newEntities.concat(this.assembleByPline(this._worldZ, plineT._geoCurves));
                    break;
            }
        }

        this.editor.history.execute(new AddPrimitivesCommand(newEntities));
        this.listeners.signals.onOpeCommandControlEnd.dispatch();
    }

    private _dir = new Vector3();

    private _tmpPoint = new Point();


    // 将一个组件放置在指定点，并转角
    getComponent = (component: ComponentT, point: Point, normalRotateAxis: Vector3, normalDeltaAngle: number, axisXRotateAxis: Vector3, axisXDeltaAngle: number) => {
        this._dir.set(point.x - component.position.x, point.y - component.position.y, point.z - component.position.z);
        let moveLength = this._dir.length();
        this._dir.normalize();
        component.move(this._dir, moveLength);
        component.rotate(point, normalRotateAxis, normalDeltaAngle);
        component.rotate(point, axisXRotateAxis, axisXDeltaAngle);
        return component;
    }

    private _tmpAxisX = new Vector3();

    // 将组件按路径以某间隔复制，起点处放置，终点处不放置
    copyByLineCurve = (component: ComponentT, curve: Line, space: number, normal: Vector3, needEnd: boolean = false) => {
        if (!component) return [];
        if (curve.delta().projectOnPlane(normal).normalize().length() < 1e-6) {
            // 线本身是垂直的，不布置
            return [];
        }
        // 周长
        let distTotal = curve.distance();
        let axisZ = normal.clone();
        let normalRotateAxis = component.normal.clone().cross(axisZ).normalize();
        if (Math.abs(component.normal.dot(axisZ) + 1) < 1e-6) {
            normalRotateAxis = component.axisX.clone();
        }
        let normalDeltaAngle = axisZ.angleTo(component.normal);
        let axisX = curve.delta().projectOnPlane(normal).normalize();
        let tempAxisX = component.axisX.clone().applyAxisAngle(normalRotateAxis, normalDeltaAngle);
        let axisXRotateAxis = tempAxisX.clone().cross(axisX).normalize();
        let axisXDeltaAngle = tempAxisX.angleTo(axisX);
        if (Math.abs(tempAxisX.dot(axisX) + 1) < 1e-6) {
            axisXRotateAxis = axisZ.clone();
        }
        let newEntities = []
        // 端点
        let endPoint = curve.getPointAt(1);
        // 计算间距占周长的比例
        let lengthFactor = space / distTotal;
        // 最多可以分成几段
        let divNumber = Math.floor(distTotal / space);
        for (let i = 0; i <= divNumber; i++) {
            curve.getPointAt(i * lengthFactor, this._tmpPoint);
            if (!needEnd && this._tmpPoint.equals(endPoint)) {
                // 不布置端点
                continue;
            }
            // 布置间隔组件
            newEntities.push(this.getComponent(component.clone(), this._tmpPoint, normalRotateAxis, normalDeltaAngle, axisXRotateAxis, axisXDeltaAngle));
        }
        return newEntities;
    }
    // 将组件按路径以某间隔复制，起点处放置，终点处不放置
    copyByArcCurve = (component: ComponentT, curve: Arc, space: number, normal: Vector3, needEnd: boolean = false) => {
        if (!component) return [];
        if (curve.normal.clone().dot(normal) < 1e-6) {
            // 弧本身是垂直的，不布置
            return [];
        }
        // 周长
        let distTotal = curve.distance();
        let axisZ = normal.clone();
        let normalRotateAxis = component.normal.clone().cross(axisZ).normalize();
        if (Math.abs(component.normal.dot(axisZ) + 1) < 1e-6) {
            normalRotateAxis = component.axisX.clone();
        }
        let normalDeltaAngle = axisZ.angleTo(component.normal);
        let tempAxisX = component.axisX.clone().applyAxisAngle(normalRotateAxis, normalDeltaAngle);
        // 需要在中间布置
        let newEntities = []
        // 端点
        let endPoint = curve.getPointAt(1);
        // 计算间距占周长的比例
        let lengthFactor = space / distTotal;
        // 最多可以分成几段
        let divNumber = Math.floor(distTotal / space);
        for (let i = 0; i <= divNumber; i++) {
            curve.getPointAt(i * lengthFactor, this._tmpPoint);
            if (!needEnd && this._tmpPoint.equals(endPoint)) {
                // 不布置端点
                continue;
            }
            // 布置间隔组件
            this._tmpAxisX.copy(curve.getCutLineDir(this._tmpPoint).projectOnPlane(normal)).normalize();
            let axisXRotateAxis = tempAxisX.clone().cross(this._tmpAxisX).normalize();
            let axisXDeltaAngle = tempAxisX.angleTo(this._tmpAxisX);
            if (Math.abs(tempAxisX.dot(this._tmpAxisX) + 1) < 1e-6) {
                axisXRotateAxis = normal.clone();
            }
            newEntities.push(this.getComponent(component.clone(), this._tmpPoint, normalRotateAxis, normalDeltaAngle, axisXRotateAxis, axisXDeltaAngle));
        }
        return newEntities;
    }

    // 基准线是多段线
    assembleByPline = (normal: Vector3, curves: Curve3[]) => {
        let newEntities = []
        let solidWallLength = 0;
        if (!!this.solidWall) {
            // 实墙组件的包围盒
            let solidWallBox = this.solidWall.getBoundingBox();
            // 长度
            solidWallLength = solidWallBox.max.x - solidWallBox.min.x;
        }
        let banisterLength = 0;
        if (!!this.banister) {
            // 栏杆组件的包围盒
            let banisterBox = this.banister.getBoundingBox();
            // 长度
            banisterLength = banisterBox.max.x - banisterBox.min.x;
        }

        let needEnd = false;
        curves.forEach((curve, index) => {
            if (index == curves.length - 1) {
                // 是最后一段线，需要布置终点
                needEnd = true;
            }
            if (curve.type == 'Arc' || curve.type == 'Circle') {
                let arc = curve as Arc;
                // ===== 布置立柱 =====
                // 立柱图元
                let uprightEntities = this.copyByArcCurve(this.upright, arc, this.uprightSpacing, normal, needEnd);
                newEntities = newEntities.concat(uprightEntities);
                // ===== 布置实墙 =====
                // 实墙图元
                let solidWallEntities = this.copyByArcCurve(this.solidWall, arc, solidWallLength, normal, needEnd);
                newEntities = newEntities.concat(solidWallEntities);
                // ===== 布置栏杆 =====
                // 栏杆图元
                let banisterEntities = this.copyByArcCurve(this.banister, arc, banisterLength, normal, needEnd);
                newEntities = newEntities.concat(banisterEntities);
            } else if (curve.type == 'Line') {
                let line = curve as Line;
                // ===== 布置立柱 =====
                // 立柱图元
                let uprightEntities = this.copyByLineCurve(this.upright, line, this.uprightSpacing, normal, needEnd);
                newEntities = newEntities.concat(uprightEntities);
                // ===== 布置实墙 =====
                // 实墙图元
                let solidWallEntities = this.copyByLineCurve(this.solidWall, line, solidWallLength, normal, needEnd);
                newEntities = newEntities.concat(solidWallEntities);
                // ===== 布置栏杆 =====
                // 栏杆图元
                let banisterEntities = this.copyByLineCurve(this.banister, line, banisterLength, normal, needEnd);
                newEntities = newEntities.concat(banisterEntities);
            }
        })
        return newEntities;
    }


    /**
     * 通过选角点布置围墙
     * @param {ComponentT} upright - 立柱组件
     * @param {number} uprightSpacing - 立柱间距
     * @param {ComponentT} solidWall - 实墙组件
     * @param {ComponentT} banister - 栏杆组件
     */
    onAssembleFenceByPoint = (upright: ComponentT, uprightSpacing: number, solidWall: ComponentT, banister: ComponentT) => {
        let initData = {
            upright: upright,
            uprightSpacing: uprightSpacing,
            solidWall: solidWall,
            banister: banister,
            executeType: EXECUTE_TYPE.POINT
        }
        this.listeners.signals.setInitControlData.dispatch(initData);
        this.executeType = EXECUTE_TYPE.POINT;
        this.upright = upright;
        this.uprightSpacing = uprightSpacing;
        this.solidWall = solidWall;
        this.banister = banister;

        this.curves = [];
        this.lines = [];
        this.step = 1;
        this.editor.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
        this.editor.renderer.domElement.addEventListener('pointermove', this.onPointerMove);
        this.listeners.signals.needCapture.dispatch();
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.listeners.signals.onPointCapture.add(this.onPointCapture);
        this.listeners.signals.onDymodeChanged.add(this.onDymodeChanged);
        this.listeners.signals.onKeyMouseConfirm.add(this.onKeyMouseConfirmPoint);
        this.listeners.signals.onPointOrthogonal.add(this.onPointOrthogonal);
        // 订阅极轴追踪信号，收听到极轴追踪点的坐标
        this.listeners.signals.onPointPolarTracking.add(this.onPointPolarTracking);
        this.editor.selectControl.setEnabled(false);
        this.extraContext.dymodeContext.enabled = true;
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.POINT);
        this.point_down.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        this.point_move.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        this.listeners.signals.sendToDymodeTip.dispatch('指定起点:');
        this.extraContext.stmodeContext.setStmode('指定起点');
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
    }

    onPointPolarTracking = (point) => {
        this.polarPoint = point;
    }

    onPointCapture = (point) => {
        this.capturePoint = point;
    }

    onPointOrthogonal = (point) => {
        this.orthogonalPoint = point;
    }

    onPointerDown = (event) => {
        if (event.button !== MOUSE.LEFT) return;

        let rect = this.editor.renderer.domElement.getBoundingClientRect();
        let x = (event.clientX - rect.x) / rect.width * 2 - 1;
        let y = -(event.clientY - rect.y) / rect.height * 2 + 1;
        this.context.ucsContext.unprojectCursorPoint(x, y, this.editor.camera, this.point_down);
        if (this.orthogonalPoint) {
            this.point_down.copy(this.orthogonalPoint);
        }
        if (this.capturePoint) {
            this.point_down.copy(this.capturePoint);
        }
        if (this.polarPoint) {
            this.point_down.copy(this.polarPoint);
        }
        if (this.step === 1) {
            this.firstStep();
        } else if (this.step === 2) {
            this.nextStep();
        }
    }

    onPointerMove = (event) => {
        this.point_move.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        if (this.step === 2) {
            if (this.orthogonalPoint) {
                this.point_move.copy(this.orthogonalPoint);
            }
            if (this.polarPoint) {
                this.point_move.copy(this.polarPoint);
            }
            if (this.capturePoint) {
                this.point_move.copy(this.capturePoint);
            }
            // 更新临时线
            this.line.setValue('end', this.point_move);
            this.line.updateViewGeometry();
            this.line.updateViewMaterial();
            this.listeners.signals.needRender.dispatch();
        }
    }

    firstStep = () => {
        this.line.set(this.point_down, this.point_move);
        this.line.color.set(PrimitiveColor.editColor);
        this.listeners.signals.needCapture.dispatch(this.point_down);
        let view = this.line.render();
        this.extraContext.sceneContext.scene.add(view);
        this.listeners.signals.needRender.dispatch();
        this.step++;
        this.listeners.signals.sendToDymodeTip.dispatch('指定下一个点:');
        this.extraContext.stmodeContext.setStmode('指定下一个点或');
        this.listeners.signals.orthogonal.dispatch(this.point_down);
        this.listeners.signals.polarTracking.dispatch(this.point_down);
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);
    }

    nextStep = () => {
        let start = this.line._geo.start;
        let end = this.point_down;
        let newLineT = new LineT(start, end);
        this.lines.push(newLineT);
        this.extraContext.sceneContext.scene.add(newLineT.render());
        let newLine = new Line(start, end);
        if (newLine.distanceSq() > 1e-6) {  //判断线段长度是否为0，若为0则不需要创建线段。
            this.curves.push(newLine);
        }
        this.line.set(end, end);

        this.extraContext.stmodeContext.setStmode('指定下一个点或');
        this.listeners.signals.needCapture.dispatch(end);
        this.listeners.signals.orthogonal.dispatch(this.point_down);
        this.listeners.signals.polarTracking.dispatch(this.point_down);
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);
    }

    _dymodeActive = false;

    onDymodeChanged = (value: dymode) => {
        if (value.mode === DYMODE_MODE.POINT) {
            this.point_down.set(value.x, value.y, value.z);
            this._dymodeActive = true;
            this.onKeyMouseConfirmPoint();
        } else if (value.mode === DYMODE_MODE.DIST) {
            let dir = this.point_move.clone().sub(this.point_down).normalize();
            let end = dir.multiplyScalar(value.dist).add(this.point_down);
            this.point_down.set(end.x, end.y, end.z);
            this._dymodeActive = true;
            this.onKeyMouseConfirmPoint();
        }
    }

    onKeyMouseConfirmPoint = () => {
        if (this.step === 1) {
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
            this._dymodeActive = false;
        } else if (this.step === 2) {
            if (this._dymodeActive) {
                this.nextStep();
                this._dymodeActive = false;
            } else {
                this.listeners.signals.onOpeCommandControlEnd.dispatch();
            }
        }
    }

}