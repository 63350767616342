
import { useEffect, useState } from "react";
import { graphicStore } from "@/commons/store/graphic-store";
import { ReactComponent as AssembleSvg } from '@/commons/icons/side/groud-panel/assemble.svg'
import { ReactComponent as UploadBlackSvg } from "@/commons/icons/panel-icon/upload-black.svg";
import { ImportComponent } from '@/commons/components/import-component/import-component';
import { useParams } from "react-router-dom";
import SideBarTool from "@/commons/components/side-bar-tool";
import { Select } from "antd";
import LocalScene from "@/commons/components/local-scene";
import { Component } from "@/commons/interface/speccommon/component";
import { ComponentType } from "@/commons/enums/component-type";
import { getComponents } from "@/api/spec-common/component";
import { AddLayerCommand, LineType, ModelBase } from "pythagoras";
import { Layer } from "@/commons/interface/layer";
import genUUID from "@/commons/utils/gen-uuid";
import { LayerType } from "@/commons/enums/layer-type";
import "./index.less"

export default function ParkingSpace() {

    const { projectId } = useParams();

    const [parkingSpaceList, setParkingSpaceList] = useState<Component[]>([]); // 立柱组件列表

    const [selectParkingSpaceUuid, setSelectParkingSpaceUuid] = useState<String>(); // 被选中的立柱组件

    useEffect(() => {
        getComponentList()
    }, [projectId])

    const onAssembleClick = () => {
        let selectParkingSpace = parkingSpaceList.find(item => item.uuid == selectParkingSpaceUuid)
        if (selectParkingSpace == null) {
            return
        }
        let layerUuid = graphicStore.context.layerContext.layers.find(item => item.type == LayerType.PARKING_SPACE)?.uuid
        if (!graphicStore.context.layerContext.layers.some(item => item.type === LayerType.PARKING_SPACE)) {
            // 没有机动车图层，新增轿厢图层
            layerUuid = genUUID();
            let newLayer: Layer = {
                id: layerUuid,
                uuid: layerUuid,
                name: '车位',
                color: '#2b2b2b',
                lineWidth: 0.25,
                lineType: LineType.BASE_LINE,
                originLayer: false,
                projectUuid: projectId,
                type: LayerType.PARKING_SPACE,

            }
            new AddLayerCommand(newLayer).execute();
        }
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("ADDPARKINGSPACECONTROL");
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal("initPrakingSpaceData").dispatch({ type: 1, entities: selectParkingSpace.primitives, layerUuid: layerUuid });
    }

    const onImportUpright = () => {
        let input = document.getElementById('import-upright');
        input.click();
    }

    const importSuccess = () => {
        getComponentList();
    }

    const getComponentList = () => {
        getComponents({ projectUuid: projectId, type: ComponentType.PARKINGSPACE }).then(res => {
            let list: Component[] = res.data || [];
            list.forEach(item => {
                let entities = item.entities.map((item: any) => {
                    let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(item.type))();
                    entity.fromJson(item);
                    return entity;
                });
                item.primitives = entities;
            })
            setParkingSpaceList(list);
        })
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    机动车位
                </div>
                <div className="detail">
                    <div className="row">
                        <div className="label">机动车位:</div>
                        <Select
                            size='small'
                            className="select-box"
                            value={selectParkingSpaceUuid}
                            // allowClear
                            onChange={(value) => setSelectParkingSpaceUuid(value)}
                            optionLabelProp='label'
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            {parkingSpaceList.map((item) => (
                                <Select.Option value={item.uuid} key={item.uuid} label={item.name}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}>
                                        <LocalScene
                                            left={1}
                                            top={1}
                                            color="#DAE3E5"
                                            height={40}
                                            width={40}
                                            entities={item.primitives}
                                            react_id={`upright-${item.uuid}`}
                                        />
                                        {item.name}
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                        <div className="upload-button" onClick={onImportUpright}><UploadBlackSvg /></div>
                        <input type="file" accept=".txt" id="import-upright" onChange={(e) => ImportComponent(e, projectId, ComponentType.PARKINGSPACE, null, importSuccess)} style={{ display: 'none' }} />

                    </div>
                    <div className="ope-btn">
                        <div className="insert-button" onClick={onAssembleClick}>
                            <div className="icon"><AssembleSvg className="icon-svg" /></div>
                            <div className="text">布置</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}