import { Select, Tooltip, message } from 'antd'
import './index.less'
import {
    AntDesignOutlined, CameraOutlined
} from '@ant-design/icons';
import { ReactComponent as UndoSvg } from '@/commons/icons/operation-bar/undo.svg'
import { ReactComponent as UndoNoneSvg } from '@/commons/icons/operation-bar/undo-none.svg'
import { ReactComponent as RndoSvg } from '@/commons/icons/operation-bar/redo.svg'
import { ReactComponent as RndoNoneSvg } from '@/commons/icons/operation-bar/redo-none.svg'
import { ReactComponent as FilterSvg } from '@/commons/icons/operation-bar/filter.svg'
import { ReactComponent as ArraySvg } from '@/commons/icons/operation-bar/array.svg'
import { ReactComponent as MoveSvg } from '@/commons/icons/operation-bar/move.svg'
import { ReactComponent as CopySvg } from '@/commons/icons/operation-bar/copy.svg'
import { ReactComponent as MirrorSvg } from '@/commons/icons/operation-bar/mirror.svg'
import { ReactComponent as NineSvg } from '@/commons/icons/operation-bar/nine.svg'
import { ReactComponent as RotateSvg } from '@/commons/icons/operation-bar/rotate.svg'
import { ReactComponent as DeleteSvg } from '@/commons/icons/operation-bar/delete.svg'
import { ReactComponent as Light1Svg } from '@/commons/icons/operation-bar/light1.svg'
import { ReactComponent as Light2Svg } from '@/commons/icons/operation-bar/light2.svg'
import { ReactComponent as Light3Svg } from '@/commons/icons/operation-bar/light3.svg'
import { ReactComponent as Twovg } from '@/commons/icons/operation-bar/two.svg'
import { ReactComponent as MessageSvg } from '@/commons/icons/operation-bar/message.svg'
import { ReactComponent as InfoSvg } from '@/commons/icons/operation-bar/info.svg'
import { ReactComponent as LayerSvg } from '@/commons/icons/operation-bar/layer.svg'
import { ReactComponent as UcsSvg } from '@/commons/icons/operation-bar/ucs.svg'
import { ReactComponent as ArrowSvg } from '@/commons/icons/operation-bar/arrow.svg'
import { ReactComponent as StoreySvg } from '@/commons/icons/operation-bar/storey.svg'
import { graphicStore } from '@/commons/store/graphic-store'
import { useEffect, useState, useContext } from 'react'
import ColorChange from '../color/ColorChange';
import { AddPrimitivesCommand, AxisT, CommandGroup, LineType, ModelBase, ToggleDrawColorCommand, ToggleEntityColorCommand, ToggleUcsCommand, UpdatePrimitivesCommand } from 'pythagoras';
import LayerManage from '../layer/LayerManage';
import { DragModal } from 'tncet-common';
import UcsManage from '../ucs/UcsManage';
import { ExportComponent } from './export-component';
import { LayerType } from '@/commons/enums/layer-type';
import { SCircleT, SLineT } from 'tnbimbase';
import { Vector3 } from 'three';
import { SystemContext, StoreyContext } from '@/App';
import { SystemStatus } from "@/commons/context/system-store";
import { CAMERA_TYPE } from '@/panel/draw/viewport';
import { useParams } from 'react-router-dom';
import { StoreyStatus } from '@/commons/context/storey-store';
import { getStoreys } from '@/api/building/storey';
import { Storey } from '@/commons/interface/building/storey';
import { showEntities } from '@/commons/utils/graphic-utils';

export default function OperationBar() {
    const { projectId } = useParams();
    const [undos, setUndos] = useState<any[]>([]);
    const [redos, setRedos] = useState<any[]>([]);
    const [currentColor, setCurrentColor] = useState(null);
    const [isColorByLayer, setIsColorByLayer] = useState(true);
    const [showLayerManage, setShowLayerManage] = useState<boolean>(false);
    const [showUcsManage, setShowUcsManage] = useState<boolean>(false);
    const [currentLayerUuid, setCurrentLayerUuid] = useState<string>('');
    const storeyContext = useContext<StoreyStatus>(StoreyContext);

    const systemContext = useContext<SystemStatus>(SystemContext);
    useEffect(() => {
        if (!projectId) return;
        // 注册层高
        getStoreys(projectId).then(res => {
            if (res.status === 200) {
                let storeys: Storey[] = res.data || [];
                storeys = storeys?.sort((a, b) => a.serialNum - b.serialNum);
                storeyContext.setStoreys([...storeys]);
            }
        })
    }, [projectId])

    useEffect(() => {
        if (!graphicStore?.context?.ucsContext?.currentUcs?.uuid) return;
        let storey = storeyContext.storeys?.find(item => item.uuid === graphicStore?.context?.ucsContext?.currentUcs?.uuid);
        if (!!storey) {
            storeyContext.setCurrentStorey(storey);
        } else {
            storeyContext.setCurrentStorey(null);
        }
    }, [graphicStore?.context?.ucsContext?.currentUcs?.uuid])

    useEffect(() => {
        if (!graphicStore?.extraContext?.getCurrentViewEditor()) return () => { };
        // 注册撤销重做
        graphicStore.extraContext.listeners.signals.undos.add(handleUndos);
        graphicStore.extraContext.listeners.signals.redos.add(handleRedos);

        return () => {
            if (!graphicStore.extraContext.getCurrentViewEditor()) return;
            graphicStore.extraContext.listeners.signals.undos.remove(handleUndos);
            graphicStore.extraContext.listeners.signals.redos.remove(handleRedos);
        }
    }, [graphicStore?.extraContext?.getCurrentViewEditor()])

    const handleUndos = () => {
        setUndos([...graphicStore.extraContext.getCurrentViewEditor()?.history?.undos]);
    }

    const handleRedos = () => {
        setRedos([...graphicStore.extraContext.getCurrentViewEditor()?.history?.redos])
    }

    const undoClick = () => {
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.undo.dispatch();
    }

    const redoClick = () => {
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.redo.dispatch();
    }

    const onLayerChange = (layerUuid) => {
        let curLayer = graphicStore.context.layerContext.layers.find(item => item.uuid === layerUuid);
        graphicStore.context.layerContext.currentLayerUuid = layerUuid;
        graphicStore.context.layerContext.currentLayerColor = curLayer.color;
        graphicStore.context.layerContext.currentColor = curLayer.color;
        setCurrentLayerUuid(layerUuid);
        // 选中的图元
        let selectedEntities: ModelBase[] = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList();
        if (!selectedEntities || selectedEntities?.length === 0) {
            return;
        }
        if (curLayer?.type === LayerType.AXIS_NET) {
            let needAddPriArr = [];
            let changeEntities = selectedEntities?.map(item => {
                let clone = item.clone();
                clone.uuid = item.uuid;
                clone.layerUuid = layerUuid;
                // 图元选中的是线，切换的图层为轴网图层
                // 将线转为轴线
                if (clone.type === 'LineT') {
                    let lineJson: any = clone.toJson();
                    lineJson.type = 'SLineT';
                    lineJson.isGrid = true;
                    lineJson.isGridMain = true;
                    lineJson.isGridSplit = false;
                    lineJson.isDimension = false;
                    lineJson.gridUuid = lineJson.uuid;

                    clone = new SLineT();
                    clone.fromJson(lineJson);

                    // 为轴线插上翅膀
                    addExtendLineAndCircle(clone, needAddPriArr);
                    return clone;
                }
                return clone;
            })
            let command = new CommandGroup();
            command.cmds.push(new UpdatePrimitivesCommand(selectedEntities, changeEntities));
            command.cmds.push(new AddPrimitivesCommand(needAddPriArr));
            graphicStore.extraContext.getCurrentViewEditor().history.execute(command);
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.forceEntitiesSelected.dispatch(changeEntities);
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
        } else {
            let changeEntities = selectedEntities?.map(item => {
                item.layerUuid = layerUuid;
                return item;
            })
            new UpdatePrimitivesCommand(selectedEntities, changeEntities).execute();
        }

    }

    const addExtendLineAndCircle = (item, arr) => {
        let gridUuid = item.uuid;
        let lineDistance = graphicStore.context.renderContext.gridLineDistanceToCircle;
        let radius = graphicStore.context.renderContext.gridCircleSize;
        let circleDistance = lineDistance + radius;

        // 计算起点到终点延长线上距离x的另一个点，并将这个终点到这个点做一条线
        let startToEndV = item._geo.delta();
        let start = item._geo.start;
        let end = item._geo.end;
        let point1 = new Vector3(end.x, end.y, end.z);
        let direction1 = startToEndV.normalize();
        let newPoint1 = point1.clone().add(direction1.clone().multiplyScalar(lineDistance));
        let endExtendLine = new SLineT(end, newPoint1);
        endExtendLine.isGrid = true;
        endExtendLine.isDimension = true;
        endExtendLine.gridUuid = gridUuid;
        endExtendLine.lineType = LineType.DASH_DOT_LINE;
        endExtendLine.isLineTypeByLayer = false;
        arr.push(endExtendLine);

        let endToStartV = startToEndV.clone().negate();
        let point2 = new Vector3(start.x, start.y, start.z);
        let direction2 = endToStartV.normalize();
        let newPoint2 = point2.clone().add(direction2.clone().multiplyScalar(lineDistance));
        let startExtendLine = new SLineT(start, newPoint2);
        startExtendLine.isGrid = true;
        startExtendLine.isDimension = true;
        startExtendLine.gridUuid = gridUuid;
        startExtendLine.lineType = LineType.DASH_DOT_LINE;
        startExtendLine.isLineTypeByLayer = false;
        arr.push(startExtendLine);

        // 画圆
        let circleCenter1 = point1.clone().add(direction1.clone().multiplyScalar(circleDistance));
        let circle1 = new SCircleT(circleCenter1, radius);
        circle1.isGrid = true;
        circle1.gridUuid = gridUuid;
        circle1.lineType = LineType.DASH_DOT_LINE;
        circle1.isLineTypeByLayer = false;
        arr.push(circle1);

        let circleCenter2 = point2.clone().add(direction2.clone().multiplyScalar(circleDistance));
        let circle2 = new SCircleT(circleCenter2, radius);
        circle2.isGrid = true;
        circle2.gridUuid = gridUuid;
        circle2.lineType = LineType.DASH_DOT_LINE;
        circle2.isLineTypeByLayer = false;
        arr.push(circle2);
    }

    useEffect(() => {
        if (!graphicStore?.context?.layerContext?.currentLayerUuid) return;
        setCurrentLayerUuid(graphicStore.context.layerContext.currentLayerUuid);
    }, [graphicStore?.context?.layerContext?.currentLayerUuid])

    const handleChangeColor = (color) => {
        let oldColor = currentColor;
        let oldIsColorByLayer = isColorByLayer;
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        let selects = editor.selectControl.getSelectedEntityList();
        if (selects.length === 0) {

            editor.history.execute(new ToggleDrawColorCommand({
                color: color,
                isColorByLayer: false,
            }, {
                color: oldColor,
                isColorByLayer: oldIsColorByLayer,
            }))

        } else {
            editor.history.execute(new ToggleEntityColorCommand(selects, color, false));
        }
        editor.listeners.signals.onColorChanged.dispatch();
        setCurrentColor(color);
        setIsColorByLayer(false);
    }

    const handleColorByLayer = () => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        let oldIsColorByLayer = isColorByLayer;
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        let selects = editor.selectControl.getSelectedEntityList();
        let color = graphicStore.context.layerContext.currentLayerColor;
        if (selects.length === 0) {
            editor.history.execute(new ToggleDrawColorCommand({
                color: color,
                isColorByLayer: true,
            }, {
                color: graphicStore.context.layerContext.currentColor,
                isColorByLayer: oldIsColorByLayer,
            }));
        } else {
            editor.history.execute(new ToggleEntityColorCommand(selects, color, true));
        }
        editor.listeners.signals.onColorChanged.dispatch();
        setCurrentColor(color);
        setIsColorByLayer(true);
    }

    const onUcsChange = (ucsUuid) => {
        if (ucsUuid == null) return;
        let ucs = graphicStore?.context?.ucsContext?.ucsList?.find((ucs: AxisT) => ucs.uuid === ucsUuid);
        if (ucs == null) return;
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleUcsCommand(ucs));
    }

    const onActiveSubControl = (cmd: string) => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch(cmd);
    }

    const onChangeCameraType = () => {
        if (systemContext.currentCameraType === CAMERA_TYPE.Orthographic) {
            systemContext.setCurrentCameraType(CAMERA_TYPE.Perspective);
            message.success("切换至透视相机");
        } else {
            systemContext.setCurrentCameraType(CAMERA_TYPE.Orthographic);
            message.success("切换至正交相机");
        }
    }
    const onStoreyChange = (storeyUuid) => {
        if (!storeyUuid) {
            // 取消层高
            storeyContext.setCurrentStorey(null);
            let wcs = graphicStore.context.ucsContext.getWcs();
            // graphicStore.context.ucsContext.setCurrentUcs(wcs);
            graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleUcsCommand(wcs));
            return;
        }
        let storey = storeyContext.storeys?.find(item => item.uuid === storeyUuid);
        storeyContext.setCurrentStorey(storey);
        let workPlaneUcs = new AxisT();
        workPlaneUcs.origin = new Vector3(0, 0, (storey?.bottomElevation * 1000 || 0));
        workPlaneUcs.axisX = new Vector3(1, 0, 0);
        workPlaneUcs.axisY = new Vector3(0, 1, 0);
        workPlaneUcs.axisZ = new Vector3(0, 0, 1);
        workPlaneUcs.uuid = storeyUuid;
        workPlaneUcs.name = `层-${storey?.name}`;
        if (!graphicStore.context.ucsContext.ucsList?.some(item => item.uuid === storeyUuid)) {
            graphicStore.context.ucsContext.addUcsNoCurrent(workPlaneUcs);
        }
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleUcsCommand(workPlaneUcs));
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redaw');
    }


    return (
        <div className="operation-bar-container">
            <div className="temp-area">
                <Tooltip title="撤回" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    {undos?.length > 0 && <UndoSvg onClick={undoClick} />}
                    {(!undos || undos?.length === 0) && <UndoNoneSvg />}
                </Tooltip>
                <Tooltip title="重做" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    {redos?.length > 0 && <RndoSvg onClick={redoClick} />}
                    {(!redos || redos?.length === 0) && <RndoNoneSvg />}
                </Tooltip>
                <Tooltip title="图层" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <div className='layer-select-container'>
                        <div className='layer-icon' onClick={() => {
                            setShowLayerManage(true);
                            if (!graphicStore?.extraContext?.getCurrentViewEditor()) return;
                            graphicStore?.extraContext?.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
                        }}>
                            <LayerSvg></LayerSvg>
                        </div>
                        <Select
                            style={{ width: '132px' }}
                            size="small"
                            suffixIcon={<ArrowSvg />}
                            // value={graphicStore?.context?.layerContext?.currentLayerUuid}
                            value={currentLayerUuid}
                            dropdownAlign={{
                                points: ['tc', 'bc'],
                                offset: [0, 0],
                                overflow: { adjustY: 0, adjustX: 0 },
                            }}
                            onChange={(e) => onLayerChange(e)}
                        >
                            {graphicStore?.context?.layerContext?.layers?.map((layer: any, key) => (
                                <Select.Option value={layer?.uuid} key={layer?.uuid}>{layer?.name}</Select.Option>
                            ))}
                        </Select>
                    </div>

                </Tooltip>
                <Tooltip title="UCS" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <div className='ucs-select-container'>
                        <div className='ucs-icon' onClick={() => setShowUcsManage(true)}>
                            <UcsSvg></UcsSvg>
                        </div>
                        <Select
                            style={{ width: '132px' }}
                            size="small"
                            suffixIcon={<ArrowSvg />}
                            value={graphicStore?.context?.ucsContext?.currentUcs?.uuid}
                            dropdownAlign={{
                                points: ['tc', 'bc'],
                                offset: [0, 0],
                                overflow: { adjustY: 0, adjustX: 0 },
                            }}
                            onChange={(e) => onUcsChange(e)}
                        >
                            {graphicStore?.context?.ucsContext?.ucsList?.sort((a, b) => (b.isWcs ? 1 : 0) - (a.isWcs ? 1 : 0))?.map((ucs: any, key) => (
                                <Select.Option value={ucs?.uuid} key={key}>{ucs?.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </Tooltip>
                <ColorChange
                    editor={graphicStore?.extraContext?.getCurrentViewEditor()}
                    selectColor={(color) => { handleChangeColor(color); }}
                    selectByLayer={() => handleColorByLayer()}
                />
                <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <FilterSvg />
                </Tooltip>
                <Tooltip title="阵列" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <ArraySvg onClick={() => onActiveSubControl('ARRAY')} />
                </Tooltip>
                <Tooltip title="移动" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <MoveSvg onClick={() => onActiveSubControl('MOVE')} />
                </Tooltip>
                <Tooltip title="复制" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <CopySvg onClick={() => onActiveSubControl('COPY')} />
                </Tooltip>
                <Tooltip title="镜像" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <MirrorSvg onClick={() => onActiveSubControl('MIRROR')} />
                </Tooltip>
                <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <NineSvg />
                </Tooltip>
                <Tooltip title="旋转" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <RotateSvg onClick={() => onActiveSubControl('ROTATE')} />
                </Tooltip>
                <Tooltip title="删除" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <DeleteSvg onClick={() => onActiveSubControl('ERASE')} />
                </Tooltip>
                <Tooltip title="TODO文字" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <AntDesignOutlined onClick={() => onActiveSubControl('DT')} />
                </Tooltip>
                <Tooltip title="TODO修剪" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <AntDesignOutlined onClick={() => onActiveSubControl('TRIM')} />
                </Tooltip>
                <Tooltip title="TODO缩放" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <AntDesignOutlined onClick={() => onActiveSubControl('SCALE')} />
                </Tooltip>
                <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <Light1Svg />
                </Tooltip>
                <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <Light2Svg />
                </Tooltip>
                <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <Light3Svg />
                </Tooltip>
                <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <Twovg />
                </Tooltip>
                <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <InfoSvg />
                </Tooltip>
                <Tooltip title="导出为组件" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    {/* todo 图标需更换，将选中图元导出为组件 */}
                    <MessageSvg onClick={() => ExportComponent(graphicStore.extraContext.getCurrentViewEditor())} />
                </Tooltip>
                <Tooltip title={systemContext.currentCameraType === CAMERA_TYPE.Orthographic ? "切换为透视相机" : "切换为正交相机"} color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <CameraOutlined onClick={onChangeCameraType} />
                </Tooltip>

                <Tooltip title="展示细节" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <div className='layer-select-container' style={{ marginLeft: 'auto', marginRight: 4, marginBottom: '-8px', paddingBottom: '8px' }}>
                        <Select
                            style={{ width: '132px' }}
                            placeholder="显示细节图元"
                            size="small"
                            suffixIcon={<ArrowSvg />}
                            allowClear
                            dropdownAlign={{
                                points: ['tc', 'bc'],
                                offset: [0, 0],
                                overflow: { adjustY: 0, adjustX: 0 },
                            }}
                            onChange={(layerUuid) => {
                                // console.log("shouldShowEntities=====");
                                if (!layerUuid) {
                                    // 显示全部
                                    let showDetailList = [];
                                    graphicStore?.context?.layerContext?.layers?.filter(item => item.name.match("细节")).forEach(layer => {
                                        showDetailList.push(layer.uuid);
                                    });
                                    const editor = graphicStore?.extraContext?.getCurrentViewEditor();
                                    Object.keys(editor.renderControl.objectsStates).forEach(key => {
                                        let mapItem = editor.renderControl.objectsStates[key];
                                        let needHidden = false;

                                        for (let i = 0; i < showDetailList.length; i++) {
                                            let detailKey = showDetailList[i];
                                            if (key.includes(detailKey)) {
                                                mapItem.hidden = true;
                                                needHidden = true;
                                                break;
                                            }
                                        }
                                        if(!needHidden){
                                            mapItem.hidden = false;
                                            mapItem.needRegenerate = true;
                                        }
                                    })
                                    Object.keys(editor.renderControl.edgeRenderMap).forEach(key => {
                                        let mapItem = editor.renderControl.edgeRenderMap[key];
                                        let needHidden = false;

                                        for (let i = 0; i < showDetailList.length; i++) {
                                            let detailKey = showDetailList[i];
                                            if (key.includes(detailKey)) {
                                                mapItem.hidden = true;
                                                needHidden = true;
                                                break;
                                            }
                                        }
                                        if(!needHidden){
                                            mapItem.hidden = false;
                                            mapItem.needRegenerate = true;
                                        }
                                    })
                                    editor.showAllEntities();
                                    graphicStore.extraContext.listeners.signals.needRender.dispatch("redraw");
                                    return;
                                }
                                // 只显示该图层的图元
                                const editor = graphicStore?.extraContext?.getCurrentViewEditor();
                                let shouldShowEntities: ModelBase[] = [];
                                Object.keys(editor.renderControl.objectsStates).forEach(key => {
                                    let mapItem = editor.renderControl.objectsStates[key];
                                    if (key.includes(layerUuid)) {
                                        // console.log("mapItem", mapItem);
                                        if(mapItem.models){
                                            shouldShowEntities.push(...mapItem.models);
                                        }
                                        if(mapItem.renderItems){
                                            mapItem.renderItems.forEach(item => {
                                                let entity = editor.entityDict[item.modelUuid]
                                                if(entity){
                                                    shouldShowEntities.push(entity);
                                                }
                                            })
                                        }
                                        mapItem.hidden = false;
                                        mapItem.needRegenerate = true;
                                    } else {
                                        mapItem.hidden = true;
                                    }
                                })
                                Object.keys(editor.renderControl.edgeRenderMap).forEach(key => {
                                    let mapItem = editor.renderControl.edgeRenderMap[key];
                                    if (key.includes(layerUuid)) {
                                        mapItem.hidden = false;
                                        mapItem.needRegenerate = true;
                                    } else {
                                        mapItem.hidden = true;
                                    }
                                })
                                // console.log("shouldShowEntities", shouldShowEntities);
                                showEntities(shouldShowEntities);
                                graphicStore.extraContext.listeners.signals.needRender.dispatch("redraw");
                                // graphicStore?.extraContext?.getCurrentViewEditor().history.execute(new LayerPartialDisplayCommand(layerUuid));
                            }}
                        >
                            {graphicStore?.context?.layerContext?.layers?.filter(item => item.name.match("细节")).map((layer: any, key) => (
                                <Select.Option value={layer?.uuid} key={layer?.uuid}>{layer?.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </Tooltip>
                <Tooltip title="层高" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                    <div className='storey-select-container' style={{ marginLeft: 'auto', marginRight: 4 }}>
                        <div className='storey-icon' onClick={() => { }}>
                            <StoreySvg></StoreySvg>
                        </div>
                        <Select
                            style={{ width: '132px' }}
                            size="small"
                            suffixIcon={<ArrowSvg />}
                            value={storeyContext.currentStorey?.uuid}
                            dropdownAlign={{
                                points: ['tc', 'bc'],
                                offset: [0, 0],
                                overflow: { adjustY: 0, adjustX: 0 },
                            }}
                            onChange={(e) => onStoreyChange(e)}
                            allowClear
                        >
                            {storeyContext.storeys?.map((item: Storey, key) => (
                                <Select.Option value={item?.uuid} key={key}>{item?.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </Tooltip>
            </div>



            <DragModal
                title="图层管理"
                visible={showLayerManage}
                width={1000}
                mask
                destroyOnClose
                onClose={() => setShowLayerManage(false)}>
                <LayerManage />
            </DragModal>

            <DragModal
                title="UCS"
                visible={showUcsManage}
                width={400}
                mask
                destroyOnClose
                onClose={() => setShowUcsManage(false)}>
                <UcsManage storeys={storeyContext.storeys} />
            </DragModal>
        </div>
    )
}