import SideBarTool from "@/commons/components/side-bar-tool"
import { SideBarBtnType } from "@/commons/enums/side-bar-btn-type"
import "../../commons/styles/side-bar.less"
import "./index.less"
import { ReactComponent as RoadTypeSvg } from "@/commons/icons/panel-icon/road-type.svg";
import { useEffect, useState } from "react";
import { RoadNetwork } from "@/commons/interface/building/road-network";
import { useParams } from "react-router-dom";
import { deleteRoadNetworkByUuids, getRoadNetworkListByProjectUuid } from "@/api/building/road-network";
import RoadListItem from "./list-item";
import RoadTypeModel from "./model";
import { RoadType } from "@/commons/interface/building/road-type";
import { getRoadTypeListByProjectUuid } from "@/api/building/road-type";
import { graphicStore } from "@/commons/store/graphic-store";
import { BlockT, ModelBase } from "pythagoras";
import { LoadingOutlined } from "@ant-design/icons";
import { deletePrimitivesInGraphic } from "@/api/geometry/primitive";
import { message } from "antd";

interface Iprops {
    width: number
}

export default function RoadPanel(prop: Iprops) {

    const [roadNetworkList, setRoadNetworkList] = useState<RoadNetwork[]>([])

    const [selectItemIndexList, setSelectItemIndexList] = useState<string[]>([])

    const [open, setOpen] = useState<boolean>(false)

    const [roadTypeList, setRoadTypeList] = useState<RoadType[]>([])

    const { projectId } = useParams()

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        generateData()
    }, [])

    const generateData = () => {
        getRoadNetworkListByProjectUuid(projectId).then(res => {
            setRoadNetworkList(res.data || [])
            setSelectItemIndexList([])
        })
        getRoadTypeListByProjectUuid(projectId).then(res => {
            setRoadTypeList(res.data || [])
        })
    }

    useEffect(() => {
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onSelectChanged.add(onSelectChange)

        return (() => {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onSelectChanged.remove(onSelectChange)
        })
    }, [selectItemIndexList, roadNetworkList])

    useEffect(() => {
        if (!graphicStore.extraContext || !graphicStore.extraContext.getCurrentViewEditor()) {
            //没有getCurrentViewEditor的话，说明是页面刷新，此时需要等待当前viewEditor被创建
            setLoading(true);
            setTimeout(() => {
                graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal('updateRoadNetworkList').add(updateRoadNetworkList);
                setLoading(false);
            }, 5000);
        } else {
            graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal('updateRoadNetworkList').add(updateRoadNetworkList)
        }
        return (() => {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal('updateRoadNetworkList').remove(updateRoadNetworkList)
        })
    }, [roadNetworkList])

    const onSelectChange = (entities: ModelBase[]) => {
        if (entities?.length > 0) {
            for (let i = 0; i < entities.length; i++) {
                let midEntity = entities[i]
                if (midEntity.baseType == "BlockT") {
                    let block = midEntity as BlockT
                    if (block?.extraData?.roadUuid?.length > 0) {
                        setSelectItemIndexList([...selectItemIndexList, block?.extraData?.roadUuid])
                    }
                }
            }
        }
        getRoadNetworkListByProjectUuid(projectId).then(res => {
            setRoadNetworkList(res.data || [])
        })
    }


    const updateRoadNetworkList = () => {
        generateData()
    }

    const onDeleteClick = () => {
        if (selectItemIndexList?.length > 0) {
            let deleteEntityUuids: string[] = []
            for (let i = 0; i < selectItemIndexList.length; i++) {
                let midRoad = roadNetworkList.find(item => item.uuid == selectItemIndexList[i])
                if (midRoad?.roadUuid?.length > 0) {
                    deleteEntityUuids.push(midRoad?.roadUuid)
                    let roadUuid = midRoad?.roadUuid
                    let model = graphicStore.extraContext.getCurrentViewEditor().entityDict[roadUuid]
                    if (model) {
                        graphicStore.extraContext.getCurrentViewEditor().removeObject(model)
                    }
                }
            }
            deleteRoadNetworkByUuids(selectItemIndexList).then(() => {
                generateData()
                return deletePrimitivesInGraphic(deleteEntityUuids)
            }).then(res => {
                message.success("成功删除")
            }).catch(e => {
                message.error("删除失败！")
            }).finally(() => {
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
            })
        }
    }


    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[SideBarBtnType.DELETE]}
                onDeleteBtnClick={onDeleteClick}
            />
            <div className="body">
                {loading && <LoadingOutlined />}
                {!loading && (
                    <>
                        <div className="title">
                            道路
                        </div>
                        <div className="detail">
                            <div className="list-title">
                                <div className="road-name">
                                    路名
                                </div>
                                <div className="road-type">
                                    路型
                                    <RoadTypeSvg className="type-icon" onClick={() => { setOpen(true) }} />
                                </div>
                            </div>
                            <div className="road-list">
                                {roadNetworkList?.filter(item => item.roadUuid?.length > 0).map((item, index) => (
                                    <RoadListItem
                                        selectList={selectItemIndexList}
                                        setSelectList={setSelectItemIndexList}
                                        roadNetwork={item}
                                        optionList={roadTypeList}
                                        key={index}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <RoadTypeModel generateList={() => { generateData() }} projectUuid={projectId} roadTypeList={roadTypeList} className="road-type-model" visiable={open} setVisiable={setOpen} />
        </div>
    )
}