import { Button, Input, message, Space } from 'antd';
import { Component } from 'react'
import './UcsManage.less';
import Icon, { CaretRightOutlined } from '@ant-design/icons'
import { ReactComponent as WordSvg } from '@/commons/icons/ucs/world.svg';
import { ReactComponent as AxisSvg } from '@/commons/icons/ucs/axis.svg';
import classnames from 'classnames';
import { AxisT, RemoveUcsCommand, ToggleUcsCommand, UpdateUcsNameCommand } from 'pythagoras';
import { graphicStore } from '@/commons/store/graphic-store';
import { Storey } from '@/commons/interface/building/storey';

interface IProps {
    storeys: Storey[]
}

export default class UcsManage extends Component<IProps> {

    state = {
        selectUuid: null,
        renamingUuid: null,
    }

    setCurrentUcs = () => {
        if (this.state.selectUuid == null) return;
        let ucs = graphicStore?.context?.ucsContext?.ucsList.find((ucs: AxisT) => ucs.uuid === this.state.selectUuid);
        if (ucs == null) return;
        graphicStore?.extraContext?.getCurrentViewEditor()?.history.execute(new ToggleUcsCommand(ucs));
    }

    ondelete = () => {
        if (this.state.selectUuid == null) {
            message.error('当前坐标系无法删除');
            return;
        }
        if (this.state.selectUuid === graphicStore?.context?.ucsContext?.ucsUuid) {
            message.error('当前坐标系无法删除');
            return;
        }
        let ucs = graphicStore?.context?.ucsContext?.ucsList.find(item => item.uuid === this.state.selectUuid);
        if (ucs == null) {
            message.error('找不到坐标系');
            return;
        }
        if (ucs.isWcs) {
            message.error('世界坐标系无法删除');
            return;
        }
        if (this.props.storeys?.some(item => item.uuid === this.state.selectUuid)) {
            message.error('当前坐标系无法删除');
            return;
        }
        graphicStore?.extraContext?.getCurrentViewEditor()?.history.execute(new RemoveUcsCommand(ucs));
    }

    activeUcsRenaming = () => {
        let selected = this.state.selectUuid || graphicStore?.context?.ucsContext?.ucsUuid;
        let ucs: AxisT = graphicStore?.context?.ucsContext?.ucsList.find(item => item.uuid === selected);
        if (ucs == null) return;
        if (ucs.isWcs) {
            message.error('世界坐标系无法重命名');
            return;
        }
        this.setState({
            renamingUuid: ucs.uuid
        })
    }

    changeUcsName = (ucs: AxisT, name: string) => {
        if (name.match(/^\s*$/)) {
            message.warning('此名称的长度必须至少为一个字符。');
            return;
        }
        if (graphicStore?.context?.ucsContext?.ucsList.some((item: AxisT) => item.uuid != ucs.uuid && item.name === name.trim())) {
            message.warning(`名称` + name.trim() + `已在使用。`);
            return;
        }
        graphicStore?.extraContext?.getCurrentViewEditor()?.history.execute(new UpdateUcsNameCommand(ucs, name.trim()));
        this.setState({
            renamingUuid: null
        })
    }
    render() {
        let selected = this.state.selectUuid || graphicStore?.context?.ucsContext?.ucsUuid;
        return (
            <div className="ucs-manage-container">
                <div className="ucs-header">
                    <span>当前UCS: </span>
                    <span>{graphicStore?.context?.ucsContext?.currentUcs?.name}</span>
                </div>
                <div className="ucs-body">
                    <div className="axis-panel">
                        {graphicStore?.context?.ucsContext?.ucsList?.sort((a, b) => (b.isWcs ? 1 : 0) - (a.isWcs ? 1 : 0))?.map((ucs: AxisT) => (
                            <div key={ucs.uuid} className={classnames({
                                "ucs-item": true,
                                'active': ucs.uuid === graphicStore?.context?.ucsContext?.ucsUuid,
                                'selected': ucs.uuid === selected,
                            })} onClick={() => {
                                this.setState({
                                    selectUuid: ucs.uuid
                                })
                            }}>
                                <div className="selected-arrow">
                                    <CaretRightOutlined />
                                </div>
                                <div className="ucs-item-icon">
                                    <Icon component={ucs.isWcs ? WordSvg : AxisSvg} />
                                </div>
                                <div className="ucs-item-name">
                                    {this.state.renamingUuid === ucs.uuid && (
                                        <Input
                                            size="small"
                                            // bordered={false} 
                                            style={{ backgroundColor: '#fff' }}
                                            defaultValue={ucs.name}
                                            onBlur={(e) => this.changeUcsName(ucs, e.target.value)}
                                            //@ts-ignore
                                            onPressEnter={(e) => this.changeUcsName(ucs, e.target.value)} />
                                    )}
                                    {this.state.renamingUuid !== ucs.uuid && (ucs.name)}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="btn-panel">
                        <Space direction="vertical" size="small">
                            <Button size="small" style={{ width: '80px' }} onClick={this.setCurrentUcs}>置为当前</Button>
                            <Button size="small" style={{ width: '80px' }} onClick={this.activeUcsRenaming}>重命名</Button>
                            <Button size="small" danger style={{ width: '80px' }} onClick={this.ondelete}>删除</Button>
                        </Space>
                    </div>
                </div>
            </div>
        )
    }
}
