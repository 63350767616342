import { LayerType } from '@/commons/enums/layer-type';
import { graphicStore } from '@/commons/store/graphic-store';
// import { SettingOutlined } from '@ant-design/icons';

import { ReactComponent as DianSvg } from '@/commons/icons/new/dian.svg';
import { ReactComponent as XianSvg } from '@/commons/icons/new/xian.svg';
import { ReactComponent as DuoyixianSvg } from '@/commons/icons/new/duoyixian.svg';
import { ReactComponent as YuanSvg } from '@/commons/icons/new/yuan.svg';
import { ReactComponent as TuoyuanSvg } from '@/commons/icons/new/tuoyuan.svg';
import { ReactComponent as DuobianxingSvg } from '@/commons/icons/new/duobianxing.svg';
import { ReactComponent as HuSvg } from '@/commons/icons/new/hu.svg';
import { ReactComponent as JvxingSvg } from '@/commons/icons/new/jvxing.svg';
import { ReactComponent as MianyuSvg } from '@/commons/icons/new/mianyu.svg';
import { ReactComponent as LifangtiSvg } from '@/commons/icons/new/lifangti.svg';
import { ReactComponent as QiutiSvg } from '@/commons/icons/new/qiuti.svg';
import { ReactComponent as XuanzhuantiSvg } from '@/commons/icons/new/xuanzhuanti.svg';
import { ReactComponent as LashentiSvg } from '@/commons/icons/new/lashenti.svg';


export default function GeometryMenu() {

    const onActiveSubControl = (cmd: string) => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch(cmd);
    }

    const onActiveLine = () => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        let cur = graphicStore.context.layerContext.layers?.find(item => item.uuid === graphicStore.context.layerContext.currentLayerUuid);
        if (cur?.type === LayerType.AXIS_NET) {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("BUILDGRIDLINE");
        } else {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("LINE");
        }
    }

    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-row-area'>
                    <div className='ope-btn'>
                        <div className='ope-name'>创建</div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onActiveSubControl('POINT') }}>
                                <div className='icon'><DianSvg /></div>
                                <div className='name'>点</div>
                            </div>
                            <div className='ope-item' onClick={() => { onActiveLine() }}>
                                <div className='icon'><XianSvg /></div>
                                <div className='name'>直线</div>
                            </div>

                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onActiveSubControl('PLINE') }}>
                                <div className='icon'><DuoyixianSvg /></div>
                                <div className='name'>多义线</div>
                            </div>
                            <div className='ope-item' onClick={() => { onActiveSubControl('CIRCLE') }}>
                                <div className='icon'><YuanSvg /></div>
                                <div className='name'>圆</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onActiveSubControl('ELLIPSE') }}>
                                <div className='icon'><TuoyuanSvg /></div>
                                <div className='name'>椭圆</div>
                            </div>
                            <div className='ope-item' onClick={() => { onActiveSubControl('POLYGON') }}>
                                <div className='icon'><DuobianxingSvg /></div>
                                <div className='name'>多边形</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onActiveSubControl('ARC') }}>
                                <div className='icon'><HuSvg /></div>
                                <div className='name'>弧</div>
                            </div>
                            <div className='ope-item' onClick={() => { onActiveSubControl('RECTANG') }}>
                                <div className='icon'><JvxingSvg /></div>
                                <div className='name'>矩形</div>
                            </div>

                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onActiveSubControl('REGION') }}>
                                <div className='icon'><MianyuSvg /></div>
                                <div className='name'>面域</div>
                            </div>
                            <div className='ope-item' onClick={() => { onActiveSubControl('BOX') }}>
                                <div className='icon'><LifangtiSvg /></div>
                                <div className='name'>长方体</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onActiveSubControl('SPHERE') }}>
                                <div className='icon'><QiutiSvg /></div>
                                <div className='name'>球体</div>
                            </div>
                            <div className='ope-item' onClick={() => { onActiveSubControl('REVOLVE') }}>
                                <div className='icon'><XuanzhuantiSvg /></div>
                                <div className='name'>旋转体</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onActiveSubControl('EXTRUDE') }}>
                                <div className='icon'><LashentiSvg /></div>
                                <div className='name'>拉伸体</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}