export const SIDE_TYPE = {
    /**
     * 地面
     */
    ground: "ground",
    road_network: "road-network",
    road: "road",

    axis_net: "axis-net",
    fence: "fence",
    hole: "hole",
    /**
     * 楼板
     */
    floor: "floor",
    /**
     * 楼板
     */
    ceiling: "ceiling",
    wall: "wall",

    column: "column",


    elevator_car: "elevator-car",
    door: "door",
    window: "window",

    texture: "texture",
    room: "room",
    fire_control_room: "fire_control_room",
    pump_room: "pump_room",
    elevator: "elevator",

    parking_space: "parking-space",

    no_motor_parking_space: "no-motor-parking-space",    
    smoke_detector: "smoke-detector",
    fire_extinguisher: "fire-extinguisher",
    fire_hydrant: "fire-hydrant",
    spray_head: "spray-head",

    /**
     * 楼层
     */
    storey: "storey",
    /**
     * 巡更点
     */
    patrol_point: "patrol-point",


    tree: "tree",
}