import { TextureUploadUrl, deleteTexture, getTextureUrl, getTextures } from "@/api/spec-common/texture";
import SideBarTool from "@/commons/components/side-bar-tool";
import { TEXTURE_CATEGORY, getCategoryName } from "@/commons/enums/texture";
import { Texture } from "@/commons/interface/texture";
import { graphicStore } from "@/commons/store/graphic-store";
import { Input, Select, message, Upload } from "antd";
import { ReactComponent as UploadSvg } from '@/commons/icons/side/groud-panel/upload.svg'
import { ReactComponent as DefaultImageSvg } from '@/commons/icons/panel-icon/default-image.svg';
import { ReactComponent as HouseSvg } from '@/commons/icons/panel-icon/house.svg';
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../commons/styles/side-bar.less"
import "./index.less"
import classNames from "classnames";
import { BIM_BASE_CTRL_TYPE, BIM_SINGAL } from "tnbimbase";
import { ContextMenuFC } from "tncet-common";



const IMAGE_SIZE = {
    WIDTH: 1,
    HEIGHT: 2
}

export default function TexturePanel() {

    const [textureListMap, setTextureListMap] = useState<{ [key: number]: Texture[] }>({})

    const [selectTextureTypeKey, setSelectTextureTypeKey] = useState<string>("")

    const [selectTextureUuid, setSelectTextureUuid] = useState<string>("")

    const [deleteUuid, setDeleteUuid] = useState<string>("")

    const { projectId } = useParams()

    const [cmOpen, setCmOpen] = useState<boolean>(false); // 是否展开右键菜单
    const [top, setTop] = useState<number>();
    const [left, setLeft] = useState<number>();


    
    const [imageWidth, setImageWidth] = useState<string>("300")
    const [imageHeight, setImageHeight] = useState<string>("300")

    const onInputChange = (e, index) => {
        let value = e.target.value
        // const check = /^\d*\.?\d*$/
        const check = /^\d*\.?\d*$/
        if (check.test(value)) {
            if (index == IMAGE_SIZE.WIDTH) {
                setImageWidth(value)
            } else if (index == IMAGE_SIZE.HEIGHT) {
                setImageHeight(value)
            } 
        }
    }

    useEffect(() => {
        generateData()
    }, [])

    const beforeUpload = (file) => {
        return true;
    }

    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array?.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    }

    const handleChange = (info) => {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
            generateData(true);
        }
        if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const generateData = (needUpdateMap: boolean = false) => {
        getTextures(projectId).then(res => {
            let myMap: { [key: number]: Texture[] } = {}
            let data: Texture[] = res.data || []

            if (needUpdateMap) {
                let textures: Texture[] = res.data || [];
                textures.forEach(texture => {
                    if (texture && texture.uuid && texture.url) {
                        graphicStore.extraContext.materialContext.materialUrlMap[texture.uuid] = getTextureUrl(texture.url);
                    }
                })
            }

            Object.keys(TEXTURE_CATEGORY).forEach(item => {
                myMap[TEXTURE_CATEGORY[item].key] = []
            })

            for (let i = 0; i < data.length; i++) {
                let midUrl = getTextureUrl(data[i].url)
                data[i].url = midUrl
                if (myMap[data[i].category]) {
                    myMap[data[i].category].push(data[i])
                } else {
                    myMap[data[i].category] = []
                    myMap[data[i].category].push(data[i])
                }
            }
            setTextureListMap(myMap)
            if (!(selectTextureTypeKey?.length > 0)) {
                setSelectTextureTypeKey(data[0]?.category?.toString() || "")
            }
            if (needUpdateMap) {
                message.success(`上传成功!`);
            }
        })
    }

    const onContextClick = (e, uuid) => {
        setDeleteUuid(uuid)
        setCmOpen(true)
        setLeft(e.clientX)
        setTop(e.clientY)

    }

    const onClickDelete = () => {
        if (selectTextureUuid == deleteUuid) {
            setSelectTextureUuid("")
            graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
        }

        deleteTexture(deleteUuid).then(res => {
            generateData()
        }).catch(e => {
            message.error("删除失败！")
        }).finally(() => {
            setCmOpen(false)
        })

    }

    const onActiveSubControl = (textureUuid: string) => {
        setSelectTextureUuid(textureUuid)
        if (textureUuid?.length > 0) {
            if (!graphicStore.extraContext.getCurrentViewEditor()) return;
            const editor = graphicStore.extraContext.getCurrentViewEditor();
            if (editor.cmdControl.currentControl) {
                //如果当前控制器已被激活，则直接发送信号
                if (editor.cmdControl.currentControl?.id == BIM_BASE_CTRL_TYPE.SET_MATERIAL) {
                    graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.initMaterialControl).dispatch(textureUuid, +imageWidth, +imageHeight)
                } else {
                    graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
                    graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onOpeCommandActive.dispatch("MATERIAL");
                    graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.initMaterialControl).dispatch(textureUuid, +imageWidth, +imageHeight)
                }
            } else {
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onOpeCommandActive.dispatch("MATERIAL");
                graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.initMaterialControl).dispatch(textureUuid, +imageWidth, +imageHeight)
            }
        }
    }


    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    材质
                </div>
                <div className="top">
                    <div className="show-image">
                        {(selectTextureUuid?.length > 0 && selectTextureTypeKey?.length > 0) ? (
                            <img src={(textureListMap[selectTextureTypeKey]).find(item => item.uuid == selectTextureUuid).url} width={66} height={66} />
                        ) : (
                            <DefaultImageSvg />
                        )}
                    </div>
                    <div className="introduce">
                        {(textureListMap[selectTextureTypeKey])?.find(item => item.uuid == selectTextureUuid)?.textureName || "描述"}
                    </div>
                </div>

                <div className="buttom-title">
                    <div className="name">材质库</div>
                    <HouseSvg className="house-svg" />
                    <Select
                        className="select"
                        value={getCategoryName(+selectTextureTypeKey)}
                        onChange={(e) => {
                            graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
                            setSelectTextureTypeKey(e)
                            setSelectTextureUuid("")
                        }}
                    >
                        {Object.entries(textureListMap).map(([key, texture]) => (
                            <Select.Option value={key} key={key} label={getCategoryName(+key)}>
                                <div>
                                    {getCategoryName(+key)}
                                </div>
                            </Select.Option>
                        ))}
                    </Select>

                    <Upload
                        className="upload-btn"
                        name='file'
                        data={{ 'projectUuid': projectId, 'category': selectTextureTypeKey, 'type': 1 }}
                        showUploadList={false}
                        action={TextureUploadUrl}
                        onChange={handleChange}
                        beforeUpload={beforeUpload}
                    >
                        <UploadSvg />
                    </Upload>
                </div>
                <div className="size-input-container">
                    <div className="name-1">
                        宽度尺寸:
                    </div>
                    <Input
                        value={imageWidth}
                        className="input-1"
                        onChange={(e) => { onInputChange(e, IMAGE_SIZE.WIDTH) }}
                    />
                    <div className="name-2">
                        高度尺寸：
                    </div>
                    <Input
                        value={imageHeight}
                        className="input-2"
                        onChange={(e) => { onInputChange(e, IMAGE_SIZE.HEIGHT) }}
                    />



                </div>


                <div className="image-list">
                    {chunkArray(textureListMap[selectTextureTypeKey], 3).map((item, key) => (
                        <div className="row">
                            {item.map((imageItem, key) => (
                                <div
                                    onContextMenu={(e) => { onContextClick(e, imageItem.uuid) }}
                                    onClick={() => { onActiveSubControl(imageItem.uuid) }}
                                    key={key}
                                    className={classNames({
                                        "row-item": true,
                                        "row-item-active": imageItem.uuid == selectTextureUuid
                                    })}
                                >
                                    <img src={imageItem.url} width={64} height={58} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <ContextMenuFC
                visible={cmOpen}
                top={top}
                left={left}
                width={72}
                wrapperClassname='texture-delete-menu'
                onCancel={() => { setCmOpen(false) }}
            >
                <div className="btn" onClick={() => { onClickDelete() }}>
                    删除材质
                </div>
            </ContextMenuFC>
        </div>
    )
}