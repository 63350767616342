/**
 * 巡更点
 */
import { Modal, message } from "antd";
import "./index.less";
import { DefaultStoreyUuid, GroupType } from "tnbimbase";
import { useContext, useEffect, useState } from "react";
import { graphicStore } from "@/commons/store/graphic-store";

import { ReactComponent as EditSvg } from './icons/edit.svg'
import { useParams } from "react-router-dom";
import SideBarTool from "@/commons/components/side-bar-tool";
import { StoreyStatus } from "@/commons/context/storey-store";
import { StoreyContext } from "@/App";
import { getStoreys } from "@/api/building/storey";
import { Storey } from "@/commons/interface/building/storey";
import classNames from "classnames";
import { BlockT, ModelBase, ViewEditor } from "pythagoras";
import { OrthographicCamera, Vector3 } from "three";
import { createPrimitiveMulti } from "@/api/geometry/primitive";

import { LoadingOutlined } from '@ant-design/icons';
import TnModal from "@/commons/widget/base-modal";

interface IProps {
    width: number
}

export function StoreyPanel(props: IProps) {

    const { projectId } = useParams();
    const storeyContext = useContext<StoreyStatus>(StoreyContext);
    const [storeyList, setStoreyList] = useState<Storey[]>([]);
    const [editing, setEditing] = useState<boolean>(false);


    const [loading, setLoading] = useState<boolean>(false);

    const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set());



    const [selectedUuid, setSelectedUuid] = useState('');

    const generateData = () => {
        if (!projectId) {
            storeyContext.setStoreys([]);
            return;
        }
        getStoreys(projectId).then(res => {
            //为保持全局一致性，不直接从storeyContext中拿，每次都查询后更新
            if (res.status === 200) {
                let data: Storey[] = res.data || [];

                storeyContext.setStoreys(data);
                //按照楼层标高从高到低排序
                data.sort((a, b) => {
                    return - a.bottomElevation + b.bottomElevation;
                });
                setStoreyList(data);
            }
        }).catch(err => {
            console.log('err', err)
        })
    }

    useEffect(() => {
        generateData();
    }, [projectId])


    /**
     * 选中某个图元
     * @param editor 
     * @param selectedEntities 
     * @param entity 
     */
    const _selectEntity = (editor: ViewEditor, selectedEntities: ModelBase[], entity) => {
        selectedEntities.push(entity);
        editor.entityDict[entity.uuid].isSelected = true;
        editor.selectControl.getSelectedEntityList()[entity.uuid] = editor.entityDict[entity.uuid]
    }


    /**
     * 显示所有图元
     * @param model 
     * @returns 
     */
    const showEntity = (models: ModelBase[]) => {
        if (!models || models.length == 0) {
            return;
        }

        let x1 = +Infinity
        let y1 = +Infinity
        let x2 = -Infinity
        let y2 = -Infinity

        models.forEach(model => {
            let box = model.getBoundingBox()
            box.min.project(graphicStore.extraContext.getCurrentViewEditor().camera);
            box.max.project(graphicStore.extraContext.getCurrentViewEditor().camera);
            x1 = Math.min(box.min.x, x1);
            y1 = Math.min(box.min.y, y1);
            x2 = Math.max(box.max.x, x2);
            y2 = Math.max(box.max.y, y2);
        })

        if (x1 == null || y1 == null || x2 == null || y2 == null) {
            return
        }

        let scale1 = Math.abs(y1 - y2) / 2;

        let scale2 = Math.abs(x1 - x2) / 2;
        // max
        let scale = Math.max(scale1, scale2) * 1.3;
        let x = (x1 + x2) / 2;
        let y = (y1 + y2) / 2;
        let camera = graphicStore.extraContext.getCurrentViewEditor().camera;
        let target = graphicStore.context.ucsContext.unprojectCursorPoint(x, y, camera);

        let lastTarget = graphicStore.extraContext.getCurrentViewEditor().viewControl.target.clone();
        let offset = camera.position.clone().sub(lastTarget);
        const position = camera.position;
        graphicStore.extraContext.getCurrentViewEditor().viewControl.target.copy(target);
        position.copy(target).add(offset);
        graphicStore.extraContext.getCurrentViewEditor().camera.lookAt(target);
        // scale 框和当前边界的比例
        graphicStore.context.renderContext.cameraEdge *= scale;
        if (camera.type === "OrthographicCamera") {
            let orthoCamera = camera as OrthographicCamera;
            orthoCamera.left = orthoCamera.left * scale;
            orthoCamera.right = orthoCamera.right * scale;
            orthoCamera.top = orthoCamera.top * scale;
            orthoCamera.bottom = orthoCamera.bottom * scale;
            orthoCamera.updateProjectionMatrix();
        }


        graphicStore.extraContext.listeners.signals.onZoomChanged.dispatch(1 / scale);
    }



    const onEditiingClick = (storeyUuid: string, index: number) => {
        setLoading(true);
        message.info("选中或取消选中以更新楼层编组");
        setSelectedUuid(storeyUuid);
        const editor = graphicStore.extraContext.getCurrentViewEditor();
        if (!editor) {
            message.error("图形编辑器未激活");
            setLoading(false);
            return;
        }
        //清空选择集
        editor.listeners.signals.emptySelectedEntities.dispatch();
        let entitiesInCurrentStorey: ModelBase[] = [];
        Object.keys(editor.entityDict).forEach(uuid => {
            let entity = editor.entityDict[uuid];
            if (!entity) return;
            //如果该图有编组属性，并且楼层编组属性存在，并且楼层编组数组的长度大于0
            if (entity.group && entity.group[GroupType.STOREY] && entity.group[GroupType.STOREY].length > 0) {
                //如果图元的楼层编组uuid为当前uuid，则加入到数组中
                if (entity.group[GroupType.STOREY][0] === storeyUuid) {
                    _selectEntity(editor, entitiesInCurrentStorey, entity);
                    //如果图元的楼层编组为动态指定，则计算其层高，将层高符合条件的图元全部加入
                } else if (entity.group[GroupType.STOREY][0] === DefaultStoreyUuid.DYNAMIC) {
                    //如果图元为块，直接用position判断高度，减少对于中心点的计算
                    if (entity instanceof BlockT) {
                        let block = entity as BlockT;
                        const z = block.position.z / 1000; //毫米与米换算
                        if (index === 0) {
                            if (z >= storeyList[index].bottomElevation) {
                                _selectEntity(editor, entitiesInCurrentStorey, entity);
                            }
                        } else if (index === storeyList.length - 1) {
                            if (z < storeyList[index - 1].bottomElevation) {
                                _selectEntity(editor, entitiesInCurrentStorey, entity);
                            }
                        } else {
                            if (z < storeyList[index - 1].bottomElevation && z >= storeyList[index].bottomElevation) {
                                _selectEntity(editor, entitiesInCurrentStorey, entity);
                            }
                        }
                    } else {
                        let vec = new Vector3();
                        entity.getCenter(vec)
                        const z = vec.z / 1000; //毫米与米换算
                        if (index === 0) {
                            if (z >= storeyList[index].bottomElevation) {
                                _selectEntity(editor, entitiesInCurrentStorey, entity);
                            }
                        } else if (index === storeyList.length - 1) {
                            if (z < storeyList[index - 1].bottomElevation) {
                                _selectEntity(editor, entitiesInCurrentStorey, entity);
                            }
                        } else {
                            if (z < storeyList[index - 1].bottomElevation && z >= storeyList[index].bottomElevation) {
                                _selectEntity(editor, entitiesInCurrentStorey, entity);
                            }
                        }
                    }
                }
            }
        });
        showEntity(entitiesInCurrentStorey);
        let uuids = entitiesInCurrentStorey.map(entity => entity.uuid);
        setSelectedSet(new Set(uuids));
        setEditing(true);
        editor.listeners.signals.needRender.dispatch("redraw");
        setLoading(false);
    }

    const onSave = () => {
        setLoading(true);
        if (!editing) {
            message.error("当前未处于编辑状态");
            setLoading(false);
            return;
        }
        if (!selectedUuid || selectedUuid.length === 0) {
            message.error("未选中楼层，请选中后再保存");
            setLoading(false);
            setEditing(false);
            return;
        }
        const editor = graphicStore.extraContext.getCurrentViewEditor();
        if (!editor) {
            message.error("图形编辑器未激活");
            setLoading(false);
            setEditing(false);
            return;
        }
        let entities: ModelBase[] = editor.selectControl.getSelectedEntityList();
        let tmpSelectSet = new Set(entities.map(entity => entity.uuid));
        if (entities.length === 0) {
            TnModal().baseModal({
                title: "确认",
                content: "当前选中图元数为0，确认按钮会清空当前楼层编组",
                onOk: () => {
                    Object.keys(editor.entityDict).forEach(uuid => {
                        let entity = editor.entityDict[uuid];
                        if (entity && selectedSet.has(entity.uuid)) {
                            //之前被选中的但是现在没有被选中的，删除编组信息
                            if (entity.group && entity.group[GroupType.STOREY]) {
                                delete entity.group[GroupType.STOREY];
                                entities.push(entity);
                            }
                        }
                    })
                    createPrimitiveMulti(entities.map(entity => entity.toJson())).then(res => {
                        if (res.status === 200) {
                            message.success("楼层编组成功");
                        }
                    }).finally(() => {
                        setLoading(false);
                        setEditing(false);
                        Modal.destroyAll();
                    });




                },
                onCancel: () => {
                    editor.listeners.signals.emptySelectedEntities.dispatch();
                    message.info("取消楼层编组操作");
                    setLoading(false);
                    setEditing(false);
                    Modal.destroyAll();
                }
            })
            return;
        } else {
            entities.forEach(entity => {
                if (!entity.group) {
                    entity.group = {};
                }
                entity.group[GroupType.STOREY] = [selectedUuid];
            });
            Object.keys(editor.entityDict).forEach(uuid => {
                let entity = editor.entityDict[uuid];
                if (entity && selectedSet.has(entity.uuid) && !tmpSelectSet.has(entity.uuid)) {
                    //之前被选中的但是现在没有被选中的，删除编组信息
                    if (entity.group && entity.group[GroupType.STOREY]) {
                        delete entity.group[GroupType.STOREY];
                        entities.push(entity);
                    }
                }
            })
            createPrimitiveMulti(entities.map(entity => entity.toJson())).then(res => {
                if (res.status === 200) {
                    message.success("楼层编组成功");
                }
            }).finally(() => {
                setLoading(false);
                setEditing(false);
            });

        }
    }

    const onCancel = () => {
        const editor = graphicStore.extraContext.getCurrentViewEditor();
        if (!editor) {
            message.warning("图形编辑器未激活");
            return;
        }
        editor.listeners.signals.emptySelectedEntities.dispatch();
        message.info("取消楼层编组操作");


    }




    return (
        // <div>123</div>
        <div className="side-bar-main storey-panel">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    楼层
                </div>
                <div className="head">
                    楼层列表
                </div>


                <div className="detail">
                    {storeyList.map((storey, index) => {
                        return (
                            <div className={classNames({
                                "storey-item": true,
                                "selected": storey.uuid === selectedUuid

                            })} key={index}>
                                <span>{storey.name}</span>
                                <div className="right-svg" onClick={() => { onEditiingClick(storey.uuid, index) }}>
                                    {loading ? <LoadingOutlined className="svg" /> : <EditSvg className="svg" />}
                                </div>
                            </div>
                        )
                    })}
                    {!loading &&
                        <div className="bottom-panel">
                            <div className="btn save" onClick={onSave}>确定</div>
                            <div className="btn cancel" onClick={onCancel}>取消</div>
                        </div>
                    }
                    {loading &&
                        <div className="bottom-panel">
                            <LoadingOutlined />
                        </div>
                    }



                </div>
            </div>
        </div >
    )

}