import { graphicStore } from "@/commons/store/graphic-store";
import { ModelBase } from "pythagoras";
import { useEffect, useRef } from "react";
import { AmbientLight, OrthographicCamera, Quaternion, Scene, Spherical, Vector2, Vector3, WebGLRenderer } from "three";

interface IProps {
    entities: ModelBase[],
    react_id: string,
    width?: number,
    height?: number
    color?: string;
    top?: number;
    left?: number;
    // showMesh?: boolean;
    onlyRenderType?: number; // 只展示该renderType
}

//通用组件  该组件可以添加若干图元，然后对含有这些图元的视图进行缩放平移旋转操作
export default function LocalScene(props: IProps) {
    //旋转中心
    const rotateCenter = useRef<Vector3>(new Vector3());
    const zoomSpeed = 2.0
    const panSpeed = 1.0
    const rotateSpeed = 1.0

    //显示三维节点
    const renderer = useRef<WebGLRenderer>();
    const camera = useRef<OrthographicCamera>();
    const scene = useRef<Scene>();
    const show = useRef<HTMLElement>();
    const target = useRef<Vector3>(new Vector3(0, 0, 0));

    //三维节点操作
    const enabledMove = useRef<boolean>(false);
    const enabledRotate = useRef<boolean>(false);
    const _pointerStart = useRef<Vector2>(new Vector2(0, 0));
    const _pointerEnd = useRef<Vector2>(new Vector2(0, 0));
    const _pointerDelta = useRef<Vector2>(new Vector2(0, 0));
    const _offset = useRef<Vector3>(new Vector3(0, 0, 0));
    const _panOffset = useRef<Vector3>(new Vector3(0, 0, 0));
    const _quat = useRef<Quaternion>();
    const _quatInverse = useRef<Quaternion>();
    const _spherical = useRef<Spherical>(new Spherical());


    //计算旋转中心
    const computeCenter = (entities: ModelBase[]) => {
        let center = new Vector3();
        let counter = 0;
        let tmp = new Vector3();
        for (let entity of entities) {
            if (entity == null) continue;
            entity.getCenter(tmp);
            center.add(tmp);
            counter++;
        }
        if (counter === 0) {
            return center;
        }
        center.x /= counter;
        center.y /= counter;
        center.z /= counter;
        return center;

    }

    //计算显示边界
    const computeEntityBoundingBox = (entities: ModelBase[]) => {
        if (entities.length === 0) {
            return {
                min: new Vector3(-100, -100, -100),
                max: new Vector3(100, 100, 100),
            }
        }
        let minX = Infinity;
        let minY = Infinity;
        let minZ = Infinity;

        let maxX = -Infinity;
        let maxY = -Infinity;
        let maxZ = -Infinity;
        entities.forEach(entity => {
            let boundingBox = entity.getBoundingBox();
            minX = minX < boundingBox.min.x ? minX : boundingBox.min.x;
            minY = minY < boundingBox.min.y ? minY : boundingBox.min.y;
            minZ = minZ < boundingBox.min.z ? minZ : boundingBox.min.z;
            maxX = maxX > boundingBox.max.x ? maxX : boundingBox.max.x;
            maxY = maxY > boundingBox.max.y ? maxY : boundingBox.max.y;
            maxZ = maxZ > boundingBox.max.z ? maxZ : boundingBox.max.z;
        });

        // TODO: points为空
        if (minX === maxX) {
            minX -= 100;
            maxX += 100;
        }
        if (minY === maxY) {
            minY -= 100;
            maxY += 100;
        }
        if (minZ === maxZ) {
            minZ -= 100;
            maxZ += 100;
        }
        let res = {
            min: new Vector3(minX, minY, minZ),
            max: new Vector3(maxX, maxY, maxZ),
        }
        return res;
    }

    useEffect(() => {
        //componentDidMount
        createRender();
        createCamera();
        createDomElement();
        createScene();
        showAllEntities(props.entities);
        renderer.current.render(scene.current, camera.current);
        render();

        return () => {//componentWillUnmount
            if (show.current) {
                const canvasElements = show.current.getElementsByTagName('canvas');
                if (canvasElements.length > 0) {
                    show.current.removeChild(canvasElements[0]);
                }
            }
        }
    }, [])

    //当传入的entities发生改变时调用
    useEffect(() => {
        //componentDidMount
        scene.current.clear();
        let oldValue = graphicStore.context.renderContext.renderType;
        if (props.onlyRenderType != null) {
            graphicStore.context.renderContext.renderType = props.onlyRenderType;
        }
        props.entities.forEach(entity => {
            entity.updateViewGeometry()
            scene.current.add(entity.render())
        }
        );
        rotateCenter.current = computeCenter(props.entities);
        showAllEntities(props.entities);
        renderer.current.render(scene.current, camera.current);
        graphicStore.context.renderContext.renderType = oldValue;
        return () => {//componentWillUnmount


        }
    }, [props.entities])

    //修改画布的高宽
    useEffect(() => {
        if (renderer.current) {
            let w = props.width ? props.width : 290
            let h = props.height ? props.height : 290
            renderer.current.setSize(w, h);

            let aspect = w / h;
            let c_h = 2000;
            let c_w = aspect * c_h;

            let layerCamera = camera.current
            layerCamera.left = -c_w / 2;
            layerCamera.right = c_w / 2;
            layerCamera.top = c_h / 2;
            layerCamera.bottom = -c_h / 2;
            layerCamera.updateProjectionMatrix()

        }
    }, [props.width, props.height])

    const showEntitiesInRect = (x1, y1, x2, y2, alpha = 1) => {
        let scale1 = Math.abs(y1 - y2) / 2;
        let scale2 = Math.abs(x1 - x2) / 2;
        // max
        let scale = Math.max(scale1, scale2) * alpha;
        let x = (x1 + x2) / 2;
        let y = (y1 + y2) / 2;
        let tmpCamera = camera.current;
        let tmpTarget = graphicStore.context.ucsContext.unprojectCursorPoint(x, y, tmpCamera);
        let lastTarget = target.current;
        let offset = tmpCamera.position.clone().sub(lastTarget);
        const position = tmpCamera.position;
        position.copy(tmpTarget).add(offset);
        camera.current.lookAt(tmpTarget)
        // scale 框和当前边界的比例
        tmpCamera.left = tmpCamera.left * scale;
        tmpCamera.right = tmpCamera.right * scale;
        tmpCamera.top = tmpCamera.top * scale;
        tmpCamera.bottom = tmpCamera.bottom * scale;
        tmpCamera.updateProjectionMatrix();
        target.current = tmpTarget;
    }

    const showAllEntities = (entities: ModelBase[]) => {
        try {
            let box = computeEntityBoundingBox(entities);
            let tmpCamera = camera.current;
            box.min.project(tmpCamera);
            box.max.project(tmpCamera);
            showEntitiesInRect(box.min.x, box.min.y, box.max.x, box.max.y, 1.3);
        } catch (error) {
            console.warn(error);
        }
    }

    const createDomElement = () => {
        show.current = document.getElementById(props.react_id);
        show.current.appendChild(renderer.current.domElement);
    }

    const createRender = () => {
        const showRenderer = new WebGLRenderer();
        showRenderer.setClearColor('#121212', 1.0);
        showRenderer.autoClear = false;
        showRenderer.setSize(props.width ? props.width : 290, props.height ? props.height : 290);
        renderer.current = showRenderer;
        renderer.current.domElement.setAttribute('tabindex', '-1');
        renderer.current.domElement.addEventListener('wheel', onMouseWheel);
        renderer.current.domElement.addEventListener('pointerdown', onPointerDown);
        renderer.current.domElement.addEventListener('pointermove', onPointerMove);
        renderer.current.domElement.addEventListener('pointerup', onPointerUp);
    }

    const createCamera = () => {
        const tmpCamera = new OrthographicCamera(-1000, 1000, 1000, -1000, -100000, 100000);
        tmpCamera.position.set(0, 0, 1000);
        tmpCamera.lookAt(0, 0, 0);
        camera.current = tmpCamera;
    }

    const createScene = () => {
        const tmpScene = new Scene();
        const ambient = new AmbientLight(0xffffff);
        tmpScene.add(ambient);
        scene.current = tmpScene;
        target.current = new Vector3(0, 0, 0);
        let oldValue = graphicStore.context.renderContext.renderType;
        if (props.onlyRenderType != null) {
            graphicStore.context.renderContext.renderType = props.onlyRenderType;
        }
        props.entities.forEach(entity => {
            scene.current.add(entity.render())
        }
        );
        renderer.current.render(scene.current, camera.current);
        graphicStore.context.renderContext.renderType = oldValue;
    }

    const render = () => {
        requestAnimationFrame(render);
        renderer.current.render(scene.current, camera.current);
    }

    const updateTarget = (v: Vector3) => {
        const position = camera.current.position;
        let offset = position.clone().sub(target.current);
        target.current.set(v.x, v.y, v.z);
        position.copy(target.current).add(offset);
        camera.current.updateMatrixWorld();
        camera.current.updateProjectionMatrix();
    }

    const onMouseWheel = (event) => {
        //缩放
        event.preventDefault()
        let zoomScale = Math.round(Math.pow(0.92, zoomSpeed) * 100) / 100;
        if (event.deltaY > 0) {
            zoomScale = 1 / zoomScale;
        }
        camera.current.left = camera.current.left * zoomScale;
        camera.current.right = camera.current.right * zoomScale;
        camera.current.top = camera.current.top * zoomScale;
        camera.current.bottom = camera.current.bottom * zoomScale;
        camera.current.updateProjectionMatrix();
    }

    const onPointerDown = (event) => {
        enabledMove.current = true;
        if (event.shiftKey) {
            enabledRotate.current = true;
        } else {
            enabledRotate.current = false;
        }
        _pointerStart.current.set(event.offsetX, event.offsetY)
    }

    const onPointerUp = (event) => {
        enabledMove.current = false;
        enabledRotate.current = false;
    }

    const onPointerMove = (event) => {
        _pointerEnd.current.set(event.offsetX, event.offsetY);
        _pointerDelta.current.subVectors(_pointerEnd.current, _pointerStart.current);
        _pointerStart.current.set(event.offsetX, event.offsetY);
        if (enabledRotate.current && enabledMove.current) {
            //旋转
            camera.current.up.set(0, 0, 1);
            _quat.current = new Quaternion().setFromUnitVectors(camera.current.up, new Vector3(0, 1, 0));
            _quatInverse.current = _quat.current.clone().invert();
            let domElement = renderer.current.domElement;
            let theta_delta = -2 * Math.PI * _pointerDelta.current.x * rotateSpeed / domElement.clientHeight;
            let phi_delta = -2 * Math.PI * _pointerDelta.current.y * rotateSpeed / domElement.clientHeight;

            let spherical = _spherical.current;
            const position = camera.current.position;
            let _target = target.current.clone();

            updateTarget(rotateCenter.current);

            // 3d-vector: offset = camera position - target position
            let offset = _offset.current;
            offset.copy(position).sub(target.current);
            // let lastOffset = offset.clone();
            let rotateAxis1 = camera.current.up.clone().normalize();
            let rotateAxis2 = camera.current.up.clone().cross(offset).normalize();

            // rotate offset to "y-axis-is-up" space
            offset.applyQuaternion(_quat.current);

            // transfer offset vector to spherical coords
            spherical.setFromVector3(offset);
            let last_theta = spherical.theta
            let last_phi = spherical.phi
            // let lastSpherical = spherical.clone();

            // add delta to spherical offset vector
            spherical.theta += theta_delta;
            spherical.phi += phi_delta;


            // restrict phi to be between desired limits
            spherical.makeSafe();
            theta_delta = spherical.theta - last_theta
            phi_delta = spherical.phi - last_phi

            // transfer offset back to cartesian coords
            offset.setFromSpherical(spherical);
            // rotate offset back to "camera-up-vector-is-up" space
            offset.applyQuaternion(_quatInverse.current);
            position.copy(target.current).add(offset);
            camera.current.lookAt(target.current);
            _pointerStart.current.set(event.offsetX, event.offsetY);

            let _oq = new Quaternion().setFromAxisAngle(rotateAxis1, theta_delta).multiply(new Quaternion().setFromAxisAngle(rotateAxis2, phi_delta));
            let v1 = _target.clone().sub(rotateCenter.current);
            v1.applyQuaternion(_oq);
            let new_target = rotateCenter.current.clone().add(v1);
            updateTarget(new_target);
        } else if (enabledMove.current) {
            //平移
            let domElement = renderer.current.domElement;

            let deltaX = _pointerDelta.current.x * panSpeed;
            let deltaY = _pointerDelta.current.y * panSpeed;

            const element = domElement;
            const position = camera.current.position;
            const cameraMatrix = camera.current.matrix;
            const distanceX = deltaX * (camera.current.right - camera.current.left) / camera.current.zoom / element.clientWidth;
            const distanceY = deltaY * (camera.current.top - camera.current.bottom) / camera.current.zoom / element.clientHeight;


            let offset = _offset.current;
            offset.copy(position).sub(target.current);

            // pan left
            let v = new Vector3();
            v.setFromMatrixColumn(cameraMatrix, 0);
            v.multiplyScalar(-distanceX)
            _panOffset.current.add(v);

            // pan up
            v.setFromMatrixColumn(cameraMatrix, 1);
            v.multiplyScalar(distanceY);
            _panOffset.current.add(v);

            target.current.add(_panOffset.current);
            position.copy(target.current).add(offset);
            camera.current.updateMatrixWorld();
            camera.current.updateProjectionMatrix();
            _panOffset.current.set(0, 0, 0);
            _pointerStart.current.set(event.offsetX, event.offsetY);
        }
    }

    return (
        <div id={props.react_id}
            className={props.react_id}
            style={{
                width: `${props.width ? props.width : 290}px`,
                height: `${props.height ? props.height : 290}px`,
                position: 'relative', backgroundColor: `${props.color ? props.color : '#212830'}`,
                top: `${props.top ? props.top : -5}px`,
                left: `${props.left ? props.left : -10}px`
            }}>
        </div>
    )
}