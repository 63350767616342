import { ComponentType } from '@/commons/enums/component-type';
import { ImportComponent } from '@/commons/components/import-component/import-component';
import { useEffect, useState } from 'react';
import { getComponents } from '@/api/spec-common/component';
import "../../commons/styles/side-bar.less"
import SideBarTool from '@/commons/components/side-bar-tool';
import { Component } from '@/commons/interface/speccommon/component';
import { ReactComponent as AssembleBlackSvg } from "@/commons/icons/panel-icon/assemble-black.svg";
import { ReactComponent as UploadBlackSvg } from "@/commons/icons/panel-icon/upload-black.svg";
import { graphicStore } from '@/commons/store/graphic-store';
import { Input, Select, message } from 'antd';
import { EXTRA_CTRL_TYPE } from '../../commons/enums/extra-control-code';
import { EXTRA_SINGAL } from '@/commons/enums/extra-singal';
import { ComponentT } from 'tnbimbase';
import { ModelBase } from 'pythagoras';
import LocalScene from '@/commons/components/local-scene';
import RadioGroup from '@/commons/components/radio-group';
import { useParams } from 'react-router-dom';

export default function FencePanel() {
    const { projectId } = useParams();
    const [uprightList, setUprightList] = useState<Component[]>([]); // 立柱组件列表
    const [solidWallList, setSolidWallList] = useState<Component[]>([]); // 实墙组件列表
    const [banisterList, setBanisterList] = useState<Component[]>([]); // 栏杆组件列表

    const [selectedUprightUuid, setSelectedUprightUuid] = useState<String>(); // 被选中的立柱组件
    const [selectedSolidWallUuid, setSelectedSolidWallUuid] = useState<String>(); // 被选中的实墙组件
    const [selectedBanisterUuid, setSelectedBanisterUuid] = useState<String>(); // 被选中的栏杆组件
    const [space, setSpace] = useState<number>(); // 立柱间隔
    const [assembleWay, setAssembleWay] = useState<string>('line'); // 布置方式

    useEffect(() => {
        // 获得围墙立柱组件
        getUprightList();
        // 获得围墙实墙组件
        getSolidWallList();
        // 获得围墙栅栏组件
        getBanisterList()
    }, [])

    const onInsertComponent = () => {
        if (!selectedUprightUuid && !selectedSolidWallUuid && !selectedBanisterUuid) {
            // 三个组件皆未选，无法布置
            message.warning("请选择组件");
            return;
        }
        let selectedUpright = selectedUprightUuid != null ? uprightList.find(item => item.uuid == selectedUprightUuid) : null;
        let selectedSolidWall = selectedSolidWallUuid != null ? solidWallList.find(item => item.uuid == selectedSolidWallUuid) : null;
        let selectedBanister = selectedBanisterUuid != null ? banisterList.find(item => item.uuid == selectedBanisterUuid) : null;
        if (!selectedUpright && !selectedSolidWall && !selectedBanister) {
            // 三个组件皆未选，无法布置
            message.warning("请选择组件");
            return;
        }
        if (selectedUpright && !!!space) {
            message.warning("请输入立柱间隔");
            return;
        }
        let upright = selectedUpright != null ? new ComponentT(ComponentType.FENCEUPRIGHT).fromJson(selectedUpright) : null;
        let solidWall = selectedSolidWall != null ? new ComponentT(ComponentType.FENCESOLIDWALL).fromJson(selectedSolidWall) : null;
        let banister = selectedBanister != null ? new ComponentT(ComponentType.FENCEBANISTER).fromJson(selectedBanister) : null;
        if (!graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl || graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl.id !== EXTRA_CTRL_TYPE.ASSEMBLE_FENCE) {
            // 激活插入组件的二级控制器
            graphicStore.extraContext.getCurrentViewEditor().cmdControl.activateSubCommandControl("ASSEMBLEFENCE");
        }
        // 毫米单位的间隔
        let spaceMill = !!space ? space * 1000 : null;
        if (assembleWay == 'line') {
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleFenceByLine).dispatch(upright, spaceMill, solidWall, banister);
        } else if (assembleWay == 'point') {
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleFenceByPoint).dispatch(upright, spaceMill, solidWall, banister);
        }
    }

    const getUprightList = () => {
        // 获得围墙立柱组件
        getComponents({ projectUuid: projectId, type: ComponentType.FENCEUPRIGHT }).then(res => {
            let list: Component[] = res.data || [];
            list.forEach(item => {
                let entities = item.entities.map((item: any) => {
                    let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(item.type))();
                    entity.fromJson(item);
                    return entity;
                });
                item.primitives = entities;
            })
            setUprightList(list);
        })
    }
    const getSolidWallList = () => {
        getComponents({ projectUuid: projectId, type: ComponentType.FENCESOLIDWALL }).then(res => {
            let list: Component[] = res.data || [];
            list.forEach(item => {
                let entities = item.entities.map((item: any) => {
                    let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(item.type))();
                    entity.fromJson(item);
                    return entity;
                });
                item.primitives = entities;
            })
            setSolidWallList(list);
        })
    }
    const getBanisterList = () => {
        getComponents({ projectUuid: projectId, type: ComponentType.FENCEBANISTER }).then(res => {
            let list: Component[] = res.data || [];
            list.forEach(item => {
                let entities = item.entities.map((item: any) => {
                    let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(item.type))();
                    entity.fromJson(item);
                    return entity;
                });
                item.primitives = entities;
            })
            setBanisterList(list);
        })
    }

    const onImportUpright = () => {
        let input = document.getElementById('import-upright');
        input.click();
    }
    const onImportSolidWall = () => {
        let input = document.getElementById('import-solid-wall');
        input.click();
    }
    const onImportBanister = () => {
        let input = document.getElementById('import-banister');
        input.click();
    }

    const importUprightSuccess = () => {
        // 刷新围墙立柱
        getUprightList();
    }

    const importSolidWallSuccess = () => {
        // 刷新围墙实墙
        getSolidWallList();
    }
    const importBanisterSuccess = () => {
        // 刷新围墙栏杆
        getBanisterList();
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    围墙
                </div>
                <div className="detail">
                    <div className="row">
                        <div className="label">立柱:</div>
                        <Select
                            size='small'
                            className="select-box"
                            value={selectedUprightUuid}
                            // allowClear
                            onChange={(value) => setSelectedUprightUuid(value)}
                            optionLabelProp='label'
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            {uprightList.map((item) => (
                                <Select.Option value={item.uuid} key={item.uuid} label={item.name}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}>
                                        <LocalScene
                                            left={1}
                                            top={1}
                                            color="#DAE3E5"
                                            height={48}
                                            width={48}
                                            entities={item.primitives}
                                            react_id={`upright-${item.uuid}`}
                                        />
                                        {item.name}
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                        <div className="upload-button" onClick={onImportUpright}><UploadBlackSvg /></div>
                        <input type="file" accept=".txt" id="import-upright" onChange={(e) => ImportComponent(e, projectId, ComponentType.FENCEUPRIGHT, null, importUprightSuccess)} style={{ display: 'none' }} />
                    </div>
                    {selectedUprightUuid && (
                        <div className="row">
                            <div className="label">间隔:</div>
                            <Input
                                className="input-box"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    if (!isNaN(parseInt(value))) {
                                        setSpace(parseInt(value))
                                    } else {
                                        if (value == '') {
                                            setSpace(null);
                                        } else {
                                            setSpace(0)
                                        }
                                    }
                                }}
                                suffix="m"
                            />
                        </div>
                    )}
                    <div className="row">
                        <div className="label">实墙:</div>
                        <Select
                            size='small'
                            className="select-box"
                            value={selectedSolidWallUuid}
                            // allowClear
                            onChange={(value) => setSelectedSolidWallUuid(value)}
                            optionLabelProp='label'
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            {solidWallList.map((item) => (
                                <Select.Option value={item.uuid} key={item.uuid} label={item.name}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}>
                                        <LocalScene
                                            left={1}
                                            top={1}
                                            color="#DAE3E5"
                                            height={48}
                                            width={48}
                                            entities={item.primitives}
                                            react_id={`solid-wall-${item.uuid}`}
                                        />
                                        {item.name}
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                        <div className="upload-button" onClick={onImportSolidWall}><UploadBlackSvg /></div>
                        <input type="file" accept=".txt" id="import-solid-wall" onChange={(e) => ImportComponent(e, projectId, ComponentType.FENCESOLIDWALL, null, importSolidWallSuccess)} style={{ display: 'none' }} />
                    </div>
                    <div className="row">
                        <div className="label">栏杆:</div>
                        <Select
                            size='small'
                            className="select-box"
                            value={selectedBanisterUuid}
                            // allowClear
                            onChange={(value) => setSelectedBanisterUuid(value)}
                            optionLabelProp='label'
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            {banisterList.map((item) => (
                                <Select.Option value={item.uuid} key={item.uuid} label={item.name}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}>
                                        <LocalScene
                                            left={1}
                                            top={1}
                                            color="#DAE3E5"
                                            height={48}
                                            width={48}
                                            entities={item.primitives}
                                            react_id={`banister-${item.uuid}`}
                                        />
                                        {item.name}
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                        <div className="upload-button" onClick={onImportBanister}><UploadBlackSvg /></div>
                        <input type="file" accept=".txt" id="import-banister" onChange={(e) => { console.log("1233245234 "); ImportComponent(e, projectId, ComponentType.FENCEBANISTER, null, importBanisterSuccess) }} style={{ display: 'none' }} />
                    </div>
                    <div className="row">
                        <div className="label">绘制方式:</div>
                        <RadioGroup
                            dataList={[
                                { label: '基准线', value: 'line' },
                                { label: '角点', value: 'point' },
                            ]}
                            selected={assembleWay}
                            onChange={(value) => setAssembleWay(value)}
                        />
                    </div>
                    <div className="ope-btn">
                        <div className="insert-button" onClick={onInsertComponent}>
                            <div className="icon"><AssembleBlackSvg className="icon-svg" /></div>
                            <div className="text">布置</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}