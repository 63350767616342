import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SideBarTool from "@/commons/components/side-bar-tool";
import { SideBarBtnType } from "@/commons/enums/side-bar-btn-type";
import { getStoreys } from "@/api/building/storey";
import { Storey } from "@/commons/interface/building/storey";
import { StoreyStatus } from "@/commons/context/storey-store";
import { StoreyContext } from "@/App";
import { getComponents } from "@/api/spec-common/component";
import { ComponentType } from "@/commons/enums/component-type";
import { ModelBase } from "pythagoras";
import { graphicStore } from "@/commons/store/graphic-store";
import { BUILDING_SINGAL, BUILDING_SUB_CONTROL, ComponentT, DefaultStoreyUuid, FireExtinguisherT, GroupType } from "tnbimbase";
import { onActiveSubControl } from "@/commons/utils/graphic-method";
import { Vector3 } from "three";
import { createPrimitive, deletePrimitivesInGraphic, findPrimitivesInGraphic } from "@/api/geometry/primitive";
import { extractFirstNumber } from "@/commons/utils/string-method";
import { Modal, message } from "antd";
import TnModal from "@/commons/widget/base-modal";
import { SecondaryDropdownBox } from "@/panel/electricity/patrol-point/secondary-dropdown-box";


export function FireExtinguisherPanel() {

    const detailRef = useRef(null);

    const { projectId } = useParams();
    const storeyContext = useContext<StoreyStatus>(StoreyContext);
    const [fireEquipmentEntity, setFireEquipmentEntity] = useState<ModelBase>(); //消防设备组件
    const [storeyList, setStoreyList] = useState<Storey[]>([]);
    const [init, setInit] = useState<boolean>(false);  //防止第一次调用onSelectedChanged的信号时导致面板为空

    const [selectedUuid, setSelectedUuid] = useState<string>("");

    const [maxNumber, setMaxNumber] = useState<number>(0);  //最大设备编号

    const onChangeSelectedUuid = (v: string) => {
        setSelectedUuid(v);
    }

    const generateData = () => {
        if (!projectId) {
            storeyContext.setStoreys([]);
            return;
        }
        getStoreys(projectId).then(res => {
            //为保持全局一致性，不直接从storeyContext中拿，每次都查询后更新
            if (res.status === 200) {
                let data: Storey[] = res.data || [];
                let bottomElevations: number[] = [];

                //按照楼层标高从高到低排序
                data.sort((a, b) => {
                    return - a.bottomElevation + b.bottomElevation;
                });
                data.forEach(item => {
                    bottomElevations.push(item.bottomElevation);
                    item.childList = [];
                });
                storeyContext.setStoreys(data);

                findPrimitivesInGraphic(projectId, { type: "FireExtinguisherT", comType: ComponentType.FIRE_EXTINGUISHER }).then(res2 => {
                    if (res2.status === 200) {
                        let primitives = res2.data || [];
                        let tmpMaxNumber = 0;

                        primitives?.forEach(element => {
                            let z = element.properties.position.z / 1000;
                            element.name = element.properties.name;
                            element.isSelected = false;
                            let tmpNumber = Number(extractFirstNumber(element.name));
                            tmpMaxNumber = tmpMaxNumber > tmpNumber ? tmpMaxNumber : tmpNumber;
                            let added = false;
                            for (let i = 0; i < bottomElevations.length; i++) {
                                if (z >= bottomElevations[i]) {
                                    data[i].childList.push(element);
                                    added = true;
                                    break;
                                }
                            }
                            if (!added) {
                                //如果标高没有被归类为前面的项，说明低于最低楼层的标高，直接归为最低楼层
                                data[bottomElevations.length - 1].childList.push(element);
                            }
                        });
                        data.forEach(item => {
                            item.nameSuffix = `(${item.bottomElevation}m)\t(已建${item.childList.length}个灭火器)`
                        });
                        setMaxNumber(tmpMaxNumber);
                        setStoreyList(data);
                    }

                })


            }
        }).catch(err => {
            console.log('err', err)
        })

        getComponents({ projectUuid: projectId, type: ComponentType.FIRE_EXTINGUISHER }).then(res => {
            if (res.status == 200) {
                let components: any[] = res.data || [];
                if (components.length > 0) {
                    let firstComponent = components[0];
                    let entity: ModelBase = new FireExtinguisherT().fromJson(firstComponent);
                    setFireEquipmentEntity(entity);
                }
            }
        })
    }

    /**
     * 项目初始化的时候，生成数据
     */
    useEffect(() => {
        generateData();
    }, [projectId])

    const onEquipmentPositionUpdate = (entities: ModelBase[], needDeleted: boolean = false) => {
        let equipments = entities?.filter(entity => entity.type === "FireExtinguisherT");
        let equipmentsUuidSet = new Set<string>(equipments.map(item => item.uuid)); //存储被修改后的图元的uuid
        if (equipments.length > 0) {
            let tmpStoreyList = [...storeyList];

            let bottomElevations: number[] = tmpStoreyList.map(storey => storey.bottomElevation);

            //从列表中移除被修改的图元
            tmpStoreyList.forEach(storey => {
                storey.childList = storey.childList.filter(item => !(equipmentsUuidSet.has(item.uuid)));  //filter操作创建新数组，牺牲空间换时间
            })

            if (!needDeleted) {//如果需要被删除，就不重新添加进去了
                equipments.forEach((item: FireExtinguisherT) => {
                    let z = item.position.z / 1000;
                    let added = false;
                    for (let i = 0; i < bottomElevations.length; i++) {

                        if (z >= bottomElevations[i]) {
                            tmpStoreyList[i].childList.push({ ...item.toJson(), isSelected: item.isSelected });
                            added = true;
                            break;
                        }
                    }
                    if (!added && tmpStoreyList.length > 0) {
                        //如果标高没有被归类为前面的项，说明低于最低楼层的标高，直接归为最低楼层
                        tmpStoreyList[bottomElevations.length - 1].childList.push({ ...item.toJson(), isSelected: item.isSelected });
                    }
                })
                tmpStoreyList.forEach(item => {
                    item.nameSuffix = `(${item.bottomElevation}m)\t(已建${item.childList.length}个灭火器)`
                });
            } else {
                //如果是删除模式，只更新每层的个数
                tmpStoreyList.forEach(item => {
                    item.nameSuffix = `(${item.bottomElevation}m)\t(已建${item.childList.length}个灭火器)`
                });
            }
            setStoreyList(tmpStoreyList);
        }
    }

    /**
     * 设备位置修改后的回调，会修改设备对应楼层
     */
    useEffect(() => {
        if (graphicStore.extraContext.getCurrentViewEditor()) {
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onEntitiesUpdatePosition.add(onEquipmentPositionUpdate)
        } else {
            //防止面板被提前打开
            setTimeout(() => {
                if (graphicStore.extraContext.getCurrentViewEditor()) {
                    graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onEntitiesUpdatePosition.add(onEquipmentPositionUpdate)
                }
            }, 3000);
        }

        return () => {
            if (graphicStore.extraContext.getCurrentViewEditor()) {
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onEntitiesUpdatePosition.remove(onEquipmentPositionUpdate)
            }
        }
    }, [onEquipmentPositionUpdate])


    const onQuicklyAssembleBlockFinish = (entity: ModelBase) => {
        let tmpNumber = Number(extractFirstNumber(entity.name));
        if (tmpNumber > maxNumber) {
            setMaxNumber(tmpNumber);
            graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal(BUILDING_SINGAL.onQuicklyAssembleBlockChangeName).dispatch(`灭火器${tmpNumber + 1}`)
        }
        let bottomElevations = storeyList.map(item => item.bottomElevation);
        let tmpStoreyList = [...storeyList];
        let added = false;
        let z = (entity as ComponentT).position.z / 1000;
        for (let i = 0; i < bottomElevations.length; i++) {

            if (z >= bottomElevations[i]) {
                tmpStoreyList[i].childList.push({ ...(entity as ComponentT).toJson(), isSelected: entity.isSelected });
                added = true;
                break;
            }
        }
        if (!added && tmpStoreyList.length > 0) {
            //如果标高没有被归类为前面的项，说明低于最低楼层的标高，直接归为最低楼层
            tmpStoreyList[bottomElevations.length - 1].childList.push({ ...(entity as ComponentT).toJson(), isSelected: entity.isSelected });
        }
        tmpStoreyList.forEach(item => {
            item.nameSuffix = `(${item.bottomElevation}m)\t(已建${item.childList.length}个灭火器)`
        });
        setStoreyList(tmpStoreyList);
    }


    /**
     * 设备在图形区添加完毕后，在面板上的回调（多显示一行）
     */
    useEffect(() => {
        if (graphicStore.extraContext.getCurrentViewEditor()) {
            graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal(BUILDING_SINGAL.onQuicklyAssembleBlockFinish).add(onQuicklyAssembleBlockFinish)
        } else {
            //防止面板被提前打开
            setTimeout(() => {
                if (graphicStore.extraContext.getCurrentViewEditor()) {
                    graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal(BUILDING_SINGAL.onQuicklyAssembleBlockFinish).add(onQuicklyAssembleBlockFinish)
                }
            }, 3000);
        }

        return () => {
            if (graphicStore.extraContext.getCurrentViewEditor()) {
                graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal(BUILDING_SINGAL.onQuicklyAssembleBlockFinish).remove(onQuicklyAssembleBlockFinish)
            }
        }
    }, [onQuicklyAssembleBlockFinish])


    const onSelectChange = (entities: ModelBase[]) => {
        if (!init) {
            setInit(true);
            return;
        }
        let equipments = entities.filter(item => item instanceof FireExtinguisherT) as FireExtinguisherT[];
        let equipmentDict: { [key: string]: boolean } = {};
        equipments.forEach(item => equipmentDict[item.uuid] = item.isSelected);
        let tmpStoreyList = [...storeyList];
        for (let i = 0; i < tmpStoreyList.length; i++) {
            let storey = tmpStoreyList[i];
            for (let j = 0; j < storey.childList.length; j++) {
                if (equipmentDict.hasOwnProperty(storey.childList[j].uuid)) {
                    storey.childList[j].isSelected = equipmentDict[storey.childList[j].uuid];
                }
            }
        }
        setStoreyList(tmpStoreyList);
    }

    /**
     * 改变图形区选中后，列表的回调
     */
    useEffect(() => {
        if (graphicStore.extraContext.getCurrentViewEditor()) {
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onSelectChanged.add(onSelectChange)
        } else {
            //防止面板被提前打开
            setTimeout(() => {
                if (graphicStore.extraContext.getCurrentViewEditor()) {
                    graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onSelectChanged.add(onSelectChange)
                }
            }, 3000);
        }

        return () => {
            if (graphicStore.extraContext.getCurrentViewEditor()) {
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onSelectChanged.remove(onSelectChange)
            }
        }
    }, [onSelectChange])



    /**
     * 布置
     */
    const onAddClick = () => {
        onActiveSubControl(BUILDING_SUB_CONTROL.QUICKLYASSEMBLEBLOCK.name);
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal(BUILDING_SINGAL.onQuicklyAssembleBlock)
            .dispatch(fireEquipmentEntity, new Vector3(0, 0, 0), null, `灭火器${maxNumber + 1}`, [{ key: GroupType.STOREY, value: DefaultStoreyUuid.DYNAMIC }]);
    }

    /**
     * 删除
     */
    const onDeleteClick = () => {
        let selectedUuids = [];
        let tmpStoreyList = [...storeyList];
        tmpStoreyList.forEach(storey => {
            storey.childList.forEach(item => {
                if (item.isSelected) {
                    selectedUuids.push(item.uuid);
                }
            })
        })
        if (selectedUuids?.length === 0) {
            message.info("未选中灭火器");
            return;
        }
        tmpStoreyList.forEach(storey => {
            storey.childList = storey.childList.filter(item => !item.isSelected);
            storey.nameSuffix = `(${storey.bottomElevation}m)\t(已建${storey.childList?.length || 0}个灭火器)`
        })

        TnModal().deleteModal({
            closable: false,
            onOk() {
                deletePrimitivesInGraphic(selectedUuids).then(res => {
                    if (res.status === 200) {
                        message.success("删除成功");
                        setStoreyList(tmpStoreyList);
                        let editor = graphicStore.extraContext.getCurrentViewEditor();
                        if (editor) {
                            editor.removeObjectByUuids(selectedUuids);

                        }
                    }
                }).catch(err => {
                    console.log('err', err)
                }).finally(() => {
                    graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.emptySelectedEntities.dispatch();
                    graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.needRender.dispatch('redraw');
                    Modal.destroyAll();
                })
            },
            onCancel() {
                Modal.destroyAll();
            },
        })

    }


    /**
     * 更新选中状态
     */
    const setSelectedEntity = (uuid: string, selected: boolean) => {
        let tmpStoreyList = [...storeyList];
        for (let i = 0; i < tmpStoreyList.length; i++) {
            let storey = tmpStoreyList[i];
            let has = false;
            for (let j = 0; j < storey.childList.length; j++) {
                if (storey.childList[j].uuid === uuid) {
                    storey.childList[j].isSelected = selected;
                    has = true;
                    break;
                }
            }
            if (has) {
                break;
            }
        }
        setStoreyList(tmpStoreyList);
    }


    /**
     * 更新名称
     */
    const updateName = (uuid: string, name: string) => {
        let tmpStoreyList = [...storeyList];
        for (let i = 0; i < tmpStoreyList.length; i++) {
            let storey = tmpStoreyList[i];
            let has = false;
            for (let j = 0; j < storey.childList.length; j++) {
                if (storey.childList[j].uuid === uuid) {
                    storey.childList[j].name = name;
                    if ((storey.childList[j] as any).toJson) {
                        createPrimitive((storey.childList[j] as any).toJson());
                    } else {
                        if ((storey.childList[j] as any).properties) {
                            let data = (storey.childList[j] as any).properties
                            data.name = name
                            createPrimitive(data);
                        } else {
                            let data = { ...storey.childList[j] };
                            delete data.isSelected;
                            createPrimitive(data);
                        }
                    }
                    has = true;
                    break;
                }
            }
            if (has) {
                break;
            }
        }
        setStoreyList(tmpStoreyList);
    }






    return (
        <div className="side-bar-main patrol-point-container">
            <SideBarTool
                activeBtnList={[SideBarBtnType.ADD, SideBarBtnType.DELETE]}
                onAddBtnClick={onAddClick}
                onDeleteBtnClick={onDeleteClick}
            />
            <div className="body">
                <div className="title">
                    灭火器
                </div>
                <div className="detail" ref={detailRef}>
                    <SecondaryDropdownBox
                        datas={storeyList}
                        selectedUuid={selectedUuid}
                        onChangeSelectedUuid={onChangeSelectedUuid}
                        setSelectedEntity={setSelectedEntity}
                        updateName={updateName}
                    />
                </div>
            </div >
        </div >
    )

}