import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { AddPlineCommand, AddPrimitivesCommand, Arc, BasicControl, CURSOR_TYPE, CommandGroup, DYMODE_MODE, FatLine, Line, LineSegments2, LineT, Listeners, MOUSE, Plane, PlineAttributes, PlineT, Point, PrimitiveColor, TnEngineContext, TnEngineExtraContext, ViewEditor, dymode } from "pythagoras";
import { Color, LineDashedMaterial, LineSegments, Vector3 } from "three";
import { BoundingWallT } from "tnbimbase";

export class AssembleWallControl extends BasicControl {
    id = EXTRA_CTRL_TYPE.ASSEMBLE_WALL;

    needInit: boolean;

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    layerUuid: string;
    height: number;
    leftWidth: number;
    rightWidth: number;


    plinePlane: Plane = new Plane(); // pline所在的平面
    helpLine: PlineT; // pline 头部的辅助移动线段区域
    pline: PlineT; // pline 确定的区域
    step: number = 1;
    loop: boolean = false;
    orthogonalPoint: Vector3;
    capturePoint: Vector3;
    dymodeChoose: string = null;
    next: boolean = false; // 当前正在输入端点的属性值
    vertexIdx: number = 0; // 当前顶点
    addPlineCmd: AddPlineCommand; // 绘制多段线命令, 因为画多段线的中途就需要创建，后续都是更新
    currentLine: Line = new Line();
    currentArc: Arc = new Arc();
    _startToCenter: Vector3 = new Vector3();
    _startToEnd: Vector3 = new Vector3();
    _tangentDir: Vector3 = new Vector3();
    _tangentDir_UCS: Vector3 = new Vector3();
    _aClockwise = false;
    currentAttribute: PlineAttributes = {
        bulge: 0,
        startWidth: 0,
        endWidth: 0,
    };
    mode = 'L'; //默认绘制Line 
    center: Point = new Point();
    radius: number;
    options = {
        2: [
            { name: '圆弧', key: 'A' },
            { name: '放弃', key: 'U' },
        ],
        3: [
            { name: '圆弧', key: 'A' },
            { name: '闭合', key: 'C' },
            { name: '放弃', key: 'U' },
        ],
        4: [
            { name: '直线', key: 'L' },
            { name: '放弃', key: 'U' },
        ],
        5: [
            { name: '直线', key: 'L' },
            { name: '闭合', key: 'C' },
            { name: '放弃', key: 'U' },
        ],
    }
    polarPoint: Vector3;
    helpRayLine: LineT;
    normal: Vector3;
    origin: Vector3;
    axisX: Vector3;
    axisY: Vector3;
    axisZ: Vector3;

    point_down: Point = new Point();
    point_move: Point = new Point();

    isSingleRender:boolean = true

    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.needInit = true;

        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();

        this.editor = editor;
        this.listeners = listeners;

        this.listeners.getSignal(EXTRA_SINGAL.onAssembleWall).add(this.onAssembleWall);

        console.log('AssembleWallControl added')
    }

    dispose(): void {
        if (this.pline && this.pline._geoCurves.length === 0) {
            this.extraContext.apiContext.deletePrimitivesInGraphic([this.pline.uuid]);
        }
        this.listeners.getSignal(EXTRA_SINGAL.onAssembleWall).remove(this.onAssembleWall);
        // 派发置空选择集的信号
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.editor.renderer.domElement.removeEventListener('pointermove', this.onPointerMove);
        document.removeEventListener('keydown', this.onKeydown);
        this.listeners.signals.onPointCapture.remove(this.onPointCapture);
        this.listeners.signals.onKeyMouseConfirm.remove(this.onKeyMouseConfirm);
        this.listeners.signals.onPointOrthogonal.remove(this.onPointOrthogonal);
        this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
        this.listeners.signals.onPointPolarTracking.remove(this.onPointPolarTracking);
        this.listeners.signals.onZoomChanged.remove(this.onZoomChanged);
        this.listeners.signals.noNeedCapture.dispatch();
        this.editor.selectControl.setEnabled(true);
        this.extraContext.dymodeContext.enabled = false;
        this.editor.orthogonalControl.enabled = false;
        this.editor.selectControl.setEnabled(true);
        this.editor.clampControl.enabled = true;
        this.editor.clampControl.hidden = false;
        let obj = this.helpLine?.viewObj;
        if (obj && !!obj.parent) {
            obj.parent.remove(obj);
        }
        if (this.addPlineCmd && this.addPlineCmd.line && this.addPlineCmd.line._geoCurves) {
            let cmds = new CommandGroup();
            let pline = this.addPlineCmd.line;
            let boundingWallT = new BoundingWallT(pline, this._worldZ, this.leftWidth, this.rightWidth, this.height);
            boundingWallT.layerUuid = this.layerUuid;
            boundingWallT.isColorByLayer = true;
            boundingWallT.isSingleFaceRender = this.isSingleRender
            let addCommand = new AddPrimitivesCommand([boundingWallT]);
            this.addPlineCmd.undo();
            cmds.cmds = [addCommand];
            this.editor.history.execute(cmds);
        }
        console.log('AssembleWallControl removed')
    }

    initControl(data: any): void {
        this.onAssembleWall(data.layerUuid, data.height, data.leftWidth, data.rightWidth, data.isSingleRender);
    }

    private _worldZ = new Vector3(0, 0, 1);
    onAssembleWall = (layerUuid: string, height: number, leftWidth: number, rightWidth: number, isSingleRender: boolean) => {
        let initData = {
            layerUuid: layerUuid,
            height: height,
            leftWidth: leftWidth,
            rightWidth: rightWidth,
            isSingleRender: isSingleRender,
        }
        this.layerUuid = layerUuid;
        this.height = height;
        this.leftWidth = leftWidth;
        this.rightWidth = rightWidth;
        this.listeners.signals.setInitControlData.dispatch(initData);
        this.isSingleRender = isSingleRender

        this.normal = this.context.ucsContext.getAxisList().axisZ.clone();
        if ((Math.abs(this.context.ucsContext.getAxisList().axisZ.clone().dot(this._worldZ))) < 1e-6) {
            // 当前工作面就是竖直的，无法布置
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
        }
        this.origin = this.context.ucsContext.getAxisList().origin.clone();
        this.axisX = this.context.ucsContext.getAxisList().axisX.clone();
        this.axisY = this.context.ucsContext.getAxisList().axisY.clone();
        this.axisZ = this.context.ucsContext.getAxisList().axisZ.clone();
        this._tangentDir = this.context.ucsContext.getAxisList().axisX.clone();
        this.initHelpRayLine();

        this.editor.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
        this.editor.renderer.domElement.addEventListener('pointermove', this.onPointerMove);
        document.addEventListener('keydown', this.onKeydown);
        this.listeners.signals.needCapture.dispatch();
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.listeners.signals.onPointCapture.add(this.onPointCapture);
        this.listeners.signals.onDymodeChanged.add(this.onDymodeChanged);
        this.listeners.signals.onKeyMouseConfirm.add(this.onKeyMouseConfirm);
        this.listeners.signals.onPointOrthogonal.add(this.onPointOrthogonal);
        // 订阅极轴追踪信号，收听到极轴追踪点的坐标
        this.listeners.signals.onPointPolarTracking.add(this.onPointPolarTracking);
        this.listeners.signals.onZoomChanged.add(this.onZoomChanged);
        this.editor.selectControl.setEnabled(false);
        this.extraContext.dymodeContext.enabled = true;
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.POINT);
        this.point_down.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        this.point_move.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        this.listeners.signals.sendToDymodeTip.dispatch('指定起点:');
        this.extraContext.stmodeContext.setStmode('指定起点');
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);

    }
    initHelpRayLine = () => {
        this.helpRayLine = new LineT();
        this.helpRayLine._isHelpLine = true;
        let viewObj: LineSegments | FatLine | LineSegments2 = this.helpRayLine.render();
        let size = this.context.renderContext.cameraEdge * this.context.renderContext.selectedDashScale * 5;
        viewObj.material = new LineDashedMaterial({ color: new Color('#00a500'), dashSize: size, gapSize: size });
    }

    onKeydown = (event) => {
        const keyCode = event.keyCode;
        if (keyCode === 90 && event.ctrlKey) {
            // ctrl + z
            event.stopPropagation();
            this.undo();
        }
    }

    undo = () => {
        if (!this.pline || this.pline._geoCurves.length === 0) {
            this.step = 0; // 防止dispose重复创建
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
            return;
        }
        if (this.pline) {
            let geo = this.pline._geoCurves.pop();
            let attribute = this.pline.plineAttributes.pop();
            this.currentAttribute = { ...attribute };
            if (geo.type === 'Line') {
                this.currentLine.copy(geo as Line);
            } else {
                this.currentArc.copy(geo as Arc);
            }

            this.point_down.copy(geo.getPointAt(0));
        }
        if (this.pline._geoCurves.length >= 2) {
            this.plinePlane.setFromNormalAndCoplanarPoint(this.normal, this.point_down);
            // this.createPline();
            this.listeners.signals.needCapture.dispatch(this.point_down);
            this.listeners.signals.needRender.dispatch();
            this.listeners.signals.sendToDymodeTip.dispatch('指定下一个点:');
            if (this.mode === 'L') {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[3] || []);
            } else if (this.mode === 'A') {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[5] || []);
            }
            this.listeners.signals.orthogonal.dispatch(this.point_down);
            this.listeners.signals.polarTracking.dispatch(this.point_down);
            // this.editor.dymodeControl.mode = DYMODE_MODE.POINT;
            this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);
        }
        if (this.pline._geoCurves.length < 2) {
            // let workplane = this.context.ucsContext.getWorkPlane();
            this.plinePlane.setFromNormalAndCoplanarPoint(this.normal, this.point_down);
            // this.createPline();
            this.listeners.signals.needCapture.dispatch(this.point_down);
            this.listeners.signals.needRender.dispatch();
            this.listeners.signals.sendToDymodeTip.dispatch('指定下一个点:');
            if (this.mode === 'L') {
                if (this.pline && this.pline._geoCurves.length >= 2) {
                    this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[3] || []);
                } else {
                    this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[2] || []);
                }
            } else if (this.mode === 'A') {
                if (this.pline && this.pline._geoCurves.length >= 2) {
                    this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[5] || []);
                } else {
                    this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[4] || []);
                }
            }
            this.listeners.signals.orthogonal.dispatch(this.point_down);
            this.listeners.signals.polarTracking.dispatch(this.point_down);
            // this.editor.dymodeControl.mode = DYMODE_MODE.POINT;
            this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);

        }
        if (this.pline._geoCurves.length < 1) {
            this.addPlineCmd.undo();
            this.pline = null;
        }
        if (!this.helpLine) {
            this.initHelpLine();
        } else {
            this.updateHelpLine(this.point_down, this.point_down);
        }
        this.extraContext.listeners.signals.needRender.dispatch('redraw');
    }

    initHelpLine = () => {
        if (this.mode === 'L') {
            this.currentLine = new Line();
        } else if (this.mode === 'A') {
            this.currentArc = new Arc();
            this.currentArc.axisX.copy(this.axisX);
            this.currentArc.normal.copy(this.normal);
            this.currentArc.aClockWise = this._aClockwise;
        }
        let curve = this.mode === 'L' ? this.currentLine : this.currentArc;
        this.helpLine = new PlineT([curve], [{ ...this.currentAttribute }], this.plinePlane.normal);
        this.helpLine.color.set(PrimitiveColor.editColor);
        this.helpLine.lineWidth = this.context.layerContext.getDrawingLineWidth();
        this.helpLine.lineType = this.context.layerContext.getDrawingLineType();
        this.updateHelpLine(this.point_down, this.point_down);
        let view = this.helpLine.render();
        this.extraContext.sceneContext.scene.add(view);
    }

    private _dir = new Vector3();
    updateHelpLine = (start: Point, end: Point) => {
        let attributes = this.helpLine.plineAttributes;

        if (this.mode === 'L') {
            // 直线
            // update currentLine
            if (!this.currentLine) {
                this.currentLine = new Line();
            }
            this.currentLine.set(start, end);
            this.currentAttribute.bulge = 0;
            // this.helpLine._geoCurves[0] = this.currentLine;
        } else if (this.mode === 'A') {
            // 圆弧
            // update currentArc
            if (!this.currentArc) {
                this.currentArc = new Arc();
                this.currentArc.axisX.copy(this.axisX);
                this.currentArc.normal.copy(this.normal);
                this.currentArc.aClockWise = this._aClockwise;
            }
            this.currentArc.axisX.copy(this.axisX);
            this.currentArc.normal.copy(this.normal);
            this._startToEnd.subVectors(end, start);
            if (this._startToEnd.angleTo(this._tangentDir) < 1e-6) {
                this.currentLine.set(start, end);
                this.helpLine._geoCurves[0] = this.currentLine;
                this.currentAttribute.bulge = Math.tan(0);
            } else if (Math.PI - this._startToEnd.angleTo(this._tangentDir) < 1e-6) {

            } else {
                this.helpLine._geoCurves[0] = this.currentArc;
                this._dir.copy(this._tangentDir).cross(this._startToEnd).normalize();
                if (this._dir.dot(this.plinePlane.normal) > 0) {
                    this._aClockwise = false;
                    this._startToCenter.copy(this.plinePlane.normal).cross(this._tangentDir).normalize();
                } else {
                    this._aClockwise = true;
                    this._startToCenter.copy(this._tangentDir).cross(this.plinePlane.normal).normalize();
                }
                // 求半径
                this.radius = this._startToEnd.length() ** 2 / (this._startToEnd.dot(this._startToCenter)) / 2;
                this.center.copy(start).add(this._startToCenter.multiplyScalar(this.radius));
                this.currentArc.aClockWise = this._aClockwise;
                this.currentArc.setFromTwoPointAndCenter(start, end, this.center);
                this.currentAttribute.bulge = Math.tan(this.currentArc.deltaAngle() / 4);
            }
            // this.helpLine._geoCurves[0] = this.currentArc;
        }

        if (attributes.length > 0) {
            attributes[0] = { ...this.currentAttribute };
        }
        this.helpLine.updateViewGeometry();
    }

    onZoomChanged = (zoomScale) => {
        if (!this.helpRayLine.viewObj) return;
        let size = this.context.renderContext.cameraEdge * this.context.renderContext.selectedDashScale * 5;
        let mat = <LineDashedMaterial>this.helpRayLine.viewObj.material;
        mat.dashSize = size;
        mat.gapSize = size;
    }

    onPointPolarTracking = (point) => {
        this.polarPoint = point;
        if (!point) {
            this.extraContext.sceneContext.scene.remove(this.helpRayLine?.viewObj);
        }
    }

    onPointCapture = (point) => {
        this.capturePoint = point;
    }

    onPointOrthogonal = (point) => {
        this.orthogonalPoint = point;
    }

    private _lastPointDown: Point = new Point();
    private _currentPointDown: Point = new Point();
    onPointerDown = (event) => {
        if (event.button !== MOUSE.LEFT) return;
        this._lastPointDown.copy(this.point_down.clone());

        let rect = this.editor.renderer.domElement.getBoundingClientRect();
        let x = (event.clientX - rect.x) / rect.width * 2 - 1;
        let y = -(event.clientY - rect.y) / rect.height * 2 + 1;
        this.context.ucsContext.unprojectCursorPoint(x, y, this.editor.camera, this.point_down);
        this._currentPointDown.copy(this.point_down.clone());
        if (this.orthogonalPoint) {
            this.point_down.copy(this.orthogonalPoint);
        }
        if (this.capturePoint) {
            this.point_down.copy(this.capturePoint);
        }
        if (this.polarPoint) {
            this.point_down.copy(this.polarPoint);
        }
        if (this.step === 1) {
            this.firstStep();
        } else if (this.step >= 2 && this.step < 10) {
            let foot = this.point_down.clone();
            this.plinePlane.projectPoint(this.point_down, foot);
            this.point_down.copy(foot);
            if (this._lastPointDown.equals(this.point_down)) {

            } else {
                this.updateHelpLine(this._lastPointDown, this.point_down);
                this.nextStep();
            }
        }
    }

    private _foot = new Point();
    onPointerMove = (event) => {
        this.point_move.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        if (this.step >= 2) {
            if (this.orthogonalPoint) {
                this.point_move.copy(this.orthogonalPoint);
            }
            if (this.capturePoint) {
                this.point_move.copy(this.capturePoint);
            }

            this.plinePlane.projectPoint(this.point_move, this._foot);
            this.point_move.copy(this._foot);
            if (this.point_down.equals(this.point_move)) {

            } else {
                this.updateHelpLine(this.point_down, this.point_move);
            }
        }
    }

    firstStep = () => {
        this.plinePlane.setFromNormalAndCoplanarPoint(this.normal, this.point_down);

        // this.createPline();
        this.listeners.signals.needCapture.dispatch(this.point_down);
        this.listeners.signals.needRender.dispatch();
        this.step++;
        this.listeners.signals.sendToDymodeTip.dispatch('指定下一个点:');
        if (this.mode === 'L') {
            if (this.pline && this.pline._geoCurves.length >= 2) {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[3] || []);
            } else {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[2] || []);
            }
        } else if (this.mode === 'A') {
            if (this.pline && this.pline._geoCurves.length >= 2) {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[5] || []);
            } else {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[4] || []);
            }
        }
        this.listeners.signals.orthogonal.dispatch(this.point_down);
        this.listeners.signals.polarTracking.dispatch(this.point_down);
        // this.editor.dymodeControl.mode = DYMODE_MODE.POINT;
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);
        if (!this.helpLine) {
            this.initHelpLine();
        } else {
            this.updateHelpLine(this.point_down, this.point_down);
        }
    }

    nextStep = () => {
        if (!this.pline) {
            this.createPline();
        } else {
            this.updatePline();
        }
        // 计算切线
        if (this.mode === 'L') {
            this._tangentDir.subVectors(this.currentLine.end, this.currentLine.start);
        } else {
            if (this._aClockwise) {
                this._tangentDir.subVectors(this.currentArc.center, this.currentArc.getPointAt(1)).cross(this.currentArc.normal).multiplyScalar(-1);
            } else {
                this._tangentDir.subVectors(this.currentArc.getPointAt(1), this.currentArc.center).cross(this.currentArc.normal).multiplyScalar(-1);
            }
        }
        this.step = 3;
        this.listeners.signals.needRender.dispatch('redraw');
        this.listeners.signals.sendToDymodeTip.dispatch('指定下一个点:');
        if (this.mode === 'L') {
            if (this.pline && this.pline._geoCurves.length >= 2) {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[3] || []);
            } else {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[2] || []);
            }
        } else if (this.mode === 'A') {
            if (this.pline && this.pline._geoCurves.length >= 2) {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[5] || []);
            } else {
                this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[4] || []);
            }
        }
        this.listeners.signals.needCapture.dispatch(this.point_down);
        this.listeners.signals.orthogonal.dispatch(this.point_down);
        this.listeners.signals.polarTracking.dispatch(this.point_down);
        // this.editor.dymodeControl.mode = DYMODE_MODE.POINT;
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);
    }

    createPline = () => {
        this.pline = new PlineT();
        this.pline._geoPlane.normal = this.context.ucsContext.getAxisList().axisZ;
        this.pline.axisX.copy(this.axisX);
        // this.pline.layerUuid = this.context.layerContext.currentLayerUuid;
        this.pline.plineAttributes.push({ ...this.helpLine.plineAttributes[0] });
        this.pline.plineAttributes.push({ ...this.helpLine.plineAttributes[0] });

        this.pline.setCurves([this.helpLine._geoCurves[0].clone()]);

        this.addPlineCmd = new AddPlineCommand(this.editor, this.pline);
        this.addPlineCmd.execute();
    }

    updatePline = () => {
        if (!this.pline) return;

        let curves = [...this.pline._geoCurves, this.helpLine._geoCurves[0].clone()];
        this.pline.plineAttributes.pop();
        this.pline.plineAttributes.push({ ...this.helpLine.plineAttributes[0] })
        this.pline.plineAttributes.push({ ...this.helpLine.plineAttributes[0] })

        this.pline.setCurves(curves);
        if (this.pline.loop) {
            this.pline.plineAttributes.pop();
        }
        this.extraContext.apiContext.createPrimitive(this.pline.toJson());
    }


    _dymodeActive = false;

    onDymodeChanged = (value: dymode) => {
        if (value.mode === DYMODE_MODE.POINT) {
            this.point_down.set(value.x, value.y, value.z);
            this._dymodeActive = true;
            this.onKeyMouseConfirm();
        } else if (value.mode === DYMODE_MODE.DIST) {
            let dir = this.point_move.clone().sub(this.point_down).normalize();
            let end = dir.multiplyScalar(value.dist).add(this.point_down);
            this.point_down.set(end.x, end.y, end.z);
            this._dymodeActive = true;
            this.onKeyMouseConfirm();
        } else if (value.mode === DYMODE_MODE.CHOOSE) {
            let choose = value.choose.toUpperCase();
            this.dymodeChoose = choose;
            switch (choose) {
                case 'L':
                    this.mode = 'L';
                    this.helpLine.setCurves([this.currentLine]);
                    this.updateHelpLine(this.point_down, this.point_move);
                    this.extraContext.dymodeContext.setDymodeTip('指定下一个点:');
                    this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);
                    if (this.pline && this.pline._geoCurves.length >= 2) {
                        this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[3] || []);
                    } else {
                        this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[2] || []);
                    }
                    this.extraContext.listeners.signals.needRender.dispatch();
                    break;
                case 'A':
                    this.mode = 'A';
                    this.helpLine.setCurves([this.currentArc]);
                    this.updateHelpLine(this.point_down, this.point_move);
                    this.extraContext.dymodeContext.setDymodeTip('指定下一个点:');
                    this.extraContext.dymodeContext.setMode(DYMODE_MODE.DIST, this.point_down);
                    if (this.pline && this.pline._geoCurves.length >= 2) {
                        this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[5] || []);
                    } else {
                        this.extraContext.stmodeContext.setStmode('指定下一个点或', this.options[4] || []);
                    }
                    this.extraContext.listeners.signals.needRender.dispatch();
                    break;
                case 'U':
                    // 放弃
                    this.undo();
                    break;
                case 'C':
                    // 闭合
                    if (this.pline._geoCurves.length >= 2) {
                        // 算上正在移动的point_move
                        this.loop = true;
                        this.onKeyMouseConfirm();
                    }
                    // this.listeners.signals.onOpeCommandControlEnd.dispatch();
                    break;
            }
        }
    }


    onKeyMouseConfirm = () => {

        if (this.step === 1) {
            if (!this._dymodeActive) {
                this.listeners.signals.onOpeCommandControlEnd.dispatch();
            } else {

                this.firstStep();
                this._currentPointDown.copy(this.point_down.clone());
                this._dymodeActive = false;
            }
        } else if (this.step >= 2) {
            if (this._dymodeActive) {
                let foot = this.point_down.clone();
                this.plinePlane.projectPoint(this.point_down, foot);
                this.point_down.copy(foot);
                this.updateHelpLine(this._currentPointDown, this.point_down);
                this._currentPointDown.copy(this.point_down.clone());
                this.nextStep();
                this._dymodeActive = false;
            } else if (this.pline && this.pline._geoCurves.length >= 1 && this.loop === true) {
                // TODO: 
                this.pline.loop = this.loop;
                // this.vertexIdx = this.pline._geoCurves.length - 1;
                // this.pline.plineAttributes[this.vertexIdx] = {...this.currentAttribute};
                // this.pline.updateViewGeometry();
                this.updateHelpLine(this.point_down, this.pline._geoCurves[0].getPointAt(0));
                this.nextStep();
                // createPrimitive(this.pline.toJson());
                // this.editor.history.push(this.addPlineCmd);
                this.step = 0; // 防止dispose重复创建
                this.listeners.signals.needRender.dispatch('redraw');
                this.listeners.signals.onOpeCommandControlEnd.dispatch();
            } else {
                this.listeners.signals.onOpeCommandControlEnd.dispatch();
            }
        } else {
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
        }
    }
}